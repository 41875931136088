import {
  ArrowIcon,
  Button,
  ButtonType,
  H2,
  Hr,
  Note,
  Strong,
  Typography,
  TypographyType,
  UpperCase,
} from '@hp/atomic';
import { CountryNameLikeField, Money } from '@hp/components';
import { useConfig } from '@hp/config';
import { AddressType, Country } from '@hp/core/shared/graphql/generated/types';
import {
  AutocompleteType,
  FieldWrapper,
  FormBackButton,
  FormWrapper,
  PhoneField,
  SwitchButtonField,
  TextAreaField,
  TextField,
  TextFieldGooglePlaces,
} from '@hp/form';
import { SpaceBetweenRow } from '@hp/layout';
import { fields, labels } from '@hp/locale';
import { useRouter } from '@hp/seo';
import { colors } from '@hp/theme';
import {
  formatPostalCodeSimple,
  hasFeature,
  isPostalCodeValidable,
  preventDefaultOnEnter,
  unformatPostalCode,
  useEffectSaveForm,
  useEffectValidateOnInitForm,
  useObjectLocalStorage,
} from '@hp/utils';
import { Trans } from '@lingui/react';
import { useFormik } from 'formik';
import React from 'react';

import { sharedKeys } from '../../sharedKeys';
import {
  CityPostalCodeContainer,
  CityPostalCodeFlexItem,
  FormContentWrapper,
} from '../../styled';
import {
  HowToSendFormFields,
  ParcelFormProps,
  RecipientFormFields,
} from '../../types';
import { computePrice } from '../../utils';
import { ParcelShopItem } from '../Delivery/ParcelShopItem';
import { useRecipientForm } from './useRecipientForm';

const formId = 'recipientForm';

export const RecipientForm = () => {
  const { config } = useConfig();
  const [howToSend] = useObjectLocalStorage<HowToSendFormFields>('howToSend');
  const [parcel] = useObjectLocalStorage<ParcelFormProps>('parcel');

  const {
    initialValues,
    saveFormData,
    handleSubmit,
    validationSchema,
    emptyValues,
    deliveryParcelShopId,
  } = useRecipientForm(howToSend);

  const isDeliveryToParcelShop = !!deliveryParcelShopId;

  const formik = useFormik<RecipientFormFields>({
    initialValues,
    enableReinitialize: false,
    onSubmit: handleSubmit,
    validationSchema,
    validateOnMount: false,
  });

  useEffectSaveForm(formik, saveFormData);
  useEffectValidateOnInitForm(formik, emptyValues);

  const router = useRouter();
  const changeDelivery = () => {
    router.push('orderDelivery');
  };

  return (
    <>
      <FormContentWrapper>
        <FormWrapper
          formik={formik}
          flex
          marginBottomSize="xl"
          disableAutoComplete
          onKeyDown={preventDefaultOnEnter}
        >
          {/* if you pray, turning off autocomplete will work */}
          <input type="hidden" value="prayer" />

          <H2>
            <Strong>
              <UpperCase mode="capitalize">
                <Trans id="common.recipient">příjemce</Trans>
              </UpperCase>
            </Strong>
          </H2>

          <SwitchButtonField<AddressType>
            formId={formId}
            name="recipient"
            extraMarginBottom
            type={hasFeature('v3') ? 'chip' : 'button'}
          >
            {[
              {
                value: AddressType.PERSON,
                disabled: formik.isSubmitting,
                content: () => (
                  <Typography
                    type={TypographyType.BodySmall}
                    textAlign="center"
                  >
                    <Trans id="common.subjectType.person">Osoba</Trans>
                  </Typography>
                ),
                testId: 'recipient.recipientType.person',
              },
              {
                value: AddressType.COMPANY,
                disabled: formik.isSubmitting,
                content: () => (
                  <Typography
                    type={TypographyType.BodySmall}
                    textAlign="center"
                  >
                    <Trans id="common.subjectType.company">Firma</Trans>
                  </Typography>
                ),
                testId: 'recipient.recipientType.company',
              },
            ]}
          </SwitchButtonField>

          <TextField<RecipientFormFields>
            formId={formId}
            name="name"
            label={
              formik.values.recipient === AddressType.COMPANY
                ? labels.recipient.companyName
                : labels.recipient.fullName
            }
            testId="recipient.input.name"
            autoComplete="off"
            disabled={formik.isSubmitting}
          />

          {formik.values.recipient === AddressType.COMPANY && (
            <TextField<RecipientFormFields>
              formId={formId}
              name="contactPerson"
              label={labels.recipient.contactPersonNotRequired}
              testId="recipient.input.contactPerson"
              autoComplete="off"
              disabled={formik.isSubmitting}
            />
          )}
          {!isDeliveryToParcelShop && (
            <>
              <TextFieldGooglePlaces<RecipientFormFields>
                autocompleteType={AutocompleteType.RECIPIENT}
                formId={formId}
                name="street"
                label={labels.recipient.street}
                testId="recipient.input.street"
                autoComplete={'' + Math.random()}
                disabled={formik.isSubmitting}
              />

              <CityPostalCodeContainer>
                <CityPostalCodeFlexItem width={['auto', 'auto']}>
                  <TextField<RecipientFormFields>
                    formId={formId}
                    name="city"
                    label={labels.recipient.city}
                    testId="recipient.input.city"
                    autoComplete="off"
                    disabled={formik.isSubmitting}
                  />
                </CityPostalCodeFlexItem>

                <CityPostalCodeFlexItem width={['auto', '150px']}>
                  <TextField<RecipientFormFields>
                    formId={formId}
                    name="postalCode"
                    label={labels.recipient.postalCode}
                    format={
                      howToSend.sendToCountry === Country.CZ ||
                      howToSend.sendToCountry === Country.SK
                        ? formatPostalCodeSimple
                        : unformatPostalCode
                    }
                    convert={unformatPostalCode}
                    testId="recipient.input.postalCode"
                    autoComplete="off"
                    validateOnlyOnBlur={(value) =>
                      //incomplete Postal codes we'll validate only onblur
                      !isPostalCodeValidable(value, howToSend.sendToCountry)
                    }
                    disabled={formik.isSubmitting}
                  />
                </CityPostalCodeFlexItem>
              </CityPostalCodeContainer>

              <CountryNameLikeField
                country={howToSend.sendToCountry}
                showOnlyNonDefault
                label={labels.recipient.deliveryCountry}
              />
            </>
          )}

          <PhoneField<RecipientFormFields>
            formId={formId}
            name="phone"
            label={fields.phoneNumber}
            defaultCountry={howToSend.sendToCountry.toLowerCase()}
            testId="recipient.input.phone"
            autoComplete="off"
            disabled={formik.isSubmitting}
          />

          <TextField<RecipientFormFields>
            formId={formId}
            name="email"
            label={labels.email}
            testId="recipient.input.email"
            autoComplete="off"
            type="email"
            autocorrect="off"
            autocapitalize="none"
            disabled={formik.isSubmitting}
          />
          {!isDeliveryToParcelShop && (
            <TextAreaField<RecipientFormFields>
              formId={formId}
              name="note"
              label={labels.recipient.noteNotRequired}
              testId="recipient.input.comment"
              autoComplete="off"
              disabled={formik.isSubmitting}
            />
          )}

          {isDeliveryToParcelShop && (
            <FieldWrapper>
              <Typography type={TypographyType.BodyLight}>
                <Trans id="common.deliveryPlace">Místo doručení</Trans>
              </Typography>

              <Note>
                <ParcelShopItem
                  item={deliveryParcelShopId}
                  selected
                  onEditClick={changeDelivery}
                  mode="delivery"
                />
              </Note>
            </FieldWrapper>
          )}

          <Hr />

          <SpaceBetweenRow>
            <div>{sharedKeys.price}</div>
            <Money currency="CZK">
              {
                computePrice(
                  {
                    howToSend,
                    parcel,
                    recipient: formik.values,
                  },
                  config,
                ).total
              }
            </Money>
          </SpaceBetweenRow>

          <SpaceBetweenRow marginSize="m" noBottomMargin>
            <FormBackButton
              to={hasFeature('v3') ? 'orderDelivery' : 'orderParcel'}
              type="button"
              className="gaButton gaButtonBack_recipient"
              buttonType={ButtonType.TEXT}
              data-test="recipient.button.back"
            >
              <Trans id="common.button.back">Zpět</Trans>
            </FormBackButton>

            <Button
              bottom
              type="submit"
              className="gaButton gaButtonSubmit_recipient"
              fakeDisabled={!formik.isValid}
              buttonType={ButtonType.PRIMARY}
              icon={
                <ArrowIcon
                  color={!formik.isValid ? colors.gray_warm : colors.white}
                />
              }
              animate={formik.isValid}
              data-test="recipient.button.submit"
              onKeyPress={(e) => {
                if (e.key === 'Enter') e.preventDefault();
              }}
            >
              <Trans id="common.button.continue">Pokračovat</Trans>
            </Button>
          </SpaceBetweenRow>
        </FormWrapper>
      </FormContentWrapper>
    </>
  );
};
