import { breakpoints, colors, spacing } from '@hp/theme';
import styled from 'styled-components';

import { IconParagraphProps } from './types';

export const Wrapper = styled.div<{ hasBottomButton?: boolean }>`
  padding-bottom: ${({ hasBottomButton }) => hasBottomButton && spacing.xl};

  @media (min-width: ${breakpoints.minDesktop}) {
    padding-bottom: 0;
  }
`;

export const ParagraphWrapper = styled.div<
  Pick<IconParagraphProps, 'marginBottomSize'>
>`
  display: flex;
  align-items: flex-start;
  margin: ${spacing.m} 0 ${({ marginBottomSize }) => spacing[marginBottomSize]}
    0;
`;

export const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  margin-right: ${spacing.s};
  margin-top: 2px;
`;

export const QrCodeWrapper = styled.div`
  background-color: ${colors.white};
`;
