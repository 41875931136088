import { PickupType } from '@hp/core/shared';
import { useYup } from '@hp/form';
import { useYupSharedOrderValidations } from '@hp/utils';

import { HowToSendFormFields, PickupFormType } from '../../types';

export const useHowToSendValidationSchema = (checkIds: boolean) => {
  const Yup = useYup();

  const { pickup, pickupForm } = useYupSharedOrderValidations(Yup);

  return Yup.object().shape<HowToSendFormFields>({
    sendFromCountry: Yup.string(),
    sendToCountry: Yup.string(),
    pickupType: pickup,
    pickupFormType: pickupForm,
    sendWhen: Yup.string(),
    selectedParcelShopId: Yup.string(),
    selectedParcelShopName: Yup.string(),
    selectedParcelShopAddress: Yup.string(),
    selectedParcelShopCodAllowed: Yup.string(),
    pickupId: checkIds
      ? Yup.string().when('pickupFormType', {
          is: (pickupFormType) => pickupFormType !== PickupFormType.COURIER,
          then: Yup.string().required(),
        })
      : Yup.string(),
    pickupFilter: Yup.object(),
    deliveryFilter: Yup.object(),
    deliveryType: pickup,
    deliveryFormType: pickupForm,
    deliveryId: checkIds
      ? Yup.string().when('deliveryFormType', {
          is: (pickupFormType) => pickupFormType !== PickupType.COURIER,
          then: Yup.string().required(),
        })
      : Yup.string(),
  });
};
