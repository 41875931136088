import { BorderPanelCss, Typography, TypographyType } from '@hp/atomic';
import { AddressView } from '@hp/components';
import { Address } from '@hp/core/shared';
import {
  InfoContent,
  ModalInvokeCloseFunction,
  ModalWellKnowResult,
  useSystemModal,
} from '@hp/core/src/providers/SystemModal';
import { colors, spacing } from '@hp/theme';
import { Trans } from '@lingui/macro';
import React, { FC, MouseEventHandler, SetStateAction } from 'react';
import styled from 'styled-components';

import { OtherData, SenderFormFields } from '../../types';
import {
  UsersProfileSenderAddressFormFragment,
  UsersProfileSenderInvoiceAddressFormFragment,
  UseSavedAddressesType,
} from './types';
import { useSavedAddresses } from './useSavedAddresses';

type ChoosenAddress = {
  addressId: string;
  formData:
    | UsersProfileSenderAddressFormFragment
    | UsersProfileSenderInvoiceAddressFormFragment;
};

const BorderButton = styled.button<{ checked?: boolean }>`
  ${BorderPanelCss};
  width: 100%;
  margin-top: ${spacing.s};
  cursor: ${({ checked }) => (checked ? 'default' : 'pointer')};
`;

const CornerWrapper = styled.div`
  position: absolute;
  right: 10px;
`;

const CornerInfo: FC<{
  checked?: boolean;
}> = ({ checked }) => {
  const txt = checked ? (
    <Trans id="order.sender.chooseAddress.selected">Vybráno</Trans>
  ) : (
    <Trans id="order.sender.chooseAddress.toSelect">Vybrat</Trans>
  );
  return (
    <CornerWrapper>
      <Typography
        type={TypographyType.BodyRegular}
        color={checked ? colors.gray_mid : colors.red_main}
      >
        {txt}
      </Typography>
    </CornerWrapper>
  );
};

const AddressButton: FC<{
  address: Address;
  checked?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
}> = ({ address, checked, onClick }) => {
  return (
    <BorderButton onClick={onClick} checked={checked}>
      <CornerInfo checked={checked} />
      <AddressView address={address} />
    </BorderButton>
  );
};

const ChooseSavedAddress: FC<{
  selectedId: string;
  invokeClose: ModalInvokeCloseFunction<ChoosenAddress>;
  addresses: {
    origin: Address;
    formData:
      | UsersProfileSenderAddressFormFragment
      | UsersProfileSenderInvoiceAddressFormFragment;
  }[];
}> = ({ invokeClose, addresses, selectedId }) => {
  const list = addresses.map(({ origin, formData }) => (
    <AddressButton
      key={origin.id}
      address={origin}
      checked={selectedId === origin.id}
      onClick={() => invokeClose({ formData, addressId: origin.id })}
    />
  ));

  return (
    <>
      <Trans id="order.sender.chooseAddress">Vyberte adresu</Trans>
      <br />
      {list}
    </>
  );
};

type UpdateFormAction = (values: Partial<SenderFormFields>) => void;

type UseChooseSavedAddressType = {
  selectedAddress: UsersProfileSenderAddressFormFragment | null;
  selectedInvoiceAddress: UsersProfileSenderInvoiceAddressFormFragment | null;
  chooseAddress: (updateForm: UpdateFormAction) => Promise<void>;
  chooseInvoiceAddress: (updateForm: UpdateFormAction) => Promise<void>;
} & UseSavedAddressesType;

export const useChooseSavedAdress = (
  {
    selectedSenderAddressOverridenByUser,
    selectedSenderInvoiceAddressOverridenByUser,
    selectedSenderAddressId,
    selectedSenderInvoiceAddressId,
  }: OtherData,
  setOtherData: (value: SetStateAction<OtherData>) => void,
): UseChooseSavedAddressType => {
  const { showInfo } = useSystemModal();
  const allAddreses = useSavedAddresses();

  //if adrress is not overriden by user, we take otherData.*AddressId or the first one.
  const selectedAddressId = selectedSenderAddressOverridenByUser
    ? null
    : selectedSenderAddressId ?? allAddreses?.addreses?.[0]?.origin?.id ?? null;

  const selectedInvoiceAddressId = selectedSenderInvoiceAddressOverridenByUser
    ? null
    : selectedSenderInvoiceAddressId ??
      allAddreses?.invoiceAddreses?.[0]?.origin?.id ??
      null;

  const selectedAddress = selectedAddressId
    ? allAddreses?.addreses?.find((x) => x.origin.id === selectedAddressId)
        ?.formData
    : null;

  const selectedInvoiceAddress = selectedInvoiceAddressId
    ? allAddreses?.invoiceAddreses?.find(
        (x) => x.origin.id === selectedInvoiceAddressId,
      )?.formData
    : null;

  const showAddressModal = async (
    forInvoice: boolean,
    updateForm: UpdateFormAction,
  ) => {
    const dialogResult = await showInfo<ChoosenAddress>(
      (invokeClose) => (
        <ChooseSavedAddress
          invokeClose={invokeClose}
          addresses={
            forInvoice ? allAddreses.invoiceAddreses : allAddreses.addreses
          }
          selectedId={
            forInvoice ? selectedInvoiceAddressId : selectedSenderAddressId
          }
        />
      ),
      false,
      {
        layout: (message, buttons) => (
          <InfoContent buttons={buttons} spacingBetween="xs">
            {message}
          </InfoContent>
        ),
      },
    );

    if (dialogResult === ModalWellKnowResult.ESC) return;

    const { addressId, formData } = dialogResult as ChoosenAddress;

    updateForm(formData);

    const addrObj: OtherData = forInvoice
      ? {
          selectedSenderInvoiceAddressId: addressId,
          selectedSenderInvoiceAddressOverridenByUser: false,
        }
      : {
          selectedSenderAddressId: addressId,
          selectedSenderAddressOverridenByUser: false,
        };

    setOtherData((otherData) => ({
      ...otherData,
      ...addrObj,
    }));
  };

  const canChooseAddress =
    allAddreses?.addreses?.length > 1 ||
    (!selectedAddress /* = user has overriden address */ &&
      allAddreses?.addreses?.length);

  const canChooseInvoiceAddress =
    allAddreses?.invoiceAddreses?.length > 1 ||
    (!selectedInvoiceAddress /* = user has overriden address */ &&
      allAddreses?.invoiceAddreses?.length);

  const chooseAddress = canChooseAddress
    ? (updateForm: UpdateFormAction) => showAddressModal(false, updateForm)
    : null;

  const chooseInvoiceAddress = canChooseInvoiceAddress
    ? (updateForm: UpdateFormAction) => showAddressModal(true, updateForm)
    : null;

  return {
    selectedAddress,
    selectedInvoiceAddress,
    ...allAddreses,
    chooseAddress,
    chooseInvoiceAddress,
  };
};
