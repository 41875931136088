import {
  BestWayIcon,
  CalendarClockIcon,
  NoteItem,
  Nowrap,
  QRCodeIcon,
  ShieldIcon,
  Strong,
  Typography,
  TypographyType,
} from '@hp/atomic';
import { Money } from '@hp/components';
import { useConfig } from '@hp/config';
import { Trans } from '@lingui/react';
import React, { FC } from 'react';

import { NoteHeading, NoteStyled } from './styled';

interface Props {
  isCodAllowed: boolean;
}

export const Included: FC<Props> = ({ isCodAllowed }) => {
  const {
    config: {
      constraints: { maxInsurance },
    },
  } = useConfig();

  return (
    <NoteStyled marginSize="m">
      <NoteHeading>
        <Strong>
          <Typography type={TypographyType.BodyLight}>
            <Trans id="homepage.included.heading">Služba zahrnuje</Trans>:
          </Typography>
        </Strong>
      </NoteHeading>

      <NoteItem icon={<CalendarClockIcon />}>
        {isCodAllowed ? (
          <Trans id="homepage.included.printAddressLabel">
            Doručení následující pracovní den, <Nowrap>s dobírkou</Nowrap>{' '}
            <Nowrap>i bez</Nowrap>
          </Trans>
        ) : (
          <Trans id="homepage.included.printAddressLabelWithoutCOD">
            Doručení následující pracovní den,{' '}
            <Nowrap>pouze bez dobírky</Nowrap>
          </Trans>
        )}
      </NoteItem>

      <NoteItem icon={<BestWayIcon />}>
        <Trans id="homepage.included.deliveryGuarantee">
          Příjemce si může změnit adresu <Nowrap>i termín</Nowrap>
        </Trans>
      </NoteItem>

      <NoteItem icon={<ShieldIcon />}>
        <Trans id="homepage.included.insurance">
          Pojištění do{' '}
          <Nowrap>
            <Money currency="CZK">{maxInsurance}</Money>
          </Nowrap>{' '}
          <Nowrap>v ceně</Nowrap> přepravy
        </Trans>
      </NoteItem>

      <NoteItem icon={<QRCodeIcon />} noMargin>
        <Trans id="homepage.included.label">
          Přepravní štítek, který nemusíte tisknout
        </Trans>
      </NoteItem>
    </NoteStyled>
  );
};
