import { Inner } from '@hp/layout';
import React from 'react';

import { TermsAndConditionsContent } from '../content/TermsAndConditions';

export const TermsAndConditions = () => {
  return (
    <Inner>
      <TermsAndConditionsContent />
    </Inner>
  );
};
