import { RouteNameType, useRouter } from '@hp/seo';
import { toArray } from '@hp/utils';
import React from 'react';

type RequiredProps = {
  fallbackRoute?: RouteNameType;
  data: unknown | unknown[];
  children: () => React.ReactNode;
};

export const Required = ({ data, fallbackRoute, children }: RequiredProps) => {
  const router = useRouter();
  const required = toArray(data);
  const missing = required.some(
    (x) => x === null || x === undefined || x === false,
  );
  if (missing) {
    if (fallbackRoute) {
      router.push(fallbackRoute);
    }
    return null;
  }

  return <>{typeof children === 'function' ? children() : children}</>;
};
