import React from 'react';

import { Coordinates } from '../../types';

const deg2rad = (deg: number) => deg * (Math.PI / 180);
const earthRadius = 6371; // Radius of the earth in km

/** computes distance by Haversine formula */
export const computeDistance = (
  a: Partial<Coordinates>,
  b: Partial<Coordinates>,
) => {
  if (!a || !b || !a.latitude || !a.longitude || !b.latitude || !b.longitude)
    return null;

  const dLat = deg2rad(b.latitude - a.latitude);
  const dLon = deg2rad(b.longitude - a.longitude);
  const s =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(a.latitude)) *
      Math.cos(deg2rad(b.latitude)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(s), Math.sqrt(1 - s));
  const d = earthRadius * c; // Distance in km
  
  return d > 2 ? (
    <>{`${Math.round(d)}`}&nbsp;km</>
  ) : (
    <>{`${Math.round(d * 10) * 100}`}&nbsp;m</>
  );
};
