import {
  ButtonType,
  ConditionalWrapper,
  Strong,
  Typography,
  TypographyType,
} from '@hp/atomic';
import { SpaceBetween } from '@hp/layout';
import { RouteNameType } from '@hp/seo';
import { colors } from '@hp/theme';
import { hasFeature } from '@hp/utils';
import { Trans } from '@lingui/macro';
import React from 'react';

import {
  ChangeButton,
  ErrorInfo,
  Heading,
  HeadingV2,
  NoteSummaryStyled,
} from './styled';

type SummarySectionProps = {
  title: JSX.Element;
  route?: RouteNameType;
  titleSuffix?: JSX.Element;
  buttonClassName?: string;
  onClick?: () => void;
  error?: boolean;
  framed?: boolean;
};

export const SummarySection: React.FC<SummarySectionProps> = ({
  title,
  titleSuffix,
  route,
  onClick,
  buttonClassName,
  children,
  error,
  framed = true,
}) => {
  return (
    <ConditionalWrapper
      condition={framed}
      wrapper={(children) => (
        <NoteSummaryStyled marginSize="m">{children}</NoteSummaryStyled>
      )}
    >
      <SpaceBetween>
        {!hasFeature('v3') || !framed ? (
          <HeadingV2>
            <Strong>{title}</Strong> {titleSuffix}
          </HeadingV2>
        ) : (
          <Heading>
            {title} {titleSuffix}
          </Heading>
        )}

        {route && (
          <ChangeButton
            buttonType={ButtonType.TEXT}
            className={buttonClassName}
            onClick={onClick}
            data-test={`sumary.button.change.${route}`}
          >
            <Trans id="common.button.change">Změnit</Trans>
          </ChangeButton>
        )}
      </SpaceBetween>

      <Typography type={TypographyType.BodySmallLight} color={colors.black}>
        {children}
      </Typography>

      {error && (
        <ErrorInfo>
          <Typography type={TypographyType.BodySmallLight} color={colors.error}>
            <Trans id="order.summary.sectionErrorInfo">
              Sekce <Strong>&ldquo;{title}&ldquo;</Strong> obsahuje chyby nebo
              již nevalidní údaje. Klikněte prosím na tlačítko ZMĚNIT
              a&nbsp;upravte objednávku.
            </Trans>
          </Typography>
        </ErrorInfo>
      )}
    </ConditionalWrapper>
  );
};
