import { TermsModals } from '@hp/legal';
import React, { FC, useState } from 'react';

import { SenderForm } from './SenderForm';
import { SenderProps } from './types';

export const Sender: FC<SenderProps> = ({ selectedParcelShop }) => {
  const [termsModalVisible, setTermsModalVisible] = useState(false);
  const [privacyModalVisible, setPrivacyModalVisible] = useState(false);

  return (
    <>
      <SenderForm
        onTermsModalVisibility={(visible) => setTermsModalVisible(visible)}
        onPrivacyModalVisibility={(visible) => setPrivacyModalVisible(visible)}
        selectedParcelShop={selectedParcelShop}
      />
      <TermsModals
        termsModalVisible={termsModalVisible}
        privacyModalVisible={privacyModalVisible}
        setTermsModalVisible={setTermsModalVisible}
        setPrivacyModalVisible={setPrivacyModalVisible}
      />
    </>
  );
};
