import { Address } from '@hp/core/shared';
import { objectEquals } from '@hp/utils';

import { OtherData, SenderFormFields } from '../../types';
import {
  UsersProfileSenderAddressFormFragment,
  UsersProfileSenderInvoiceAddressFormFragment,
} from './types';

export const convertToProfileAddressFieldsFragment = (
  values: SenderFormFields,
) => {
  //for defensive reason we dont use ...rest pattern !
  const { sender, city, street, ico, dic, phone, name, postalCode } = values;

  const valuesProfileFields: UsersProfileSenderAddressFormFragment = {
    sender,
    city,
    street,
    ico,
    dic,
    phone,
    name,
    postalCode,
  };

  return valuesProfileFields;
};

export const convertToProfileInvoiceAddressFieldsFragment = (
  values: SenderFormFields,
) => {
  //for defensive reason we dont use ...rest pattern !
  const {
    cityInvoice,
    streetInvoice,
    icoInvoice,
    dicInvoice,
    nameInvoice,
    postalCodeInvoice,
  } = values;

  const valuesProfileFields: UsersProfileSenderInvoiceAddressFormFragment = {
    cityInvoice,
    streetInvoice,
    icoInvoice,
    dicInvoice,
    nameInvoice,
    postalCodeInvoice,
  };

  return valuesProfileFields;
};

export const checkFormForChanges = (
  profileAddreses: Array<{
    origin: Address;
    formData: UsersProfileSenderAddressFormFragment;
  }>,
  profileInvoiceAddreses: Array<{
    origin;
    formData: UsersProfileSenderInvoiceAddressFormFragment;
  }>,
  values: SenderFormFields,
) => {
  const hasUserOverridenAddress = (values: SenderFormFields) => {
    const formData = convertToProfileAddressFieldsFragment(values);
    const equalAddress = profileAddreses.find((profileAddress) => {
      return objectEquals<UsersProfileSenderAddressFormFragment>(
        formData,
        profileAddress.formData,
        false,
      );
    });

    return equalAddress ? equalAddress.origin.id : undefined;
  };

  const hasUserOverridenInvoiceAddress = (values: SenderFormFields) => {
    const formData = convertToProfileInvoiceAddressFieldsFragment(values);
    const equalAddress = profileInvoiceAddreses.find(
      (profileInvoiceAddress) => {
        return objectEquals<UsersProfileSenderInvoiceAddressFormFragment>(
          formData,
          profileInvoiceAddress.formData,
          false,
        );
      },
    );

    return equalAddress ? equalAddress.origin.id : undefined;
  };

  let willSave = false;

  const otherDataChanges: Pick<
    OtherData,
    | 'selectedSenderAddressId'
    | 'selectedSenderInvoiceAddressId'
    | 'selectedSenderAddressOverridenByUser'
    | 'selectedSenderInvoiceAddressOverridenByUser'
    | 'selectedSenderInvoiceAddressUsageOverridenByUser'
  > = {};

  if (profileAddreses) {
    otherDataChanges.selectedSenderAddressId = hasUserOverridenAddress(values);
    otherDataChanges.selectedSenderAddressOverridenByUser = !otherDataChanges.selectedSenderAddressId;
    willSave = true;
  }

  if (profileInvoiceAddreses) {
    otherDataChanges.selectedSenderInvoiceAddressId = hasUserOverridenInvoiceAddress(
      values,
    );
    otherDataChanges.selectedSenderInvoiceAddressOverridenByUser = !otherDataChanges.selectedSenderInvoiceAddressId;
    //the user does not want to use invoice address even it exists
    otherDataChanges.selectedSenderInvoiceAddressUsageOverridenByUser = !values.invoiceToAnotherAddress;
    willSave = true;
  }

  return willSave ? otherDataChanges : null;
};
