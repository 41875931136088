import { NoteButton } from '@hp/atomic';
import { spacing } from '@hp/theme';
import styled from 'styled-components';

export const NoteButtonStyled = styled(NoteButton)`
  margin: ${spacing.m} 0 0 0;
  padding: 0;
`;

export const HeaderWrapper = styled.div`
  margin: ${spacing.xs} 0;
  line-height: 10px;
`;

export const PrivacyInformation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
