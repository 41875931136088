import { useUserContext } from '@hp/context';
import { useVerifyEmailMutation } from '@hp/order/src/graphql';
import { useRouter } from '@hp/seo/src/hooks/useRouter';
import { deArray } from '@hp/utils';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { NextPageContext } from 'next';
import { useEffect, useState } from 'react';

import { useClearLocalStorage } from './useClearLocalStorage';

export type VerifyEmailParams = {
  verified: string;
};

export type VerifyEmailQuery = NextPageContext['query'] &
  Partial<{ verified: NextPageContext['query']['any'] }>;

export const useVerifyEmailId = (
  client?: ApolloClient<NormalizedCacheObject>,
) => {
  const [invoke, { data }] = useVerifyEmailMutation({ client });
  const { user, logout, loading } = useUserContext();
  const { clearLocalStorage } = useClearLocalStorage();
  const { query, push } = useRouter();
  const [cleared, setCleared] = useState(false);
  const id = deArray((query as VerifyEmailQuery)?.verified);
  useEffect(() => {
    if (id)
      //todo: handle errors
      invoke({ variables: { userId: id } }).catch((e) => console.error(e));
  }, [id, invoke]);
  useEffect(() => {
    //we clear localStorage (when email is differ) and then set new into localStorage
    if (data?.verifyEmail && !loading && !cleared) {
      clearLocalStorage(data?.verifyEmail, true);

      setCleared(true);
      if (user && user.user.email != data?.verifyEmail) {
        /* logout switch to homepage, we need go again to login */
        logout().then(() => {
          push('login');
        });
      }
    }
  }, [user, loading, data?.verifyEmail]);
};
