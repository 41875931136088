import {
  ArrowIcon,
  Axes3DIcon,
  Button,
  ButtonType,
  CourierCheckedIcon,
  Note,
  NoteItem,
  Typography,
  TypographyType,
} from '@hp/atomic';
import { AlignRightRow, CenteredRow, Inner } from '@hp/layout';
import { labels } from '@hp/locale';
import { colors } from '@hp/theme';
import { Trans } from '@lingui/react';
import React, { FC } from 'react';

import { Dimension } from '../../types';

type ConfirmContentProps = {
  dimension: Dimension;
  reset: () => void;
  hasProperDimension: boolean;
  circumference: number;
};

export const ConfirmFormContent: FC<ConfirmContentProps> = ({
  dimension: { height, width, length },
  reset,
  hasProperDimension,
}) => {
  const NoteIcon = hasProperDimension ? <CourierCheckedIcon /> : <Axes3DIcon />;
  const NoteText = hasProperDimension ? (
    <Trans id="order.parcel.maxSizeCalculator.shipmentValidForTransport">
      Váš balík se nám vejde do auta.
    </Trans>
  ) : (
    <Trans id="order.parcel.maxSizeCalculator.shipmentNotValidForTransport">
      Váš balík se nám bohužel nevejde do auta.
    </Trans>
  );

  return (
    <>
      <Inner paddingY="m" paddingX="m">
        <Typography type={TypographyType.BodySmallLight} color={colors.black}>
          {labels.dimension.length}: {length} cm
          <br />
          {labels.dimension.width}: {width} cm
          <br />
          {labels.dimension.height}: {height} cm
        </Typography>
      </Inner>

      <Note disablePaddingTop={false}>
        <NoteItem icon={NoteIcon}>
          <Typography type={TypographyType.BodyMicro} color={colors.black}>
            {NoteText}
          </Typography>
        </NoteItem>
      </Note>

      <Inner paddingX="m">
        <AlignRightRow>
          <CenteredRow marginSize="m" noBottomMargin={!hasProperDimension}>
            <Button
              type="button"
              className="gaButton gaButtonSubmit_computeAgain"
              fullWidth
              buttonType={
                hasProperDimension ? ButtonType.TEXT : ButtonType.PRIMARY
              }
              onClick={(e) => {
                e.nativeEvent.stopImmediatePropagation();
                reset();
              }}
              data-test="parcel.calculator.button.computeAgain"
            >
              <Trans id="order.parcel.maxSizeCalculator.computeAgain">
                Spočítat znovu
              </Trans>
            </Button>
          </CenteredRow>
          {hasProperDimension && (
            <Button
              fullWidth
              type="submit"
              className="gaButton gaButtonSubmit_computeContinue"
              buttonType={ButtonType.PRIMARY}
              icon={<ArrowIcon />}
              animate
              data-test="parcel.calculator.button.continue"
            >
              <Trans id="common.button.continue">Pokračovat</Trans>
            </Button>
          )}
        </AlignRightRow>
      </Inner>
    </>
  );
};
