import { Typography, TypographyType } from '@hp/atomic';
import { useLanguage } from '@hp/core/src/providers/LanguageProvider';
import { DayOfTheWeek, getDayName } from '@hp/utils';
import React from 'react';
import { useI18n } from '@hp/locale';

import { OpenClosePair, OpeningHours as OpeningHoursType } from '../../types';
import { allDayNames, normalizeOpeniningHours } from './openingHoursUtils';
import {
  OpeningHoursContainer,
  OpeningHoursItem,
  OpeningHoursRow,
} from './styled';

type OpeningHoursProps = {
  data: OpeningHoursType;
};

export const OpeningHours: React.FC<OpeningHoursProps> = ({ data }) => {
  const { language } = useLanguage();
  const i18n = useI18n();
  const normalizedData = normalizeOpeniningHours(data);

  return (
    <OpeningHoursContainer>
      {(Object.entries(normalizedData) as Array<
        [DayOfTheWeek, OpenClosePair[]]
      >)
        .sort((a, b) => allDayNames.indexOf(a[0]) - allDayNames.indexOf(b[0]))
        .map(([key, pairs]) => {
          const day = key as DayOfTheWeek;

          return (
            <OpeningHoursRow key={day}>
              <OpeningHoursItem>
                <Typography type={TypographyType.BodyMicro}>
                  {getDayName(day, language, i18n, true)}
                </Typography>
              </OpeningHoursItem>

              {pairs.map(({ open, close }, i) => (
                <OpeningHoursItem key={i}>
                  <Typography type={TypographyType.BodyMicroLight}>
                    {open} - {close}
                  </Typography>
                </OpeningHoursItem>
              ))}
            </OpeningHoursRow>
          );
        })}
    </OpeningHoursContainer>
  );
};
