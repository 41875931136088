import { Button, ButtonType } from '@hp/atomic';
import { useConfig } from '@hp/config';
import {
  CaseQuery,
  DeepPartial,
  DynamicConfigRoot,
  PickupType,
  PriceInput,
} from '@hp/core/shared';
import { useLanguage } from '@hp/core/src/providers/LanguageProvider';
import {
  ModalWellKnowResult,
  useSystemModal,
} from '@hp/core/src/providers/SystemModal';
import { QueryParamModeValue, useRouter, useUtmSource } from '@hp/seo';
import { merge, useObjectLocalStorage } from '@hp/utils';
import { Trans } from '@lingui/macro';
import * as Sentry from '@sentry/nextjs';
import { isPast, isToday } from 'date-fns';
import React, { useState } from 'react';

import {
  useCaseQuery,
  useCreateCaseMutation,
  useCreatePaymentMutation,
} from '../../graphql';
import {
  useAllFormsDataValidation,
  useFetchForParcelShopIds,
} from '../../hooks';
import {
  AdditionalServicesFormFields,
  AllFormsData,
  HowToSendFormFields,
  ParcelFormProps,
  RecipientFormFields,
  SenderFormFields,
} from '../../types';
import {
  computePrice,
  createCaseData,
  readCaseData,
  refillStorage,
} from '../../utils';

const getPrice = (
  caseData: CaseQuery['case'],
  allFormsData: AllFormsData | undefined,
  isResendMode: boolean,
  config: DynamicConfigRoot,
): PriceInput => {
  if (caseData && !isResendMode) return caseData.price;

  if (allFormsData) return computePrice(allFormsData, config);

  return null;
};

export const useSummary = (
  id: string | null | undefined,
  mode: QueryParamModeValue | undefined | null,
  paymentError: boolean,
) => {
  const { config } = useConfig();
  const [parcel, setParcel] = useObjectLocalStorage<ParcelFormProps>('parcel');
  const [recipient, setRecipient] = useObjectLocalStorage<RecipientFormFields>(
    'recipient',
  );
  const [sender, setSender] = useObjectLocalStorage<SenderFormFields>('sender');
  const [howToSend, setHowToSend] = useObjectLocalStorage<HowToSendFormFields>(
    'howToSend',
  );
  const [additionalServices, setAdditionalParams] = useObjectLocalStorage<
    AdditionalServicesFormFields
  >('additionalServices');
  const [utmParams] = useUtmSource();
  const { getGlobalUrl, push, replace } = useRouter();
  const { language } = useLanguage();
  const { showErrorInfo } = useSystemModal();
  const [createCase, mutationData] = useCreateCaseMutation();
  const [createPayment] = useCreatePaymentMutation();
  const [sending, setSending] = useState(false);
  const [overrides, setOverrides] = useState<DeepPartial<AllFormsData>>({});
  const resend = mode === QueryParamModeValue.resend;

  const isOldDayForPickup = (pickupDate: string) => {
    const when = Date.parse(pickupDate);

    return isToday(when) || isPast(when);
  };

  const { data: caseDataQuery, loading, error } = useCaseQuery({
    variables: {
      id,
    },
    skip: !id || !!mutationData.data,
  });

  const caseData = caseDataQuery?.case ?? mutationData?.data?.createCase;

  const allFormsDataWithoutOverrides: AllFormsData = caseData
    ? readCaseData(caseData, config)
    : error || loading
    ? null
    : {
        parcel,
        recipient,
        sender,
        additionalServices,
        howToSend,
      };
  /** if allFormsData not null, we merge them with overrides */
  const allFormsData = allFormsDataWithoutOverrides
    ? merge<AllFormsData>(
        [allFormsDataWithoutOverrides, overrides],
        [null, undefined],
      )
    : allFormsDataWithoutOverrides;

  const price = getPrice(caseData, allFormsData, resend, config);

  const allFormsDataValidation = useAllFormsDataValidation(allFormsData, {
    /* skip when there are no data to validation. Do not skip when 'resend' mode, do not skip when still no caseData */
    skip: !allFormsDataWithoutOverrides || !(resend || !caseData),
  });

  const shopData = useFetchForParcelShopIds(allFormsData?.howToSend, {
    //not necessary load when going back from gateway (and payment was sucessfull),
    //because there is redirect to thankYou-page
    skip: mode === QueryParamModeValue.thankYou && !paymentError,
  });

  const fillStorage = caseData
    ? () => {
        refillStorage(
          allFormsData,
          setHowToSend,
          setParcel,
          setRecipient,
          setSender,
          setAdditionalParams,
        );
      }
    : null;

  const promptData = {
    variableSymbol: resend && caseData?.cashOnDelivery?.variableSymbol,
    sendWhen:
      resend && caseData?.sendHow === PickupType.COURIER && caseData?.sendWhen,
    cashOnDelivery: resend && !!caseData?.cashOnDelivery,
    cashOnDeliveryValue:
      resend && caseData?.cashOnDelivery?.cashOnDeliveryValue,
  };

  const setPrompData = ({
    sendWhen,
    variableSymbol,
    cashOnDelivery,
    cashOnDeliveryValue,
  }: typeof promptData) => {
    setOverrides(
      merge([
        overrides,
        {
          howToSend: { sendWhen },
          additionalServices: {
            variableSymbol,
            cashOnDelivery,
            cashOnDeliveryValue,
          },
        },
      ]),
    );
  };

  const confirm = async () => {
    if (
      allFormsData.howToSend.pickupType === PickupType.COURIER &&
      isOldDayForPickup(allFormsData.howToSend.sendWhen)
    ) {
      if (caseData?.id) {
        await showErrorInfo(
          <Trans id="order.summary.oldDateValidationInfo">
            Omlouváme se, platba této zásilky již není povolena, protože zásilku
            již nestihneme ve zvolený čas vyzvednout.
          </Trans>,
        );
        return;
      }

      await showErrorInfo(
        <Trans id="order.summary.futureDateValidationInfo">
          Omlouváme se, ale v tento den už vaši zásilku nestihneme vyzvednout.
          Vrátíme vás na stránku, kde si prosím vyberte nové datum.
        </Trans>,
        (invokeClose) => (
          <Button
            buttonType={ButtonType.PRIMARY}
            onClick={() => invokeClose(ModalWellKnowResult.OK)}
          >
            <Trans id="common.button.ok">OK</Trans>
          </Button>
        ),
      );
      push('orderSender');

      return;
    }

    try {
      setSending(true);
      const createNewCase = async () => {
        const data = createCaseData(
          allFormsData,
          config,
          language,
          utmParams,
          shopData?.delivery?.data,
        );

        const {
          data: { createCase: outData },
        } = await createCase({
          variables: { data },
        });

        //it just replace url, but flow continues
        await replace('orderSummary', {
          id: outData.id,
          mode: '',
        });

        return outData.id;
      };

      const id = !resend
        ? caseData?.id ?? (await createNewCase())
        : await createNewCase();

      const { data: paymentData } = await createPayment({
        variables: {
          id,
          redirectUrl: getGlobalUrl('orderSummary', {
            id,
            mode: QueryParamModeValue.thankYou,
          }),
        },
      });

      //window.open(paymentData.createPayment.redirectUri); //just for tests in dev !!!!!!!!
      document.location.href = paymentData.createPayment.redirectUri;
    } catch (e) {
      Sentry.captureException(e);
      console.error(e);
      await showErrorInfo();
    } finally {
      setSending(false);
    }
  };

  return {
    ...allFormsData,
    price,
    confirm,
    sending,
    /** when TRUE, any checks for payment has no sense (do not call usePayment) */
    creatingPayment: !id || !!mutationData.data,
    /** e.g. invalid case-id */
    error,
    /** data about case are still loading */
    loading,
    /** validated existing caseId */
    caseId: caseData?.id,
    fillStorage,
    ...allFormsDataValidation,
    /** data for modal which should ask necessari info */
    promptData,
    setPrompData,
    shopData,
  };
};
