import { useConfig } from '@hp/config';
import { useUserContext } from '@hp/context';
import { PickupType } from '@hp/core/shared';
import { useRouter } from '@hp/seo';
import {
  hasFeature,
  pickupDateOptions,
  useObjectLocalStorage,
} from '@hp/utils';
import { useEffect, useState } from 'react';
import { useI18n } from '@hp/locale';

import { useOtherDataLocalStorage } from '../../hooks/useOtherDataLocalStorage';
import {
  HowToSendFormFields,
  ParcelFormProps,
  ParcelShop,
  SenderFormFields,
} from '../../types';
import { emptyProfileValues } from './emptyProfileValues';
import { useChooseSavedAdress } from './useChooseSavedAdress';
import { useSenderValidationSchema } from './useSenderValidationSchema';
import { checkFormForChanges } from './utils';

export const useSenderForm = (selectedParcelShop?: ParcelShop) => {
  const router = useRouter();
  const [howToSendFormData, saveHowToSendFormData] = useObjectLocalStorage<
    HowToSendFormFields
  >('howToSend');
  const [parcel] = useObjectLocalStorage<ParcelFormProps>('parcel');
  const [other, setOther] = useOtherDataLocalStorage();
  const { config } = useConfig();
  const i18n = useI18n();

  const isPickupFromParcelShopOrBox =
    howToSendFormData.pickupType !== PickupType.COURIER;
  const changePickupPoint = () => {
    router.push('orderDelivery');
  };

  const {
    userExists,
    user,
    loading: userIsLoading,
    createUser,
  } = useUserContext();

  const validationSchema = useSenderValidationSchema(
    howToSendFormData.sendFromCountry,
    user,
    isPickupFromParcelShopOrBox,
  );

  const [emailIsKnown, setEmailIsKnown] = useState(false);
  const {
    selectedAddress,
    selectedInvoiceAddress,
    addreses: profileAddreses,
    invoiceAddreses: profileInvoiceAddreses,
    chooseAddress,
    chooseInvoiceAddress,
  } = useChooseSavedAdress(other, setOther);

  const checkAccountByEmail = (email: string) => {
    userExists(email).then(setEmailIsKnown);
  };

  const pickupDateOpts = pickupDateOptions(
    howToSendFormData.sendFromCountry,
    i18n,
  );

  const emptyValues: SenderFormFields = {
    ...emptyProfileValues,
    createAccount: true,
    pickup: !hasFeature('v3')
      ? PickupType.COURIER
      : howToSendFormData.pickupType ?? PickupType.PARCEL_SHOP,
    sendWhen: pickupDateOpts[0].value,
    invoiceToAnotherAddress: false,
    note: '',
    termsAgreement: false,
    newsletterAgreement: true,
    password: '',
    email: user ? user.user.email : '',
  };

  const [savedFormData, saveFormData] = useObjectLocalStorage(
    'sender',
    emptyValues,
  );
  const keys = Object.keys(savedFormData);
  if (keys.length === 1 && keys[0] === 'email') {
    //see useVerifyEmail, where we use senderLocalStorage for remembering email
    emptyValues.email = savedFormData.email;
    saveFormData(emptyValues);
  }

  const formData: SenderFormFields = {
    ...savedFormData,
    ...(selectedAddress ?? {}),
    ...(selectedInvoiceAddress ?? {}),
    email: user ? user.user.email : savedFormData.email,
    createAccount: user ? false : savedFormData.createAccount,
    invoiceToAnotherAddress: selectedInvoiceAddress
      ? !other?.selectedSenderInvoiceAddressUsageOverridenByUser
      : savedFormData.invoiceToAnotherAddress,
    pickup: howToSendFormData.pickupType,
    sendWhen: howToSendFormData.sendWhen,
  };

  useEffect(() => {
    if (formData.email && userExists) {
      checkAccountByEmail(formData.email);
    }
  }, [!!formData.email, userExists]);

  //fix when old date is stored in localStorage
  if (!pickupDateOpts.some((x) => x.value === formData.sendWhen)) {
    formData.sendWhen = pickupDateOpts[0].value;
  }

  const saveFormDataWithoutPassword = ({
    password,
    ...values
  }: SenderFormFields) => {
    const changes = checkFormForChanges(
      profileAddreses,
      profileInvoiceAddreses,
      values,
    );

    if (changes) setOther({ ...other, ...changes });

    saveFormData({ ...values, password: '', createAccount: false });
  };

  const handleSubmit = async (values: SenderFormFields) => {
    saveFormDataWithoutPassword(values);

    const { pickup, sendWhen, createAccount, password, email } = values;

    //we also should correct a first form
    saveHowToSendFormData({
      ...howToSendFormData,
      pickupType: pickup,
      sendWhen: sendWhen,
      selectedParcelShopId: selectedParcelShop?.parcelshop_id,
      selectedParcelShopName: selectedParcelShop?.parcelshop_name,
      selectedParcelShopAddress: selectedParcelShop?.parcelshop_address,
      selectedParcelShopCodAllowed: selectedParcelShop?.parcelshop_cod_allowed,
    });

    if (!user && createAccount) {
      await createUser(email, password);
    }

    router.push('orderAdditionalServices');
  };

  return {
    canSwitchToParcelShop: parcel
      ? parcel.items.every(
          (item) => item.weight <= config.constraints.maxWeightParcelShop,
        )
      : true,
    saveFormData: saveFormDataWithoutPassword,
    handleSubmit,
    initialValues: formData,
    checkAccountByEmail,
    emailIsKnown,
    user,
    userIsLoading,
    chooseAddress,
    chooseInvoiceAddress,
    validationSchema,
    howToSendFormData,
    emptyValues,
    pickupParcelShopId: isPickupFromParcelShopOrBox
      ? howToSendFormData.pickupId
      : undefined,
    changePickupPoint: isPickupFromParcelShopOrBox
      ? changePickupPoint
      : undefined,
  };
};
