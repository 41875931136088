import { colors } from '@hp/theme';
import { Trans } from '@lingui/react';
import React from 'react';

import { Heading, Wrapper } from './styled';

export const TermsAndConditionsContent = () => {
  return (
    <Wrapper>
      <Heading color={colors.black}>
        <Trans id="legal.terms.title">Všeobecné obchodní podmínky</Trans>
      </Heading>
      <p>
        Bacon ipsum dolor amet boudin biltong sausage tri-tip hamburger fatback
        corned beef kevin. Cupim pastrami bacon salami chicken sausage picanha
        filet mignon leberkas shoulder turkey. Ham boudin cupim cow buffalo
        porchetta. Fatback chuck chislic meatloaf short loin, pork frankfurter
        spare ribs alcatra leberkas pancetta swine bresaola jowl shoulder.
        Meatloaf beef chicken turducken pork belly t-bone buffalo bresaola ball
        tip chuck jerky frankfurter ribeye sausage.
      </p>
      <p>
        Pig ham hock chislic, meatloaf frankfurter drumstick salami meatball
        shankle. Shankle biltong pancetta filet mignon pastrami. Swine tri-tip
        beef ribs burgdoggen. Tail andouille pork belly biltong ham. Ribeye
        swine short loin venison hamburger. Corned beef sirloin meatloaf jowl
        short ribs swine. Spare ribs chuck pork chop landjaeger beef ribs kevin.
      </p>
      <p>
        Flank ham turducken, pig chuck frankfurter sausage tail boudin short
        loin jerky landjaeger ribeye. Shankle flank pork meatball turkey, tail
        boudin rump sirloin landjaeger tongue picanha short ribs pancetta beef.
        Beef ribeye ball tip, shank strip steak salami jowl rump burgdoggen
        flank bacon cupim turkey sirloin. Capicola porchetta chuck, beef strip
        steak ball tip pork chop hamburger pastrami filet mignon spare ribs rump
        turkey. Pork loin picanha pig, short loin pancetta pastrami swine
        alcatra salami prosciutto meatloaf kevin shankle buffalo jowl.
      </p>
      <p>
        Chicken porchetta alcatra flank, tri-tip meatloaf short loin t-bone
        bresaola hamburger sausage picanha buffalo short ribs turkey. Cow
        meatball t-bone chislic pig ground round short loin capicola andouille
        salami. Boudin alcatra ball tip sausage fatback short ribs porchetta
        rump beef. Drumstick hamburger filet mignon alcatra buffalo sausage.
        Landjaeger chicken jowl corned beef, sirloin strip steak brisket cupim
        porchetta shank turkey.
      </p>
      <p>
        Bacon ipsum dolor amet boudin biltong sausage tri-tip hamburger fatback
        corned beef kevin. Cupim pastrami bacon salami chicken sausage picanha
        filet mignon leberkas shoulder turkey. Ham boudin cupim cow buffalo
        porchetta. Fatback chuck chislic meatloaf short loin, pork frankfurter
        spare ribs alcatra leberkas pancetta swine bresaola jowl shoulder.
        Meatloaf beef chicken turducken pork belly t-bone buffalo bresaola ball
        tip chuck jerky frankfurter ribeye sausage.
      </p>
      <p>
        Pig ham hock chislic, meatloaf frankfurter drumstick salami meatball
        shankle. Shankle biltong pancetta filet mignon pastrami. Swine tri-tip
        beef ribs burgdoggen. Tail andouille pork belly biltong ham. Ribeye
        swine short loin venison hamburger. Corned beef sirloin meatloaf jowl
        short ribs swine. Spare ribs chuck pork chop landjaeger beef ribs kevin.
      </p>
      <p>
        Flank ham turducken, pig chuck frankfurter sausage tail boudin short
        loin jerky landjaeger ribeye. Shankle flank pork meatball turkey, tail
        boudin rump sirloin landjaeger tongue picanha short ribs pancetta beef.
        Beef ribeye ball tip, shank strip steak salami jowl rump burgdoggen
        flank bacon cupim turkey sirloin. Capicola porchetta chuck, beef strip
        steak ball tip pork chop hamburger pastrami filet mignon spare ribs rump
        turkey. Pork loin picanha pig, short loin pancetta pastrami swine
        alcatra salami prosciutto meatloaf kevin shankle buffalo jowl.
      </p>
      <p>
        Chicken porchetta alcatra flank, tri-tip meatloaf short loin t-bone
        bresaola hamburger sausage picanha buffalo short ribs turkey. Cow
        meatball t-bone chislic pig ground round short loin capicola andouille
        salami. Boudin alcatra ball tip sausage fatback short ribs porchetta
        rump beef. Drumstick hamburger filet mignon alcatra buffalo sausage.
        Landjaeger chicken jowl corned beef, sirloin strip steak brisket cupim
        porchetta shank turkey.
      </p>
    </Wrapper>
  );
};
