import { AddressType } from '@hp/core/shared';

import { UsersProfileSenderFormFragment } from './types';

export const emptyProfileValues: UsersProfileSenderFormFragment = {
  sender: AddressType.PERSON,
  name: '',
  ico: '',
  dic: '',
  street: '',
  city: '',
  postalCode: '',
  phone: '',
  nameInvoice: '',
  icoInvoice: '',
  dicInvoice: '',
  streetInvoice: '',
  cityInvoice: '',
  postalCodeInvoice: '',
};
