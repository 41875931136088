import { Button, ButtonType } from '@hp/atomic';
import {
  ElementBuilderFunction,
  ModalWellKnowResult,
  useSystemModal,
} from '@hp/core/src/providers/SystemModal';
import { Trans } from '@lingui/react';
import React from 'react';

import { ParcelShopItemType } from '../../types';
import { ParcelShopItemDetail } from './ParcelShopItemDetail';
import { ParcelShopMode } from './types';

const buttons: ElementBuilderFunction = (invokeClose) => (
  <Button
    buttonType={ButtonType.PRIMARY}
    onClick={() => invokeClose(ModalWellKnowResult.OK)}
  >
    <Trans id="common.button.ok">OK</Trans>
  </Button>
);

export const useDetailDialog = () => {
  const { showInfo } = useSystemModal();

  const options = {
    desktopMaxWidth: 1200,
  };

  const handler = (item: ParcelShopItemType, type: ParcelShopMode) => {
    showInfo(
      <ParcelShopItemDetail item={item} mode={type} />,
      buttons,
      options,
    );
  };

  return { handler };
};
