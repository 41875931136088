import {
  ButtonType,
  ConditionalWrapper,
  Loading,
  Typography,
  TypographyType,
} from '@hp/atomic';
import { PickupType } from '@hp/core/shared';
import { useLanguage } from '@hp/core/src/providers/LanguageProvider';
import { colors } from '@hp/theme';
import { hasFeature } from '@hp/utils';
import { Trans } from '@lingui/macro';
import React, { FC } from 'react';

import { Error } from '../../../../../packages/form';
import { useFetchForParcelShopId } from '../../hooks';
import { ParcelShopItemType } from '../../types';
import { ParcelShopOpeningState } from './ParcelShopOpeningState';
import {
  Arrow,
  ButtonStyled,
  EditButton,
  ItemContentMain,
  ItemRow,
  LinkStyled,
  NameStyled,
  NameWrapper,
  ParcelShopItemErrorBlock,
  ParcelShopListItem,
  PickupDeliveryIconStyled,
} from './styled';
import { ParcelShopItemProps } from './types';

export const getErrorMsg = (error: any) => {
  return (
    <Error>
      {((error?.message ?? '') as string).includes('404') ? (
        <Trans id="order.summary.notExistingPlace">
          Místo zřejmě bylo v mezičase zrušeno, prosím vyberte jiné.
        </Trans>
      ) : (
        <Trans id="order.summary.errorLoading">Místo se nepovedlo načíst</Trans>
      )}
    </Error>
  );
};

export const ParcelShopItem: FC<ParcelShopItemProps> = ({
  item,
  ...otherProps
}) => {
  const hasOwnData = typeof item === 'object';
  const { data, loading, error } = useFetchForParcelShopId(
    hasOwnData || !item ? null : (item as string),
  );

  if (!item) return null;

  if (hasOwnData)
    return (
      <ParcelShopItemSnippet
        item={item as ParcelShopItemType}
        {...otherProps}
      />
    );

  if (error)
    return (
      <Typography type={TypographyType.BodySmall}>
        {otherProps.mode === 'pickup' ? (
          <ParcelShopItemErrorBlock>
            <Trans id="delivery.parcelShopLoading.error.pickup">
              Podací místo
            </Trans>
            <br />
            {getErrorMsg(error)}
            {otherProps?.onEditClick && (
              <ButtonStyled
                type="button"
                buttonType={ButtonType.PRIMARY}
                onClick={() => otherProps?.onEditClick()}
              >
                <Trans id="common.button.change">Změnit</Trans>
              </ButtonStyled>
            )}
          </ParcelShopItemErrorBlock>
        ) : (
          <ParcelShopItemErrorBlock>
            <Trans id="delivery.parcelShopLoading.error.delivery">
              Výdejní místo
            </Trans>
            <br />
            {getErrorMsg(error)}
            {otherProps?.onEditClick && (
              <ButtonStyled
                type="button"
                buttonType={ButtonType.PRIMARY}
                onClick={() => otherProps?.onEditClick()}
              >
                <Trans id="common.button.change">Změnit</Trans>
              </ButtonStyled>
            )}
          </ParcelShopItemErrorBlock>
        )}
      </Typography>
    );

  return loading ? (
    <Loading height="60px" />
  ) : (
    <ParcelShopItemSnippet item={data} {...otherProps} />
  );
};

type ParcelShopItemSnippetProps = Omit<ParcelShopItemProps, 'item'> & {
  item: ParcelShopItemType;
};

const stopPropagation = (e: React.MouseEvent) => {
  e?.stopPropagation();
  e?.nativeEvent?.stopImmediatePropagation();
};

const ParcelShopItemSnippet: FC<ParcelShopItemSnippetProps> = ({
  onEditClick,
  onClick,
  item,
  selected = false,
  hideOpeningStatus = false,
  hideIcon = false,
  mini = false,
  distance,
  mode: type,
}) => {
  const { language } = useLanguage();
  if (!item) return null;
  const address = `${item.location?.address?.street}`;
  const disabled = !item.enabled && !hasFeature('allowDisabledShops');
  const textColor = disabled ? colors.gray_badge : colors.black;
  const linkClassName =
    type === 'pickup'
      ? 'gaButton gaButtonAction_sendingPlace'
      : 'gaButton gaButtonAction_deliveryPlace';
  const buttonClassName =
    type === 'pickup'
      ? 'gaButton gaButtonAction_sendingPlace'
      : 'gaButton gaButtonAction_deliveryPlace';

  return (
    <>
      <ParcelShopListItem
        onClick={
          mini || disabled
            ? undefined
            : (e) => {
                stopPropagation(e);
                onClick?.(item, type);
              }
        }
        selected={selected}
        mini={mini}
      >
        <ItemContentMain>
          <ItemRow>
            {!mini && !hideIcon && (
              <>
                <PickupDeliveryIconStyled
                  type={
                    item?.type === 'parcelShop'
                      ? PickupType.PARCEL_SHOP
                      : PickupType.LOCKER
                  }
                />
              </>
            )}
            <NameWrapper className={linkClassName}>
              <ConditionalWrapper
                condition={!!onClick && mini}
                wrapper={(children) => (
                  <LinkStyled
                    onClick={(e) => {
                      stopPropagation(e);
                      onClick(item, type);
                    }}
                    className={linkClassName}
                  >
                    {children}
                  </LinkStyled>
                )}
              >
                <NameStyled
                  type={TypographyType.BodySmallLight}
                  color={disabled && !mini && colors.gray_badge}
                  className={linkClassName}
                >
                  {item?.contactInfo?.name}
                </NameStyled>
              </ConditionalWrapper>
            </NameWrapper>

            {distance && (
              <Typography
                type={TypographyType.BodyMicro}
                color={disabled ? colors.gray_superlight : colors.gray_warm}
              >
                {distance}
              </Typography>
            )}

            {selected && onEditClick && (
              <EditButton
                type="button"
                buttonType={ButtonType.TEXT}
                onClick={(e) => {
                  stopPropagation(e);
                  onEditClick?.();
                }}
                className={buttonClassName}
              >
                <Trans id="common.button.change">Změnit</Trans>
              </EditButton>
            )}
          </ItemRow>
          <ItemRow>
            {!mini && (
              <Typography
                type={TypographyType.BodyMicroLight}
                color={textColor}
              >
                {address}
                <br />
                {item?.location?.address?.city} {item?.location?.address?.zip}
              </Typography>
            )}

            {!hideOpeningStatus && (
              <ParcelShopOpeningState
                item={item}
                type={type}
                language={language}
              />
            )}
          </ItemRow>
        </ItemContentMain>
        {!selected && <Arrow height={20} />}
      </ParcelShopListItem>
    </>
  );
};
