import {
  ArrowIcon,
  Axes3DIcon,
  BoxIcon2,
  Button,
  ButtonType,
  CalculatorIcon,
  H2,
  Hr,
  InfoIcon2,
  NoteButton,
  NoteItem,
  QuestionIcon,
  Strong,
  Typography,
  TypographyType,
} from '@hp/atomic';
import { Money, WideNoteOnMobile } from '@hp/components';
import { useConfig } from '@hp/config';
import { getSpecificCfg, PickupType } from '@hp/core/shared';
import { FormBackButton, FormWrapper } from '@hp/form';
import { CenteredRow, MediaQueryJS, SpaceBetweenRow } from '@hp/layout';
import { colors } from '@hp/theme';
import { hasFeature, useObjectLocalStorage } from '@hp/utils';
import { Trans } from '@lingui/macro';
import { FieldArray, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';

import { sharedKeys } from '../../sharedKeys';
import { HowToSendFormFields, ParcelFormProps } from '../../types';
import { computePrice } from '../../utils';
import { MaxSizeCalculatorModal } from '../maxSizeCalculator';
import { ParcelWeightFormContent } from './ParcelWeightFormContent';
import { ChangePickupLink } from './styled';
import { useParcel } from './useParcel';

const formId = 'parcelForm';

export const Parcel = () => {
  const [calculatorVisible, setCalculatorVisible] = useState(false);
  const [howToSend] = useObjectLocalStorage<HowToSendFormFields>('howToSend');
  const [touched, setTouched] = useState(false);
  const { config } = useConfig();
  const { constraints } = config;
  const specList = getSpecificCfg(
    config.parcelSpecifications,
    howToSend.sendToCountry,
  );

  const {
    initialValues,
    saveFormData,
    handleSubmit,
    validationSchema,
    addPart,
    removePart,
    correctPart,
  } = useParcel(howToSend.pickupType, howToSend.sendToCountry);

  const formik = useFormik<ParcelFormProps>({
    initialValues,
    enableReinitialize: false,
    onSubmit: handleSubmit,
    validationSchema,
  });

  const hasMoreItems = formik.values.items.length > 1;
  const maxItemCount =
    formik.values.items.length >= constraints.maxItemCountInOrder;

  useEffect(() => {
    saveFormData(formik.values);
  }, [formik.values]);

  return (
    <>
      <FormWrapper formik={formik} flex marginBottomSize="xl">
        <FieldArray
          name="items"
          render={(arrayHelpers) => (
            <>
              {formik.values.items.map((item, index) => (
                <ParcelWeightFormContent<ParcelFormProps>
                  key={index}
                  formId={formId}
                  part={index}
                  item={item}
                  name={(values) => values.items[index]}
                  removePart={
                    hasMoreItems &&
                    (() => {
                      setTouched(true);
                      removePart(arrayHelpers, index);
                    })
                  }
                  isMuplipleMode={hasMoreItems || touched}
                  onItemChanged={(item) => {
                    correctPart(arrayHelpers, index, item, specList);
                    //quickfix: we need revalidate form
                    setTimeout(() => formik.validateForm(), 500);
                  }}
                  pickupType={howToSend.pickupType}
                  sendFromCountry={howToSend.sendFromCountry}
                  sendToCountry={howToSend.sendToCountry}
                  disabled={formik.isSubmitting}
                />
              ))}

              {hasFeature('multiPackage') &&
                !maxItemCount &&
                ((hasFeature('v3') && hasMoreItems) ||
                  !hasFeature('v3') ||
                  touched) && (
                  <CenteredRow marginSize="l" noBottomMargin>
                    <Button
                      buttonType={ButtonType.SECONDARY}
                      onClick={() => addPart(arrayHelpers)}
                      type="button"
                      data-test="parcel.button.addPackage"
                      disabled={formik.isSubmitting}
                    >
                      {hasMoreItems || hasFeature('v3') ? (
                        <Trans id="order.parcel.addPart">Přidat balík</Trans>
                      ) : (
                        <Trans id="order.parcel.sendMoreItems">
                          Rozdělit do několika balíků
                        </Trans>
                      )}
                    </Button>
                  </CenteredRow>
                )}
            </>
          )}
        />
        {!hasFeature('v3') && (
          <WideNoteOnMobile>
            <H2>
              <Trans id="common.dimensions">Rozměry</Trans>
            </H2>

            <NoteItem icon={<Axes3DIcon />}>
              {howToSend.pickupType !== PickupType.COURIER ? (
                <Trans id="order.parcel.note1.text.parcelShop">
                  max. délka jednoho balíku:{' '}
                  <Strong>{constraints.maxLengthParcelShop} cm</Strong>,
                  <br /> max. obvodová délka jednoho balíku: &nbsp;
                  <Strong>
                    {constraints.maxCircumferenceParcelShop} cm
                  </Strong>{' '}
                  (= 2x šířka + 2x výška + délka)
                </Trans>
              ) : (
                <Trans id="order.parcel.note1.text.courier">
                  max. délka jednoho balíku:{' '}
                  <Strong>{constraints.maxLength} cm</Strong>,
                  <br /> max. obvodová délka jednoho balíku: &nbsp;
                  <Strong>{constraints.maxCircumference} cm</Strong> (= 2x šířka
                  + 2x výška + délka)
                </Trans>
              )}
            </NoteItem>

            <NoteButton
              className="gaButton gaButtonAction_parcelSize"
              icon={<CalculatorIcon />}
              iconWidth="12"
              onClick={() => setCalculatorVisible(!calculatorVisible)}
              data-test="parcel.button.calculateDimensions"
            >
              <Trans id="order.parcel.maxSizeCalculator.isParcelDimensionsValid">
                Vejde se můj balík?
              </Trans>
            </NoteButton>

            {howToSend.pickupType !== PickupType.COURIER && (
              <NoteItem icon={<div />}>
                <Trans id="order.parcel.note1.sendBiggerParcel.text">
                  Pokud si přejete poslat větší balík,
                </Trans>
                <br />
                <ChangePickupLink to="accountHomepageSend">
                  <Trans id="order.parcel.note1.sendBiggerParcel.link">
                    změntě způsob předání
                  </Trans>
                </ChangePickupLink>
                .
              </NoteItem>
            )}
          </WideNoteOnMobile>
        )}
        {hasFeature('v3') && (
          <>
            <WideNoteOnMobile>
              <NoteItem icon={<InfoIcon2 />}>
                <Trans id="order.parcel.xl.note1">
                  Balík XL může vážit maximálně 31,5 kg a pro jeho přepravu není
                  možné využít pobočky DPD Pickup a samoobslužné boxy. Pokud si
                  nejste jistí, zadejte raději o něco vyšší hmotnost, aby se
                  zohlednil také obalový materiál.
                </Trans>
              </NoteItem>
            </WideNoteOnMobile>
            <WideNoteOnMobile>
              <H2>
                <Trans id="order.parcel.xl.note2.title">
                  Maximální rozměry
                </Trans>
              </H2>

              <CenteredRow>
                <MediaQueryJS>
                  {(isMobileScreen) => (
                    <>
                      {' '}
                      <div>
                        <div>
                          <Typography
                            type={TypographyType.BodyMicroLight}
                            textAlign="right"
                            color={colors.black}
                          >
                            <Trans id="common.circumference">
                              Obvodová&nbsp;délka :
                            </Trans>
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            type={TypographyType.BodyMicro}
                            textAlign="right"
                            color={colors.red_main}
                          >
                            {specList.XL.dimensions?.[1] ??
                              constraints.maxCircumference}
                          </Typography>
                          &nbsp;
                          <Typography
                            type={TypographyType.BodyMicroLight}
                            textAlign="right"
                            color={colors.black}
                          >
                            cm
                          </Typography>
                        </div>
                      </div>
                      <BoxIcon2 size="XL" zoom={isMobileScreen ? 1.2 : 2}>
                        <Trans id="order.parcel.xl.note2.pictureText">
                          <Typography
                            type={TypographyType.BodyMicroLight}
                            textAlign="center"
                            color={colors.black}
                          >
                            délka:
                          </Typography>
                          <Typography
                            type={TypographyType.BodyMicro}
                            textAlign="center"
                            color={colors.red_main}
                          >
                            <Strong>
                              {specList.XL.dimensions?.[0] ??
                                constraints.maxLength}
                            </Strong>
                          </Typography>
                          <Typography
                            type={TypographyType.BodyMicroLight}
                            textAlign="center"
                            color={colors.black}
                          >
                            &nbsp; cm
                          </Typography>
                        </Trans>
                      </BoxIcon2>
                    </>
                  )}
                </MediaQueryJS>
              </CenteredRow>

              <NoteItem icon={<InfoIcon2 />}>
                <Trans id="order.parcel.xl.note2.text">
                  Obvodová délka se vypočítá jako: <br />
                  <Strong>
                    1x délka (nejdelší strana) + 2x šířka + 2x výška.
                  </Strong>
                </Trans>
              </NoteItem>

              <NoteItem icon={<QuestionIcon />}>
                <NoteButton
                  className="gaButton gaButtonAction_parcelSize"
                  onClick={() => setCalculatorVisible(!calculatorVisible)}
                  data-test="parcel.button.calculateDimensions"
                  padding="0"
                >
                  <Trans id="order.parcel.maxSizeCalculator.isParcelDimensionsValid">
                    Vejde se můj balík?
                  </Trans>
                </NoteButton>
              </NoteItem>

              {howToSend.pickupType !== PickupType.COURIER && (
                <NoteItem icon={<div />}>
                  <Trans id="order.parcel.note1.sendBiggerParcel.text">
                    Pokud si přejete poslat větší balík,
                  </Trans>
                  <br />
                  <ChangePickupLink to="accountHomepageSend">
                    <Trans id="order.parcel.note1.sendBiggerParcel.link">
                      změntě způsob předání
                    </Trans>
                  </ChangePickupLink>
                  .
                </NoteItem>
              )}
            </WideNoteOnMobile>
          </>
        )}
        <Hr />

        <SpaceBetweenRow>
          <div>{sharedKeys.price}</div>
          <Money currency="CZK">
            {
              computePrice(
                {
                  howToSend,
                  parcel: formik.values,
                },
                config,
              ).total
            }
          </Money>
        </SpaceBetweenRow>

        <SpaceBetweenRow marginSize="m" noBottomMargin>
          <FormBackButton
            to="landing"
            type="button"
            className="gaButton gaButtonBack_parcel"
            buttonType={ButtonType.TEXT}
            data-test="parcel.button.back"
          >
            <Trans id="common.button.back">Zpět</Trans>
          </FormBackButton>

          <Button
            type="submit"
            className="gaButton gaButtonSubmit_parcel"
            buttonType={ButtonType.PRIMARY}
            icon={
              <ArrowIcon
                color={!formik.isValid ? colors.gray_warm : colors.white}
              />
            }
            animate={formik.isValid}
            bottom
            fakeDisabled={!formik.isValid}
            data-test="parcel.button.submit"
          >
            <Trans id="common.button.continue">Pokračovat</Trans>
          </Button>
        </SpaceBetweenRow>
      </FormWrapper>

      <MaxSizeCalculatorModal
        onCloseRequest={() => setCalculatorVisible(false)}
        opened={calculatorVisible}
      />
    </>
  );
};
