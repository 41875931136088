import {
  Button,
  ChevronNavBigIcon,
  ChevronNavIcon,
  Link,
  Typography,
} from '@hp/atomic';
import { PickupDeliveryIcon } from '@hp/components';
import { breakpoints, colors, misc, spacing } from '@hp/theme';
import styled, { css } from 'styled-components';

export const SearchBoxRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media (min-width: ${breakpoints.minDesktop}) {
    flex-direction: row;
  }
`;

export const SearchBox = styled.div<{ withShadow?: boolean }>`
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-height: 200px;
  padding: ${spacing.s};
  border: 1px solid ${colors.gray_light};
  border-radius: 5px;
  margin-bottom: 16px;
  position: relative;
  ${({ withShadow }) =>
    withShadow
      ? css`
          box-shadow: ${misc.shadow_form};
        `
      : ''};

  @media (min-width: ${breakpoints.minDesktop}) {
    width: 288px;
    margin-bottom: 0;
  }
`;

export const SearchFieldRefWrapper = styled.div`
  width: 100%;
`;

export const ParcelShopListWrapper = styled.div<{ selected?: boolean }>`
  ${({ selected }) =>
    selected
      ? css`
          border-top: 1px solid ${colors.gray_light};
          margin-top: 8px;
        `
      : css`
          position: absolute;
          left: -1px;
          right: -1px;
          bottom: 58px;
          transform: translateY(100%);
          border: 1px solid ${colors.gray_light};
          border-top: none;
          z-index: 40;
          background-color: ${colors.white};
          box-shadow: ${misc.shadow_form};
          border-radius: 0 0 5px 5px;
        `};
`;

export const FiltersWrapper = styled.div`
  padding: ${spacing.s} ${spacing.xs};
  border-bottom: 1px solid ${colors.gray_superlight};
`;

export const ParcelShopListContainer = styled.div<{ empty?: boolean }>`
  ${({ empty }) =>
    empty
      ? css`
          height: auto;
        `
      : css`
          max-height: 260px;
          @media (min-width: ${breakpoints.minDesktop}) {
            max-height: 400px;
          }
        `};

  width: 100%;
  overflow-y: scroll;
`;

export const ParcelShopListStyled = styled.ul`
  width: 100%;
  padding: 0;
  margin: 0;

  /* width */
  ::-webkit-scrollbar {
    width: 9px;
    height: 250px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${colors.gray_warm};
    border-radius: 10px;
  }
`;

type ParcelShopListItemProps = {
  selected?: boolean;
  mini?: boolean;
};

export const ParcelShopListItem = styled.li<ParcelShopListItemProps>`
  cursor: ${({ selected }) => !selected && 'pointer'};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style: none;
  width: 100%;
  margin: 0;
  padding: ${({ mini }) => (mini ? spacing.zero : spacing.s)};
`;

export const ItemRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ItemContentMain = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

export const NameWrapper = styled.div`
  flex-grow: 1;
`;

export const NameStyled = styled(Typography)`
  font-weight: 400;
  /* thanks to inlin-block base line-height works  */
  display: inline-block;
`;

export const ItemContentLeft = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const ItemContentRight = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: ${spacing.xs} 0;
`;

export const PickupDeliveryIconStyled = styled(PickupDeliveryIcon)`
  flex-shrink: 0;
  margin: 0 ${spacing.s} ${spacing.s} 0;
`;

export const Arrow = styled(ChevronNavBigIcon)`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin: auto 0 auto ${spacing.xs};
`;

export const NoItemsContainer = styled.div`
  width: 100%;
  padding: ${spacing.s};
`;

export const OpeningHoursContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const OpeningHoursRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const OpeningHoursItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 90px;
`;

export const PhoneNumber = styled.a`
  color: ${colors.black};
  text-decoration: none;
`;

export const ModalContentWrapper = styled.div`
  padding: ${spacing.m};
`;

export const ChevronNavIconStyled = styled(ChevronNavIcon)`
  transform: rotate(180deg);
`;

export const EditButton = styled(Button)`
  margin: ${spacing.xs} 0 auto ${spacing.m};
  justify-content: flex-end;
`;

export const LinkStyled = styled(Link)`
  color: ${colors.red_dark};
  text-decoration: none;

  :hover {
    color: ${colors.red_dark};
    text-decoration: underline;
  }
`;

export const IconWrapper = styled.div`
  margin-right: ${spacing.m};
`;

export const DescriptionWrapper = styled.div`
  margin-left: 36px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.minDesktop}) {
    flex-direction: row;
  }
`;

export const DetailWrapper = styled.div`
  flex-shrink: 0;

  @media (min-width: ${breakpoints.minDesktop}) {
    width: 340px;
  }
`;

export const MapWrapper = styled.div`
  margin-top: ${spacing.m};

  @media (min-width: ${breakpoints.minDesktop}) {
    width: 100%;
    min-width: 250px;
    margin-top: 0;
    margin-left: ${spacing.m};
  }
`;

export const Photo = styled.img`
  height: 120px;
  width: auto;
  margin-right: ${spacing.m};

  @media (min-width: ${breakpoints.minDesktop}) {
    height: 200px;
  }
`;

export const ButtonStyled = styled(Button)`
  height: 20px;
  width: 120px;
  margin-top: ${spacing.xs};
  align-self: flex-end;
`;

export const ParcelShopItemErrorBlock = styled.div`
  display: flex;
  flex-direction: column;
`;
