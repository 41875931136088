import { Strong, UpperCase } from '@hp/atomic';
import { Money } from '@hp/components';
import { DynamicConfigRoot } from '@hp/core/shared';
import { Trans } from '@lingui/macro';
import React from 'react';

export const sharedKeys = {
  parcelTags: {
    fragile: (
      <Trans id="order.additionalServices.tag.fragile">
        Štítek&nbsp;
        <Strong>
          <UpperCase>křehké</UpperCase>
        </Strong>
      </Trans>
    ),
    doNotTip: (
      <Trans id="order.additionalServices.tag.doNotTip">
        Štítek&nbsp;
        <Strong>
          <UpperCase>neklopit</UpperCase>
        </Strong>
      </Trans>
    ),
  },
  insurance: (
    <Trans id="order.additionalServices.insurance">Připojištění</Trans>
  ),
  insuranceFor: (value: any) => (
    <Trans id="order.additionalServices.insuranceFor">
      Připojištění na {value}
    </Trans>
  ),
  insuranceOverMax: (config: DynamicConfigRoot) => (
    <Trans id="order.additionalServices.insuranceOverMax">
      Připojištění nad{' '}
      <Money currency="CZK">{config.constraints.maxInsurance}</Money> (cena se
      odvíji od hodnoty zboží)
    </Trans>
  ),
  insuranceBellowMax: (config: DynamicConfigRoot) => (
    <Trans id="order.additionalServices.insuranceBellowMax">
      Připojištění do{' '}
      <Money currency="CZK">{config.constraints.maxInsurance}</Money>
    </Trans>
  ),
  parcelValue: (
    <Trans id="order.additionalServices.parcelValue">Hodnota zboží</Trans>
  ),
  cashOnDelivery: (
    <Trans id="order.additionalServices.cashOnDelivery">Dobírka</Trans>
  ),
  cashOnDeliveryValue: (
    <Trans id="order.additionalServices.cashOnDeliveryValue">
      Výše dobírky
    </Trans>
  ),
  price: <Trans id="common.price">Cena</Trans>,
  finalPrice: <Trans id="common.finalPrice">Celková cena</Trans>,
};
