import { config as appConfig, Language } from '@hp/config';
import {
  AddressType,
  AtLeast,
  CaseDataInput,
  CaseQuery,
  clamp,
  computeInputPrice,
  Country,
  DynamicConfigRoot,
  ParcelPartDescriptionInput,
  PickupType,
  PlaceAddressInput,
  PlaceType,
  RecipientInput,
  SenderInput,
} from '@hp/core/shared';
import { joinPhone, splitPhone } from '@hp/form/src/atomic/phoneInput/utils';
import { UtmParams } from '@hp/seo';

import { emptyValues as initialAdditionalServiceValues } from '../components/AdditionalServices/useAdditionalServicesForm';
import { initialValues as initalParcelValues } from '../components/Parcel/useParcel';
import {
  AdditionalServicesFormFields,
  AllFormsData,
  HowToSendFormFields,
  ParcelFormProps,
  ParcelPartDescription,
  ParcelShopItemType,
  PaymentOptionType,
  PickupFormType,
  RecipientFormFields,
  SenderFormFields,
} from '../types';

const getNames = (
  subject: AddressType,
  { name, contactPerson }: { name: string; contactPerson?: string },
) => {
  return {
    name: subject === AddressType.PERSON ? name : contactPerson || undefined,
    companyName: subject === AddressType.COMPANY ? name : undefined,
  };
};

const convertRecipient = (
  {
    recipient: type,
    name,
    city,
    note,
    email,
    phone: fullPhone,
    postalCode,
    street,
    contactPerson,
    recipient: subject,
  }: RecipientFormFields,
  country: Country,
) => {
  const [phonePrefix, phone] = splitPhone(fullPhone);
  const recipient: RecipientInput = {
    phone,
    phonePrefix,
    postalCode,
    street,
    city,
    email,
    country,
    note,
    ...getNames(subject, { name, contactPerson }),
    type,
  };
  return recipient;
};

const convertSender = (
  {
    sender: type,
    city,
    email,
    ico,
    dic,
    name,
    phone: fullPhone,
    postalCode,
    street,
    invoiceToAnotherAddress,
    icoInvoice,
    dicInvoice,
    cityInvoice,
    nameInvoice,
    postalCodeInvoice,
    note,
    streetInvoice,
  }: SenderFormFields,
  country: Country,
) => {
  const [phonePrefix, phone] = splitPhone(fullPhone);

  const sender: SenderInput = {
    type,
    phonePrefix,
    phone,
    postalCode,
    street,
    city,
    email,
    ico,
    dic,
    country,
    note,
    name,
    ...(invoiceToAnotherAddress
      ? {
          dicInvoice,
          icoInvoice,
          cityInvoice,
          streetInvoice,
          postalCodeInvoice,
          nameInvoice,
          countryInvoice: country /* todo: add country select for invoice to sender form */,
          typeInvoice: AddressType.COMPANY, // every invoice address is now COMPANY type
        }
      : {}),
  };

  return sender;
};
export const computePrice = (
  data: AtLeast<AllFormsData, 'howToSend'>,
  config: DynamicConfigRoot,
) => {
  const inputWithoutPrice = createCaseDataWithoutPriceAndLanguageAndDestAddress(
    config,
    data,
  );
  const price = computeInputPrice(inputWithoutPrice, config);
  return price;
};

export const createCaseData = (
  data: AtLeast<AllFormsData, 'howToSend'>,
  config: DynamicConfigRoot,
  language?: Language,
  utmParams?: UtmParams,
  destAddressShopData?: ParcelShopItemType,
) => {
  const inputWithoutPrice = createCaseDataWithoutPriceAndLanguageAndDestAddress(
    config,
    data,
    utmParams,
  );

  const { howToSend } = data;

  let destAdrress: PlaceAddressInput;
  if (data.howToSend.deliveryType !== PickupType.COURIER) {
    {
      if (!destAddressShopData)
        throw new Error('Unable to load parcel shop data');
      const { location, id, contactInfo, type } = destAddressShopData;
      if (id !== data.howToSend.deliveryId)
        //never should occur, just defensive
        throw new Error(
          `Unable to load parcel shop data, invalid id. Expected ${data.howToSend.deliveryId}, but downloaded was ${id} `,
        );
      const { city, country, street, zip: postalCode } =
        location?.address ?? {};
      const { name } = contactInfo ?? {};
      destAdrress = {
        city,
        name: (name || `${street}, ${city}, ${postalCode}`).substring(0, 34),
        postalCode,
        street,
        country,
        type: type === 'locker' ? PlaceType.LOCKER : PlaceType.PARCEL_SHOP,
      };
    }
  }

  const input: CaseDataInput = {
    price: computeInputPrice(inputWithoutPrice, config),
    ...inputWithoutPrice,
    language: language ?? appConfig.app.defaultLanguage,
    destAddress:
      howToSend?.deliveryType !== PickupType.COURIER
        ? {
            ...destAdrress,
            id: howToSend.deliveryId,
          }
        : undefined,
  };

  return input;
};

const emptyAdress = {
  city: '',
  email: '',
  name: '',
  phone: '',
  postalCode: '',
  street: '',
};

const normalizeInsuranceValue = (
  {
    value,
    on,
  }: {
    value: number | null | undefined;
    on: boolean;
  },
  config: DynamicConfigRoot,
) => {
  return on
    ? clamp(
        value || config.constraints.maxInsurance,
        config.constraints.maxInsurance + 1,
        config.constraints.maxOptionalInsurance,
      )
    : undefined;
};

const joinInsuranceValues = (
  items: ParcelPartDescription[],
  parcelValues: Array<{ value: number; on: boolean }> | null,
  config: DynamicConfigRoot,
): ParcelPartDescriptionInput[] => {
  return items.map((item, index) => ({
    ...item,
    parcelValue: parcelValues
      ? normalizeInsuranceValue(parcelValues?.[index], config)
      : undefined,
  }));
};

/** creates all data except price, destAddress is present only like flag (without data) */
const createCaseDataWithoutPriceAndLanguageAndDestAddress = (
  config: DynamicConfigRoot,
  {
    howToSend,
    parcel = initalParcelValues,
    recipient = { ...emptyAdress, recipient: AddressType.PERSON },
    sender = {
      ...emptyAdress,
      sender: AddressType.PERSON,
      pickup: howToSend.pickupType,
      termsAgreement: false,
      newsletterAgreement: true,
    },
    additionalServices = initialAdditionalServiceValues(config),
  }: AtLeast<AllFormsData, 'howToSend'>,
  utmParams?: UtmParams,
) => {
  const { items } = parcel;

  const {
    doNotTip,
    fragile,
    cashOnDelivery,
    cashOnDeliveryValue,
    parcelValues,
    accountNumber,
    bankCode,
    variableSymbol,
    insurance,
    swift,
    iban,
  } = additionalServices;

  const recipientInput = convertRecipient(recipient, howToSend.sendToCountry);

  const senderInput = convertSender(sender, howToSend.sendFromCountry);

  const result: Omit<CaseDataInput, 'price' | 'language'> = {
    doNotTip,
    fragile,
    items: joinInsuranceValues(items, insurance ? parcelValues : null, config),
    sendHow: howToSend.pickupType,
    cashOnDelivery: cashOnDelivery
      ? {
          ...(swift
            ? {
                account: {
                  swift,
                  iban,
                },
              }
            : {
                account: {
                  number: accountNumber,
                  bankCode,
                },
              }),
          variableSymbol: variableSymbol || '',
          cashOnDeliveryValue,
        }
      : undefined,
    sender: senderInput,
    recipient: recipientInput,
    sendWhen:
      howToSend.pickupType === PickupType.COURIER
        ? howToSend.sendWhen
        : undefined,
    utm: utmParams?.utmData,
    srcPudoId:
      howToSend.pickupType !== PickupType.COURIER
        ? howToSend.pickupId
        : undefined,
    destAddress:
      howToSend.deliveryType !== PickupType.COURIER
        ? { id: '1' /* any value, flag for computing price */ }
        : undefined,
  };

  return result;
};

const readSender = (
  senderData: CaseQuery['case']['sender'],
  pickup: PickupType,
  sendWhen: string,
) => {
  const {
    city,
    streetInvoice,
    email,
    name,
    nameInvoice,
    phonePrefix,
    phone,
    postalCode,
    postalCodeInvoice,
    street,
    type,
    cityInvoice,
    dic,
    ico,
    dicInvoice,
    icoInvoice,
    note,
  } = senderData;

  const sender: SenderFormFields = {
    city: norm(city),
    email: norm(email),
    name: norm(name),
    newsletterAgreement: true,
    phone: joinPhone(phonePrefix, phone),
    pickup,
    postalCode: norm(postalCode),
    street: norm(street),
    termsAgreement: true,
    cityInvoice: norm(cityInvoice),
    dic: norm(dic),
    dicInvoice: norm(dicInvoice),
    ico: norm(ico),
    icoInvoice: norm(icoInvoice),
    note: norm(note),
    nameInvoice: norm(nameInvoice),
    postalCodeInvoice: norm(postalCodeInvoice),
    invoiceToAnotherAddress: !!nameInvoice,
    sender: type,
    sendWhen,
    streetInvoice: norm(streetInvoice),
  };

  return sender;
};

const readRecipient = (recipientData: CaseQuery['case']['recipient']) => {
  const {
    city,
    email,
    name,
    phonePrefix,
    phone,
    postalCode,
    street,
    type,
    note,
    companyName,
  } = recipientData;

  const recipient: RecipientFormFields = {
    city: norm(city),
    email: norm(email),
    phone: joinPhone(phonePrefix, phone),
    postalCode: norm(postalCode),
    street: norm(street),
    note: norm(note),
    recipient: type,
    contactPerson: type === AddressType.PERSON ? undefined : name,
    name: type === AddressType.PERSON ? name : companyName,
  };

  return recipient;
};

const norm = <T>(value: T, defaultValue: any = '') =>
  value ?? (defaultValue as T);

export const readCaseData = (
  data: CaseQuery['case'],
  config: DynamicConfigRoot,
): AllFormsData => {
  const {
    sender: senderData,
    items,
    recipient: recipientData,
    sendHow,
    sendWhen,
    cashOnDelivery,
    fragile,
    doNotTip,
    destAddress,
    srcPudoId,
  } = data;

  const howToSend: HowToSendFormFields = {
    sendFromCountry: senderData.country,
    sendToCountry: recipientData.country,
    pickupFormType:
      sendHow === PickupType.COURIER
        ? PickupFormType.COURIER
        : PickupFormType.PARCELSHOP_OR_BOX,
    pickupType: sendHow,
    pickupId: sendHow === PickupType.COURIER ? undefined : srcPudoId,
    sendWhen: sendHow === PickupType.COURIER ? sendWhen : '',
    selectedParcelShopAddress: '',
    selectedParcelShopCodAllowed: '',
    selectedParcelShopName: '',
    deliveryType: destAddress?.id
      ? destAddress.type === PlaceType.LOCKER
        ? PickupType.LOCKER
        : PickupType.PARCEL_SHOP
      : PickupType.COURIER,
    deliveryFormType: destAddress?.id
      ? PickupFormType.PARCELSHOP_OR_BOX
      : PickupFormType.COURIER,
    deliveryId: destAddress?.id,
  };

  const parcel: ParcelFormProps = {
    items: items.map(({ size, weight }) => {
      return {
        size,
        weight,
      };
    }),
  };

  const sender: SenderFormFields = readSender(
    senderData,
    sendHow,
    howToSend.sendWhen,
  );
  const recipient: RecipientFormFields = readRecipient(recipientData);

  let insurance = false;
  const parcelValues = items.map(({ parcelValue: value }) => {
    const parcelValue = { on: value > config.constraints.maxInsurance, value };
    if (parcelValue.on) insurance = true;
    return parcelValue;
  });

  const additionalServices: AdditionalServicesFormFields = {
    doNotTip: !!doNotTip,
    fragile: !!fragile,
    cashOnDelivery: !!cashOnDelivery,
    cashOnDeliveryValue: cashOnDelivery?.cashOnDeliveryValue,
    accountNumber: cashOnDelivery?.account?.number ?? '',
    bankCode: cashOnDelivery ? cashOnDelivery.account.bankCode : '',
    iban: cashOnDelivery ? cashOnDelivery.account.iban : '',
    swift: cashOnDelivery ? cashOnDelivery.account.swift : '',
    addPaymentDetails: !!cashOnDelivery?.variableSymbol,
    variableSymbol: cashOnDelivery?.variableSymbol ?? '',
    parcelValues,
    insurance,
    paymentOption: PaymentOptionType.CARD_FORWARD,
  };

  return {
    howToSend,
    parcel,
    additionalServices,
    sender,
    recipient,
  };
};
