import {
  Button,
  ButtonType,
  ConfirmIcon,
  Loading,
  Nowrap,
  Strong,
  Typography,
  TypographyType,
  UpperCase,
} from '@hp/atomic';
import { Money, ParcelDescription } from '@hp/components';
import { config as staticConfig, useConfig } from '@hp/config';
import {
  AddressType,
  Country,
  getSpecificCfg,
  Language,
  ParcelSpecs,
  PayUStatus,
} from '@hp/core/shared';
import { Currency } from '@hp/core/shared/constants';
import { useLanguage } from '@hp/core/src/providers/LanguageProvider';
import { FormBackButton } from '@hp/form';
import { AlignRightRow, Row, SpaceBetweenRow } from '@hp/layout';
import { fields } from '@hp/locale';
import {
  QueryParamModeValue,
  QueryParams,
  RoutesType,
  useRouter,
} from '@hp/seo';
import { colors } from '@hp/theme';
import { addDataLayerItem, formatNumber, hasFeature } from '@hp/utils';
import { Trans } from '@lingui/macro';
import React, { FC, Fragment, useEffect } from 'react';

import { ErrorInAllOrder, usePayment } from '../../hooks';
import { sharedKeys } from '../../sharedKeys';
import { AddressSummary } from './AddressSummary';
import { PickupAndDelivery } from './PickupAndDelivery';
import { HrStyled, SummarySectionWrapper } from './styled';
import { SummaryModal } from './SummaryModal';
import { SummarySection } from './SummarySection';
import { useSummary } from './useSummary';

const Free = () => (
  <UpperCase>
    <Trans id="common.priceFree">Zdarma</Trans>
  </UpperCase>
);

//deprecated: use rather ParcelDescription component
const getWeightSuffix = (
  specification: ParcelSpecs,
  weight: number | null | undefined,
  language: Language,
) => {
  if (!specification.ranges?.length) return '';
  const [min, max] = specification.ranges.find(
    (r) => r[0] <= weight && weight <= r[1],
  );
  return `- ${formatNumber(min, language)}-${formatNumber(max, language)} kg`;
};

const getCountrySuffix = (countryFrom: Country, countryTo: Country) => {
  return countryFrom == countryTo ? '' : ` (${countryFrom} → ${countryTo})`;
};

type Props = {
  [QueryParams.id]?: string;
  [QueryParams.mode]?: QueryParamModeValue;
  paymentError?: boolean;
};

export const Summary: FC<Props> = ({ id, mode, paymentError }) => {
  const resend = mode === QueryParamModeValue.resend;
  const viewOnly =
    mode === QueryParamModeValue.view || mode === QueryParamModeValue.thankYou;

  const router = useRouter();
  const { config } = useConfig();
  const { language } = useLanguage();

  const {
    confirm,
    parcel,
    recipient,
    sender,
    howToSend,
    additionalServices,
    sending,
    creatingPayment,
    loading,
    error,
    caseId,
    fillStorage,
    errors,
    isValid,
    price,
    promptData,
    setPrompData,
    shopData,
  } = useSummary(id, mode, paymentError);

  const { status } = usePayment({
    caseId: !creatingPayment ? caseId : null,
    bypassWaiting: mode === QueryParamModeValue.view,
    onComplete:
      mode === QueryParamModeValue.thankYou
        ? () => router.push('thankYou', null, id)
        : null,
    tryAgain: confirm,
  });

  useEffect(() => {
    // push order_summary when entering page from order (mode is undefined) or resend
    if (mode === undefined || mode === QueryParamModeValue.resend) {
      addDataLayerItem({ event: 'order_summary' });
    }
  }, []);

  if (loading) return <Loading />;

  if (error) {
    router.push('error404');

    return null;
  }

  const paid = status === PayUStatus.COMPLETED;
  const specList = getSpecificCfg(
    config.parcelSpecifications,
    howToSend.sendToCountry,
  );

  const getSectionProps = (
    route: keyof RoutesType,
    errorOrder: ErrorInAllOrder,
    anchor?: string,
  ) => {
    const canChange =
      !!route &&
      (!paid || resend) &&
      !viewOnly &&
      (isValid ||
        (!isValid &&
          (errorOrder === ErrorInAllOrder.before ||
            errorOrder === ErrorInAllOrder.myself)));
    return canChange
      ? {
          route,
          onClick: () => {
            if (resend) fillStorage();
            if (!anchor) router.push(route);
            else router.nextRouter.push(`${route}#${anchor}`);
          },
          error: errorOrder === ErrorInAllOrder.myself,
        }
      : {};
  };

  const effectiveInsuranceParcelValues = additionalServices.insurance
    ? additionalServices.parcelValues
        .map((v, index) => ({ index, ...v, item: parcel.items[index] }))
        .filter((x) => x.on)
    : null;

  return (
    <>
      <SummarySectionWrapper>
        <SummarySection
          buttonClassName="gaButton gaButtonChange gaButtonChange_orderParcel"
          {...getSectionProps('landing', errors?.howToSend?.errorInAllOrder)}
          title={
            parcel.items.length > 1 ? (
              <Trans id="order.summary.parcelInfo.package.multi">Balíky</Trans>
            ) : (
              <>
                <UpperCase mode="capitalize">
                  <Trans id="common.parcel">balík</Trans> {parcel.items[0].size}
                </UpperCase>
                {howToSend.sendToCountry !==
                  (staticConfig.app.defaultCountry as Country) && (
                  <>
                    {' '}
                    <Trans id="common.parcel.toAbroad">do zahraničí</Trans>
                    {` (${howToSend.sendToCountry})`}
                  </>
                )}
              </>
            )
          }
          titleSuffix={
            parcel.items.length > 1 ? (
              <>
                {getCountrySuffix(
                  howToSend.sendFromCountry,
                  howToSend.sendToCountry,
                )}
              </>
            ) : (
              <>
                {getWeightSuffix(
                  specList[parcel.items[0].size],
                  parcel.items[0].weight,
                  language,
                )}
                {howToSend.sendToCountry ==
                  (staticConfig.app.defaultCountry as Country) &&
                  getCountrySuffix(
                    howToSend.sendFromCountry,
                    howToSend.sendToCountry,
                  )}
              </>
            )
          }
        >
          {parcel.items.length > 1 &&
            parcel.items.map((item, index) => {
              return (
                <div key={index}>
                  <ParcelDescription
                    index={index}
                    {...item}
                    strong
                    specsList={specList}
                  />
                </div>
              );
            })}
        </SummarySection>

        <SummarySection
          buttonClassName="gaButton gaButtonChange gaButtonChange_orderSender_0"
          {...getSectionProps(
            hasFeature('v3') ? 'orderDelivery' : 'landing',
            errors?.howToSend?.errorInAllOrder,
          )}
          title={
            <Trans id="order.delivery.title">Místo odeslání a doručení</Trans>
          }
        >
          <PickupAndDelivery howToSend={howToSend} shopData={shopData} />
        </SummarySection>

        <SummarySection
          buttonClassName="gaButton gaButtonChange gaButtonChange_orderRecipient"
          {...getSectionProps(
            'orderRecipient',
            errors?.recipient?.errorInAllOrder,
          )}
          title={<Trans id="order.summary.recipient.heading">Příjemce</Trans>}
        >
          <AddressSummary
            address={{
              ...recipient,
              country: howToSend.sendToCountry,
            }}
          />
        </SummarySection>

        <SummarySection
          buttonClassName="gaButton gaButtonChange gaButtonChange_orderSender_1"
          {...getSectionProps('orderSender', errors?.sender?.errorInAllOrder)}
          title={<Trans id="order.summary.sender.heading">Odesílatel</Trans>}
        >
          <AddressSummary
            address={{
              ...sender,
              ico: sender?.sender === AddressType.COMPANY ? sender?.ico : null,
              dic: sender?.sender === AddressType.COMPANY ? sender?.dic : null,
              country: howToSend.sendFromCountry,
            }}
          />
        </SummarySection>

        <SummarySection
          buttonClassName="gaButton gaButtonChange gaButtonChange_orderAdditionalServices"
          {...getSectionProps(
            'orderAdditionalServices',
            errors?.additionalServices?.errorInAllOrder,
          )}
          title={
            <Trans id="order.summary.additionalServices.heading">
              Doplňkové služby
            </Trans>
          }
        >
          <div>
            {additionalServices.fragile && (
              <>
                {sharedKeys.parcelTags.fragile}
                <br />
              </>
            )}

            {additionalServices.doNotTip && (
              <>
                {sharedKeys.parcelTags.doNotTip}
                <br />
              </>
            )}

            {additionalServices.cashOnDelivery && (
              <>
                <br />
                <Strong>{sharedKeys.cashOnDelivery}:</Strong>
                <br />
                <Money currency={Currency(howToSend.sendToCountry)}>
                  {additionalServices.cashOnDeliveryValue}
                </Money>
                <br />
                <Trans id="order.summary.toAccountNumber">Na účet</Trans>:{' '}
                <Nowrap withBreak>
                  {howToSend.sendToCountry === Country.CZ ? (
                    <>
                      {additionalServices.accountNumber}/
                      {additionalServices.bankCode}
                    </>
                  ) : (
                    <>
                      <br />
                      {additionalServices.iban}
                      <br />
                      {additionalServices.swift}
                    </>
                  )}
                </Nowrap>
                {additionalServices.addPaymentDetails &&
                  additionalServices.variableSymbol && (
                    <>
                      {fields.variableSymbol}:{' '}
                      {additionalServices.variableSymbol}
                      <br />
                    </>
                  )}
                <br />
              </>
            )}

            {additionalServices.insurance && (
              <>
                {parcel.items.length > 1 && (
                  <>
                    <br />
                    {effectiveInsuranceParcelValues.map(
                      ({ index, value, item }, i) => (
                        <Fragment key={index}>
                          <ParcelDescription
                            index={parcel.items.length > 1 ? index : null}
                            {...item}
                            strong
                            specsList={specList}
                          />
                          <br />
                          <div>
                            {sharedKeys.insuranceFor(
                              <Money currency="CZK">{value}</Money>,
                            )}
                          </div>
                          {i + 1 < effectiveInsuranceParcelValues.length && (
                            <br />
                          )}
                        </Fragment>
                      ),
                    )}
                  </>
                )}

                {parcel.items.length === 1 &&
                  sharedKeys.insuranceFor(
                    <Money currency="CZK">
                      {additionalServices.parcelValues[0].value}
                    </Money>,
                  )}
              </>
            )}
          </div>
        </SummarySection>

        <SummarySection
          framed={false}
          title={
            <Trans id="order.summary.priceCalculation.heading">
              Vaše objednávka
            </Trans>
          }
        >
          <Row>
            <SpaceBetweenRow>
              {parcel.items.length > 1 ? (
                <Trans id="order.summary.packagePrice">Cena zásilky</Trans>
              ) : (
                <span>
                  <UpperCase mode="capitalize">
                    <Trans id="common.parcel">balík</Trans>{' '}
                    {parcel.items[0].size}
                  </UpperCase>

                  {howToSend.sendToCountry !==
                    (staticConfig.app.defaultCountry as Country) && (
                    <>
                      {' '}
                      <Trans id="common.parcel.toAbroad">do zahraničí</Trans>
                      {` (${howToSend.sendToCountry})`}
                    </>
                  )}
                </span>
              )}

              <Money currency="CZK" block>
                {price.basePrice}
              </Money>
            </SpaceBetweenRow>
            {price.anotherCountry > 0 && (
              <SpaceBetweenRow>
                <Trans id="order.summary.anotherCountry">
                  Doručení do jiné země
                </Trans>
                <Money currency="CZK" block>
                  {price.cashOnDelivery}
                </Money>
              </SpaceBetweenRow>
            )}
            {price.courierPickup && !price.courierDelivery ? (
              <SpaceBetweenRow>
                <Trans id="order.summary.courierPickup">
                  Vyzvednutí kurýrem
                </Trans>
                <Money currency="CZK" block>
                  {price.courierPickup}
                </Money>
              </SpaceBetweenRow>
            ) : null}
            {price.courierDelivery && !price.courierPickup ? (
              <SpaceBetweenRow>
                <Trans id="order.summary.courierDelivery">
                  Doručení kurýrem
                </Trans>
                <Money currency="CZK" block>
                  {price.courierDelivery}
                </Money>
              </SpaceBetweenRow>
            ) : null}
            {price.courierDelivery && price.courierPickup ? (
              <SpaceBetweenRow>
                <Trans id="order.summary.courierPickupAndDelivery">
                  Vyzvednutí a doručení kurýrem
                </Trans>
                <Money currency="CZK" block>
                  {price.courierPickup + price.courierDelivery}
                </Money>
              </SpaceBetweenRow>
            ) : null}
            {additionalServices.fragile && (
              <SpaceBetweenRow>
                <div>{sharedKeys.parcelTags.fragile}</div>
                <Free />
              </SpaceBetweenRow>
            )}
            {additionalServices.doNotTip && (
              <SpaceBetweenRow>
                <div>{sharedKeys.parcelTags.doNotTip}</div>
                <Free />
              </SpaceBetweenRow>
            )}
            {price.cashOnDelivery > 0 && (
              <SpaceBetweenRow>
                <Trans id="order.summary.cashOnDelivery">Dobírka</Trans>
                <Money currency={'CZK'} block>
                  {price.cashOnDelivery}
                </Money>
              </SpaceBetweenRow>
            )}
            {price.extraInsurance > 0 ? (
              <SpaceBetweenRow>
                <Trans id="order.summary.extraInsurance">
                  Celková cena připojištení
                </Trans>
                <Money currency="CZK" block>
                  {price.extraInsurance}
                </Money>
              </SpaceBetweenRow>
            ) : (
              <SpaceBetweenRow>
                <div>{sharedKeys.insuranceBellowMax(config)}</div>
                <Free />
              </SpaceBetweenRow>
            )}
          </Row>

          <HrStyled />
        </SummarySection>

        <SpaceBetweenRow>
          <div>{sharedKeys.finalPrice}</div>
          <Money currency="CZK">{price.total}</Money>
        </SpaceBetweenRow>

        {(!status ||
          status === PayUStatus.CANCELED ||
          status === PayUStatus.CREATED ||
          resend ||
          (viewOnly && !paid)) && (
          <SpaceBetweenRow marginSize="m" noBottomMargin>
            <FormBackButton
              to={
                resend || viewOnly
                  ? 'accountArchiveDetail'
                  : 'orderAdditionalServices'
              }
              asHrefParam={resend || viewOnly ? id : undefined}
              type="button"
              className="gaButton gaButtonBack_summary"
              buttonType={ButtonType.TEXT}
              data-test="summary.button.back"
            >
              <Trans id="common.button.back">Zpět</Trans>
            </FormBackButton>

            <Button
              bottom
              type="button"
              className="gaButton gaButtonSubmit_summary"
              buttonType={ButtonType.PRIMARY}
              icon={
                <ConfirmIcon
                  color={!isValid ? colors.gray_warm : colors.white}
                />
              }
              onClick={() => {
                addDataLayerItem({ event: 'payment_initiated' });
                confirm();
              }}
              data-test="summary.button.submit"
              disabled={
                sending ||
                !isValid ||
                shopData?.delivery?.loading ||
                shopData?.delivery?.error ||
                shopData?.pickup?.loading ||
                shopData?.pickup?.error
              }
              animate={isValid}
            >
              <Trans id="order.summary.button.pay">Zaplatit</Trans>
            </Button>
          </SpaceBetweenRow>
        )}

        {paid && !resend && (
          <AlignRightRow>
            <Typography
              type={TypographyType.Heading2Regular}
              color={colors.gray_mid}
            >
              <Trans id="order.summary.button.alreadypayed">
                Již zaplaceno
              </Trans>
            </Typography>
          </AlignRightRow>
        )}
      </SummarySectionWrapper>

      <SummaryModal
        promptProps={promptData}
        setProps={setPrompData}
        sendToCountry={howToSend.sendToCountry}
        sendFromCountry={howToSend.sendFromCountry}
      />
    </>
  );
};
