import { Button, ButtonType, Typography, TypographyType } from '@hp/atomic';
import { Money, MoneySymbolIcon } from '@hp/components';
import { useConfig } from '@hp/config';
import { Country, getSpecificCfg } from '@hp/core/shared';
import {
  CheckboxField,
  FormWrapper,
  SelectField,
  TextField,
  useYup,
} from '@hp/form';
import { CenteredRow, Row } from '@hp/layout';
import { fields, inlineKeys, labels, useI18n } from '@hp/locale';
import { Modal } from '@hp/modal';
import { colors } from '@hp/theme';
import { pickupDateOptions } from '@hp/utils';
import { Trans } from '@lingui/macro';
import { useFormik } from 'formik';
import React, { useState } from 'react';

import { sharedKeys } from '../../sharedKeys';
import { ModalWrapper } from './styled';

type PromptProps = {
  variableSymbol?: string;
  sendWhen?: string;
  cashOnDelivery: boolean;
  cashOnDeliveryValue?: number;
};

type SummaryModalProps = {
  promptProps: PromptProps;
  sendFromCountry: Country;
  sendToCountry: Country;
  setProps: (props: PromptProps) => void;
};

export const SummaryModal: React.FC<SummaryModalProps> = ({
  sendFromCountry,
  sendToCountry,
  promptProps: {
    sendWhen,
    variableSymbol,
    cashOnDelivery,
    cashOnDeliveryValue,
  },
  setProps,
}) => {
  const i18n = useI18n();
  const pickupOptions = pickupDateOptions(sendFromCountry, i18n);
  const [closed, setClosed] = useState(!variableSymbol && !sendWhen);

  const {
    config: {
      constraints: { minCashOnDelivery, maxCashOnDelivery },
      priceList,
    },
  } = useConfig();

  const Yup = useYup();

  const currentMaxCashOnDelivery = getSpecificCfg(
    maxCashOnDelivery,
    sendToCountry,
  );
  const currentMinCashOnDelivery = getSpecificCfg(
    minCashOnDelivery,
    sendToCountry,
  );

  const validationSchema = Yup.object().shape<PromptProps>({
    variableSymbol: Yup.string()
      .max(10)
      .matches(/^(\s*|\d+)$/, i18n._(inlineKeys.variableSymbolValidation.key)),
    sendWhen: Yup.string(),
    cashOnDelivery: Yup.boolean(),
    cashOnDeliveryValue: Yup.number().when('cashOnDelivery', {
      is: (value) => value === true,
      then: Yup.number()
        .required()
        .integer()
        .test(
          'cashOnDeliveryValue',
          i18n._(inlineKeys.cashOnDeliveryValidation.key),
          (value: number) =>
            value >= currentMinCashOnDelivery &&
            value <= currentMaxCashOnDelivery,
        ),
      otherwise: Yup.number().nullable(),
    }),
  });

  const initialValues = {
    variableSymbol,
    sendWhen:
      pickupOptions.find((x) => x.value === sendWhen)?.value ??
      pickupOptions[0].value,
    cashOnDelivery,
    cashOnDeliveryValue,
  };

  const formik = useFormik<PromptProps>({
    initialValues,
    enableReinitialize: false,
    onSubmit: (values) => {
      setProps(values);
      setClosed(true);
    },
    validationSchema,
  });

  if (closed) return null;
  const formId = 'summaryModal';
  return (
    <>
      <Modal
        onCloseRequest={() => {
          setClosed(true);
        }}
        disableClose
        desktopMaxWidth={450}
      >
        <ModalWrapper>
          <CenteredRow>
            <Typography type={TypographyType.BodyMicro} color={colors.black}>
              <Trans id="common.button.sendAgain">Poslat znovu</Trans>
            </Typography>
          </CenteredRow>
          <FormWrapper formik={formik}>
            {sendWhen && (
              <Row marginSize="m">
                <SelectField<PromptProps>
                  formId={formId}
                  name="sendWhen"
                  label={labels.howToSend.sendWhen}
                  options={pickupOptions}
                  noCheckmark
                  data-test="homepage.input.sendWhen"
                  disabled={formik.isSubmitting}
                />
              </Row>
            )}

            {cashOnDeliveryValue && (
              <>
                <CheckboxField<PromptProps>
                  formId={formId}
                  name="cashOnDelivery"
                  label={
                    <>
                      {sharedKeys.cashOnDelivery} (+{' '}
                      <Money currency="CZK">
                        {
                          getSpecificCfg(priceList, sendToCountry)
                            .cashOnDelivery
                        }
                      </Money>
                      )
                    </>
                  }
                  data-test="additionalServices.checkbox.cashOnDelivery"
                  disabled={formik.isSubmitting}
                />
                {formik.values.cashOnDelivery && (
                  <>
                    <Row marginSize="m">
                      <TextField<PromptProps>
                        formId={formId}
                        name="cashOnDeliveryValue"
                        label={sharedKeys.cashOnDeliveryValue}
                        type="number"
                        step={1}
                        noCheckMark
                        customIcon={<MoneySymbolIcon currency="CZK" />}
                      />
                    </Row>
                    {variableSymbol && (
                      <Row marginSize="m">
                        <TextField<PromptProps>
                          formId={formId}
                          name="variableSymbol"
                          label={fields.variableSymbol}
                          type="text"
                          autocorrect="off"
                          autocapitalize="none"
                        />
                      </Row>
                    )}
                  </>
                )}
              </>
            )}

            <CenteredRow marginSize="m">
              <Button buttonType={ButtonType.PRIMARY} type="submit">
                <Trans id="common.button.continue">Pokračovat</Trans>
              </Button>
            </CenteredRow>
          </FormWrapper>
        </ModalWrapper>
      </Modal>
    </>
  );
};
