import { config } from '@hp/config';
import { Country } from '@hp/core/shared';
import { useYup, useYupSharedValidations } from '@hp/form';
import { useYupSharedOrderValidations } from '@hp/utils';

import { RecipientFormFields } from '../../types';

export const useRecipientValidationSchema = (
  country: Country,
  deliveryToParcelShopMode: boolean,
) => {
  const Yup = useYup();

  const {
    city,
    name,
    street,
    phone,
    email,
    note,
    subject,
  } = useYupSharedValidations(Yup);

  const {
    app: { defaultCountry },
  } = config;

  const { postalCode } = useYupSharedOrderValidations(Yup);

  return Yup.object().shape<RecipientFormFields>({
    recipient: subject,
    name,
    contactPerson: Yup.string().max(35),
    street: deliveryToParcelShopMode
      ? Yup.string()
      : country === defaultCountry
      ? street
      : Yup.string().max(35).required(),
    city: deliveryToParcelShopMode
      ? Yup.string()
      : country === defaultCountry
      ? city
      : Yup.string().max(35).required(),
    postalCode: deliveryToParcelShopMode
      ? Yup.string()
      : postalCode(country, 'postalCode', false),
    phone: phone('phone'),
    email: email.required(),
    note,
  });
};
