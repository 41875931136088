import { AddressComponent } from '@hp/form';

const addressSet = [
  'neighborhood',
  'premise',
  'street_number',
  'route',
  'sublocality',
  'locality',
];

export const formatGoogleAddress = (
  addressComponents: AddressComponent[],
  googleFormattedAdressFallback: string,
) => {
  const pairs = new Map<string, string>(
    addressComponents
      .map((x) =>
        x.types
          .filter((y) => addressSet.includes(y))
          .map((type) => [type, x.long_name]),
      )
      .flat() as [string, string][],
  );
  //join street number to street, but promise is 'Karluv most'
  if (
    pairs.has('premise') &&
    pairs.has('route') &&
    pairs.get('premise') !== pairs.get('route')
  ) {
    pairs.set('route', `${pairs.get('route')} ${pairs.get('premise')}`);
    pairs.delete('premise');
  }
  //join street number to street
  if (
    pairs.has('street_number') &&
    pairs.has('route') &&
    pairs.get('street_number') !== pairs.get('route')
  ) {
    pairs.set('route', `${pairs.get('route')} ${pairs.get('street_number')}`);
    pairs.delete('street_number');
  }

  if (pairs.size >= 1) {
    const ordered = [...pairs].sort(
      (a, b) => addressSet.indexOf(a[0]) - addressSet.indexOf(b[0]),
    );
    const uniques = [...new Set(ordered.map((x) => x[1]))];
    return uniques.join(', ');
  }
  //there is no way to create a nice addressm we using google formated adress
  return googleFormattedAdressFallback;
};
