import { BanksQuery } from '@hp/core/shared';

import { useBanksQuery } from '../graphql';

const toItem = ({ code, name }: BanksQuery['banks'][0]) => {
  return {
    value: code,
    label: `${code} ${name}`,
  };
};
export const useBankCodes = () => {
  const { data } = useBanksQuery();
  return data?.banks?.map(toItem) ?? [];
};
