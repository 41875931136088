import { User } from '@hp/context';

import { useProfileWithAdressessQuery } from '../../graphql';

export const useSavedBankAccount = (user: User | null) => {
  const { data } = useProfileWithAdressessQuery({
    skip: !user,
  });
  if (!data || !user) return null;
  const {
    profile: { account },
  } = data;
  return account
    ? {
        accountNumber: account.number,
        bankCode: account.bankCode,
      }
    : null;
};
