import { usePasswordValidations } from '@hp/auth';
import { User } from '@hp/context';
import { AddressType, Country } from '@hp/core/shared';
import { useYup, useYupSharedValidations } from '@hp/form';
import { useYupSharedOrderValidations } from '@hp/utils';

import { SenderFormFields } from '../../types';

export const useSenderValidationSchema = (
  country: Country,
  user: User,
  //@ts-ignore
  isPickupFromParcelShop: boolean,
) => {
  const Yup = useYup();

  const {
    postalCode: postalCodeWithDepo,
    postalCodeSimply: postalCodeSimple,
    pickup,
  } = useYupSharedOrderValidations(Yup);

  const {
    city,
    cityOptional,
    ico,
    name,
    nameOptional,
    dic,
    street,
    streetOptional,
    phone,
    email,
    note,
    termsAgreement,
    subject,
  } = useYupSharedValidations(Yup);

  const { password } = usePasswordValidations(Yup);

  return Yup.object().shape<SenderFormFields>({
    sender: subject,
    name,
    ico: Yup.string().when('sender', {
      is: (value) => value === AddressType.COMPANY,
      then: ico.required(),
      otherwise: ico,
    }),
    dic,
    street,
    city,
    postalCode: postalCodeWithDepo(country, 'postalCode', true),
    phone: phone('phone'),
    email: email.required(),
    invoiceToAnotherAddress: Yup.boolean(),
    nameInvoice: Yup.string().when('invoiceToAnotherAddress', {
      is: (value) => value === true,
      then: name,
      otherwise: nameOptional,
    }),
    icoInvoice: ico,
    dicInvoice: dic,
    streetInvoice: Yup.string().when('invoiceToAnotherAddress', {
      is: (value) => value === true,
      then: street,
      otherwise: streetOptional,
    }),
    cityInvoice: Yup.string().when('invoiceToAnotherAddress', {
      is: (value) => value === true,
      then: city,
      otherwise: cityOptional,
    }),
    postalCodeInvoice: Yup.string().when('invoiceToAnotherAddress', {
      is: (value) => value === true,
      then: postalCodeSimple(country, 'postalCodeInvoice'),
    }),
    pickup,
    sendWhen: Yup.string(),
    note,
    newsletterAgreement: Yup.boolean(),
    createAccount: Yup.boolean(),
    termsAgreement: !user ? termsAgreement : Yup.boolean(),
    password: Yup.string().when('createAccount', {
      is: (value) => value === true,
      then: password,
      otherwise: Yup.string().optional().nullable(),
    }),
  });
};
