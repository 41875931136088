import { Modal } from '@hp/modal';
import React, { FC } from 'react';

import { PrivacyPolicyContent } from '../content/PrivacyPolicy';
import { TermsAndConditionsContent } from '../content/TermsAndConditions';

type TermsModalsProps = {
  termsModalVisible: boolean;
  privacyModalVisible: boolean;
  setTermsModalVisible: (visible: boolean) => void;
  setPrivacyModalVisible: (visible: boolean) => void;
};

export const TermsModals: FC<TermsModalsProps> = ({
  termsModalVisible,
  privacyModalVisible,
  setTermsModalVisible,
  setPrivacyModalVisible,
}) => {
  return (
    <>
      {termsModalVisible && (
        <Modal onCloseRequest={() => setTermsModalVisible(false)}>
          <TermsAndConditionsContent />
        </Modal>
      )}
      {privacyModalVisible && (
        <Modal onCloseRequest={() => setPrivacyModalVisible(false)}>
          <PrivacyPolicyContent />
        </Modal>
      )}
    </>
  );
};
