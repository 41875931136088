import {
  Button,
  ButtonType,
  GoogleMap,
  H2,
  Hr,
  LockerIcon,
  Note,
  NoteItem,
  Nowrap,
  PaymentCardIcon,
  PaymentCashIcon,
  PaymentMobileIcon,
  Typography,
  TypographyType,
} from '@hp/atomic';
import { ScrollBox } from '@hp/components';
import { Language } from '@hp/config';
import { useLanguage } from '@hp/core/src/providers/LanguageProvider';
import { CenteredRow, Row } from '@hp/layout';
import { colors } from '@hp/theme';
import { Trans } from '@lingui/react';
import React, { FC } from 'react';

import { OpeningHours } from './OpeningHours';
import { ParcelShopOpeningState } from './ParcelShopOpeningState';
import {
  ContentWrapper,
  DescriptionWrapper,
  DetailWrapper,
  IconWrapper,
  ItemContentLeft,
  ItemContentRight,
  ItemRow,
  MapWrapper,
  PhoneNumber,
  Photo,
} from './styled';
import { ParcelShopItemDetailProps } from './types';

export const ParcelShopItemDetail: FC<ParcelShopItemDetailProps> = ({
  onClick,
  item,
  mode: type,
  showInfoForParcelL,
}) => {
  const { language } = useLanguage();

  const phone =
    language === Language.CS
      ? item?.contactInfo?.phone
      : `+420 ${item?.contactInfo?.phone}`;

  return (
    <ContentWrapper>
      <DetailWrapper>
        <ItemRow>
          <ItemContentLeft>
            <H2 marginSize="zero">
              <Typography type={TypographyType.BodyExtraLargeLight}>
                {item?.contactInfo?.name}
              </Typography>
            </H2>

            <Row marginSize="s" noBottomMargin>
              <Typography type={TypographyType.BodySmallLight}>
                {item?.location?.address?.street}
                <br />
                {item?.location?.address?.zip} {item?.location?.address?.city}
                <br />
                {item?.location?.address?.note}
                <Typography type={TypographyType.BodySmall}>
                  <Nowrap>
                    <Trans id="detail.phone">Tel</Trans>
                    {': '}
                    <PhoneNumber href={`tel: ${phone}`}>{phone}</PhoneNumber>
                  </Nowrap>
                </Typography>
              </Typography>
            </Row>
          </ItemContentLeft>
          <ItemContentRight>
            <ParcelShopOpeningState
              item={item}
              type={type}
              language={language}
            />
          </ItemContentRight>
        </ItemRow>
        {showInfoForParcelL && type === 'pickup' && item?.type === 'locker' && (
          <Row marginSize="s">
            <Note backgroundColor={colors.noteImportantBackground}>
              <NoteItem
                icon={<LockerIcon />}
                textColor={colors.noteImportantText}
                bold
              >
                <Trans id="common.detail.parcel-L-warning">
                  V tomto boxu zbývají pro balík L pouze 2 volné schránky. Pokud
                  se kapacita vyčerpá, balík můžete přinést na jiné podací místo
                  v okolí.
                </Trans>
              </NoteItem>
            </Note>
          </Row>
        )}

        {item?.photos?.length > 0 && (
          <>
            <Hr marginSize="s" />

            <Row marginSize="s">
              <ScrollBox>
                {item?.photos?.map((photo) => {
                  return <Photo key={photo} src={photo} />;
                })}
              </ScrollBox>
            </Row>
          </>
        )}

        <Hr marginSize="s" />

        <Row marginSize="s" noBottomMargin>
          <Typography type={TypographyType.BodyLight}>
            <Trans id="common.detail.paymentOptions">Možnosti platby</Trans>
          </Typography>
        </Row>

        <Row marginSize="s">
          <Typography type={TypographyType.BodySmall}>
            {item?.type !== 'locker' && item?.paymentOptions?.codAllowed && !item?.paymentOptions?.onlineCodAllowed && (
              <Row marginSize="s" verticallyCentered>
                <IconWrapper>
                  <PaymentCashIcon />
                </IconWrapper>

                <Trans id="common.detail.codAllowed">Hotovost</Trans>
              </Row>
            )}

            {item?.paymentOptions?.cardPaymentAllowed && (
              <Row marginSize="s" verticallyCentered>
                <IconWrapper>
                  <PaymentCardIcon />
                </IconWrapper>

                <Trans id="common.detail.cardPaymentAllowed">
                  Platba kartou
                </Trans>
              </Row>
            )}

            {(item?.paymentOptions?.onlineCodAllowed ||
              item?.paymentOptions?.prepaidAllowed) && (
              <>
                <Row marginSize="s" verticallyCentered noBottomMargin>
                  <IconWrapper>
                    <PaymentMobileIcon />
                  </IconWrapper>

                  <Trans id="common.detail.prepaidAllowed">Kartou online</Trans>
                </Row>
                <Row marginSize="zero" verticallyCentered>
                  <DescriptionWrapper>
                    <Typography type={TypographyType.BodyMicroLight}>
                      <Trans id="common.detail.prepaidAllowed.description">
                        Před vyzvednutím zásilky v boxu zaplatíte dobírkovou
                        částku kartou.
                      </Trans>
                    </Typography>
                  </DescriptionWrapper>
                </Row>
              </>
            )}
          </Typography>
        </Row>

        <Hr marginSize="s" />

        <Row marginSize="s">
          <Typography type={TypographyType.BodyLight}>
            <Trans id="delivery.parcelShopDetail.openingHours">
              Otevírací doba
            </Trans>
          </Typography>
        </Row>

        <Row marginSize="s">
          <OpeningHours data={item.openingHours} />
        </Row>

        {onClick && (
          <CenteredRow marginSize="m" noBottomMargin>
            <Button
              bottom
              type="button"
              buttonType={ButtonType.PRIMARY}
              animate
              onClick={() => onClick()}
            >
              {item?.type === 'locker' ? (
                <Trans id="delivery.parcelShopDetail.button.selectBox">
                  Vybrat box
                </Trans>
              ) : (
                <Trans id="delivery.parcelShopDetail.button.selectParcelShop">
                  Vybrat pobočku
                </Trans>
              )}
            </Button>
          </CenteredRow>
        )}
      </DetailWrapper>

      <MapWrapper>
        <GoogleMap
          position={{
            lat: item?.location?.coordinates?.latitude,
            lng: item?.location?.coordinates?.longitude,
          }}
          desktopHeight={'100%'}
        />
      </MapWrapper>
    </ContentWrapper>
  );
};
