import { Button, ButtonType, UnitCmIcon } from '@hp/atomic';
import { TextField } from '@hp/form';
import { Inner, Row } from '@hp/layout';
import { labels } from '@hp/locale';
import { colors } from '@hp/theme';
import { Trans } from '@lingui/react';
import React, { FC } from 'react';

import { ButtonWrapper, CalculatorIconStyled } from './styled';
import { CalculatorFormProps } from './useCalculator';

type DimensionFormContentProps = {
  formId: string;
};

export const DimensionFormContent: FC<DimensionFormContentProps> = ({
  formId,
}) => {
  return (
    <Inner paddingY="xs" paddingX="m">
      <Row marginSize="xs">
        <TextField<CalculatorFormProps>
          formId={formId}
          name="length"
          label={labels.dimension.length}
          type="number"
          customIcon={<UnitCmIcon />}
          data-test="parcel.calculator.input.length"
        />
      </Row>

      <Row marginSize="s">
        <TextField<CalculatorFormProps>
          formId={formId}
          name="width"
          label={labels.dimension.width}
          type="number"
          customIcon={<UnitCmIcon />}
          data-test="parcel.calculator.input.width"
        />
      </Row>

      <Row marginSize="xs">
        <TextField<CalculatorFormProps>
          formId={formId}
          name="height"
          label={labels.dimension.height}
          type="number"
          customIcon={<UnitCmIcon />}
          data-test="parcel.calculator.input.height"
        />
      </Row>

      <ButtonWrapper>
        <Button
          fullWidth
          type="submit"
          className="gaButton gaButtonSubmit_compute"
          buttonType={ButtonType.PRIMARY}
          icon={<CalculatorIconStyled color={colors.white} />}
          data-test="parcel.calculator.button.submit"
        >
          <Trans id="common.button.compute">Spočítat</Trans>
        </Button>
      </ButtonWrapper>
    </Inner>
  );
};
