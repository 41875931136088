import { Language } from '@hp/core/shared';
import { DayOfTheWeek, getDayName, groupBy } from '@hp/utils';
import { I18n } from '@lingui/core';

import { OpenClosePair, OpeningHours as OpeningHoursType } from '../../types';
import { MultiDayInterval, PairWithDay, ShopState } from './types';

const getHoursAndMinuts = (timeStr: string) => {
  return timeStr.split(':').map((x) => Number.parseInt(x));
};

export const allDayNames = Object.values(DayOfTheWeek);
/** returns true when closing hour is below curent time */
const isLower = ({ close }: OpenClosePair, currentWeight: number) => {
  const [c, d] = getHoursAndMinuts(close);
  const closeWeight = c * 100 + d;

  return closeWeight < currentWeight;
};

/** returns true when opening hour is upper than curent time */
const isHigher = ({ open }: OpenClosePair, currentWeight: number) => {
  const [a, b] = getHoursAndMinuts(open);
  const openWeight = a * 100 + b;

  return currentWeight < openWeight;
};
const isInRange = (pair: OpenClosePair, currentWeight: number) => {
  return !isHigher(pair, currentWeight) && !isLower(pair, currentWeight);
};

/** returns timespan in minutes for 2 adjoined pairs */
const getDelta = (
  a: { dayName: DayOfTheWeek; time: string },
  b: { dayName: DayOfTheWeek; time: string },
) => {
  let aDayIndex = allDayNames.indexOf(a.dayName);
  let bDayIndex = allDayNames.indexOf(b.dayName);

  const [h1, m1] = getHoursAndMinuts(a.time);
  let aWeight = 100 * (h1 + m1 / 60);
  const [h2, m2] = getHoursAndMinuts(b.time);

  let bWeight = 100 * (h2 + m2 / 60);
  if (aWeight === 2400) {
    aWeight = 0;
    aDayIndex = aDayIndex + 1;
  }
  if (bWeight === 2400) {
    bWeight = 0;
    bDayIndex = bDayIndex + 1;
  }
  const dayDelta = bDayIndex - aDayIndex;
  const diffMinuts = Math.round(
    (bWeight - aWeight + dayDelta * 2400) / (100 / 60),
  );

  return Math.abs(diffMinuts % (24 * 60 * 7)) /* minits in a week */;
};

const lpad = (value: number, padding: number) => {
  const zeroes = new Array(padding + 1).join('0');

  return (zeroes + value).slice(-padding);
};

const roundTime = (timeStr: string) => {
  let [h, m] = getHoursAndMinuts(timeStr);
  m = Math.round(m / 10) * 10;
  if (m === 60) {
    m = 0;
    h++;
  }

  return `${lpad(h, 2)}:${lpad(m, 2)}`;
};

const roundPair = ({ open, close }: OpenClosePair) => {
  return { open: roundTime(open), close: roundTime(close) } as OpenClosePair;
};

/** joins all adjoined intervals into one. It does not connects intervals from differents days. */
const joinIntervals = function* (
  intervals: Iterable<PairWithDay>,
  maxDiff = 5,
) {
  let previous: PairWithDay;

  for (const current of intervals) {
    if (previous) {
      if (previous.dayName !== current.dayName) yield previous;
      else {
        const delta = getDelta(
          { dayName: previous.dayName, time: previous.pair.close },
          { dayName: current.dayName, time: current.pair.open },
        );
        if (
          delta <
          maxDiff /* ignoring e.g. 2 minuts gaps between close&open time*/
        ) {
          //new reference is required not to modify openingHours themselfs
          previous = {
            ...previous,
            pair: { ...previous.pair, close: current.pair.close },
          };
          continue;
        } else {
          yield previous;
        }
      }
    }
    previous = current;
  }
  yield previous;
};

/** joins all adjoined intervals into one, even when intervals are in different days.*/
const joinIntervalsOverDays = function* (
  intervals: Iterable<PairWithDay>,
  maxDiff = 5,
  debug = false,
) {
  let previous: MultiDayInterval;

  for (const current of intervals) {
    if (previous) {
      const delta = getDelta(
        { dayName: previous.dayClose, time: previous.close },
        { dayName: current.dayName, time: current.pair.open },
      );
      if (debug) console.log(delta, maxDiff);
      if (
        delta < maxDiff /* ignoring e.g. 2 minuts gaps between close&open time*/
      ) {
        //new reference is required not to modify openingHours themselfs
        previous = {
          ...previous,
          dayClose: current.dayName,
          close: current.pair.close,
        };
        continue;
      } else yield previous;
    }
    previous = {
      close: current.pair.close,
      open: current.pair.open,
      dayOpen: current.dayName,
      dayClose: current.dayName,
    };
  }

  const openCloseDelta = getDelta(
    { dayName: previous.dayOpen, time: previous.open },
    { dayName: previous.dayClose, time: previous.close },
  );

  if (openCloseDelta === 0) {
    previous = {
      close: '00:00',
      open: '00:00',
      dayOpen: DayOfTheWeek.monday,
      dayClose: DayOfTheWeek.monday,
      nonstop: true,
    };
  }
  yield previous;
};

const getIntervals = function* (
  openingHours: OpeningHoursType,
  startingTime?: Date | null,
  roundIntervals = true,
) {
  const d = startingTime;
  const dayOfWeekIndexInit = d ? (d.getDay() + 6) % 7 : 0;
  const currentWeight = d ? d.getHours() * 100 + d.getMinutes() : 0;
  let dayOfWeekIndex = dayOfWeekIndexInit;

  for (let n = 0; n <= 7; n++) {
    const hoursPerDayRaw = openingHours[allDayNames[dayOfWeekIndex]] ?? [];

    const hoursPerDay = roundIntervals
      ? hoursPerDayRaw.map(roundPair)
      : hoursPerDayRaw;

    for (const pair of hoursPerDay) {
      if (n === 0 && isLower(pair, currentWeight)) continue;
      if (n === 7 && !isLower(pair, currentWeight)) continue;
      const res: PairWithDay = { dayName: allDayNames[dayOfWeekIndex], pair };
      yield res;
    }
    dayOfWeekIndex = ++dayOfWeekIndex % 7;
  }
};

const convertToOpeningHoursType = (intervals: Iterable<PairWithDay>) => {
  const map = groupBy(
    intervals,
    (x) => x.dayName,
    (x) => x.pair,
  );
  const res = Object.fromEntries(map) as OpeningHoursType;
  allDayNames.forEach((day) => {
    if (!res[day]) res[day] = [];
  });

  return res;
};

export const normalizeOpeniningHours = (data: OpeningHoursType) => {
  return convertToOpeningHoursType(joinIntervals(getIntervals(data)));
};

const getDayOfWeekIndex = (d: Date) => {
  return (d.getDay() + 6) % 7;
};

const isIntervalInRange = (
  { dayOpen, dayClose, nonstop, ...pair }: MultiDayInterval,
  serverTime: Date,
) => {
  if (nonstop) return true;
  const currentWeight = serverTime.getHours() * 100 + serverTime.getMinutes();
  if (!isInRange(pair, currentWeight)) return false;
  const dayOfWeekCurrent = getDayOfWeekIndex(serverTime);
  const [dayOfWeekStart, dayOfWeekEnd] = [
    allDayNames.indexOf(dayOpen),
    allDayNames.indexOf(dayClose),
  ].sort();

  return dayOfWeekCurrent <= dayOfWeekEnd && dayOfWeekStart <= dayOfWeekCurrent;
};

const getDayPrefix = (
  serverTime: Date,
  openingOrClosingDay: DayOfTheWeek,
  language: Language,
  i18n: I18n,
) => {
  const dayOfWeekCurrent = allDayNames[getDayOfWeekIndex(serverTime)];
  if (openingOrClosingDay === dayOfWeekCurrent) return null;

  return getDayName(openingOrClosingDay, language, i18n, true, true);
};

export const getState = (
  serverTime: Date,
  data: OpeningHoursType,
  language: Language,
  i18n: I18n,
  debug = false,
) => {
  if (debug) {
    console.log('intervals', [...getIntervals(data, serverTime)]);
    console.log('joinedOVerDays', [
      ...joinIntervalsOverDays(getIntervals(data, serverTime), undefined, true),
    ]);
  }
  // console.log('getState', serverTime, data);
  let n = 0;
  for (const interval of joinIntervalsOverDays(
    getIntervals(data, serverTime),
  )) {
    if (interval.nonstop) return { state: ShopState.nonstop };

    if (n++ === 0 && isIntervalInRange(interval, serverTime))
      return {
        state: ShopState.opened,
        interval,
        dayPrefix: getDayPrefix(serverTime, interval.dayClose, language, i18n),
      };

    return {
      state: ShopState.closed,
      interval,
      dayPrefix: getDayPrefix(serverTime, interval.dayOpen, language, i18n),
    };
  }

  return { state: ShopState.permanentlyClosed };
};
