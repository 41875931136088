import { Nowrap, Typography, TypographyType } from '@hp/atomic';
import {
  HideOnDesktop,
  HideOnMobile,
  MediaQueryJS,
  WideWrapperMobile,
} from '@hp/layout';
import { colors } from '@hp/theme';
import { Trans } from '@lingui/react';
import React from 'react';

import {
  AccountSwitchIconStyled,
  Divider,
  DividerDesktop,
  FillDataIconStyled,
  HowItWorksContainer,
  HowItWorksContent,
  HowItWorksHeading,
  HowItWorksWrapper,
  IconContent,
  Image,
  PackagingIconStyled,
  Text,
} from './styled';

export const HowItWorks = () => {
  return (
    <WideWrapperMobile>
      <HideOnDesktop>
        <HowItWorksHeading>
          <Typography type={TypographyType.Heading2Light} color={colors.black}>
            <Trans id="homepage.howItWorks.heading">
              Jak to u nás funguje?
            </Trans>
          </Typography>
        </HowItWorksHeading>
      </HideOnDesktop>

      <HowItWorksWrapper>
        <HowItWorksContainer>
          <HideOnMobile>
            <HowItWorksHeading>
              <Typography type={TypographyType.Heading2Light}>
                <Trans id="homepage.howItWorks.heading">
                  Jak to u nás funguje?
                </Trans>
              </Typography>
            </HowItWorksHeading>
          </HideOnMobile>
        </HowItWorksContainer>

        <MediaQueryJS>
          {(isMobileScreen) => (
            <Typography
              type={
                isMobileScreen
                  ? TypographyType.BodyMicroLight
                  : TypographyType.BodyLight
              }
              textAlign="center"
            >
              <HowItWorksContainer>
                <HowItWorksContent>
                  <IconContent>
                    <PackagingIconStyled />

                    <Text>
                      <Trans id="homepage.howItWorks.point1">
                        Zvolíte zemi doručení <Nowrap>a velikost</Nowrap> balíku
                      </Trans>
                    </Text>
                  </IconContent>

                  {!isMobileScreen && (
                    <Image src="/images/phone_send.png" alt="Velikost balíku" />
                  )}
                </HowItWorksContent>

                {isMobileScreen ? <Divider /> : <DividerDesktop />}

                <HowItWorksContent>
                  {!isMobileScreen && (
                    <Image
                      src="/images/phone_delivery.png"
                      alt="Způsob předání"
                      isLeft
                    />
                  )}

                  <IconContent>
                    <AccountSwitchIconStyled />

                    <Text>
                      <Trans id="homepage.howItWorks.point2">
                        Vyberete způsob předání <Nowrap>a doručení</Nowrap>
                      </Trans>
                    </Text>
                  </IconContent>
                </HowItWorksContent>

                {isMobileScreen ? <Divider /> : <DividerDesktop />}

                <HowItWorksContent>
                  <IconContent>
                    <FillDataIconStyled />

                    <Text>
                      <Trans id="homepage.howItWorks.point3">
                        Vyplníte údaje <Nowrap>a zaplatíte</Nowrap> kartou
                      </Trans>
                    </Text>
                  </IconContent>

                  {!isMobileScreen && (
                    <Image
                      src="/images/phone_thank_you.png"
                      alt="Zaplatíte kartou"
                    />
                  )}
                </HowItWorksContent>
              </HowItWorksContainer>
            </Typography>
          )}
        </MediaQueryJS>
      </HowItWorksWrapper>
    </WideWrapperMobile>
  );
};
