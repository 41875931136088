import {
  BoxIcon,
  BoxIcon2,
  BoxIconEmpty,
  Button,
  ButtonType,
  InfoIcon2,
  Note,
  NoteItem,
  Typography,
  TypographyType,
  UpperCase,
  WeightColorIcon,
} from '@hp/atomic';
import { Money, ParcelWeightDescription } from '@hp/components';
import { config as appConfig, useConfig } from '@hp/config';
import {
  Country,
  DynamicConfigRoot,
  getSpecificCfg,
  ParcelSize,
  ParcelSpecsList,
  PickupType,
} from '@hp/core/shared';
import { CountrySelectField, FormWrapper } from '@hp/form';
import {
  CenteredRow,
  Column,
  Column2,
  HideOnDesktop,
  HideOnMobile,
  MediaQueryJS,
  Row as LayoutRow,
} from '@hp/layout';
import { labels } from '@hp/locale';
import { colors } from '@hp/theme';
import { addDataLayerItem, hasFeature } from '@hp/utils';
import { Trans } from '@lingui/react';
import { useFormik } from 'formik';
import React, { FC, SetStateAction, useEffect, useState } from 'react';

import {
  HowToSendFormFields,
  HowToSendFormProps,
  ParcelFormProps,
} from '../../types';
import { computePrice } from '../../utils';
import { ParcelDimensionsDescription } from '../Parcel/ParcelDimensionsDescription';
import {
  BoxIconButton,
  BoxIconWrapper,
  CenteredSpan,
  CountryColumn,
  Divider,
  H2Styled,
  HowToSendSection,
  MultiPackageButton,
  MultipackageRow,
  PriceStyled,
  Row,
} from './styled';
import { useHowToSendForm } from './useHowToSendForm';
import { useHowToSendValidationSchema } from './useHowToSendValidationSchema';

const formId = 'howToSendForm';

type ButtonsGroupProps = {
  parcelSpecs: ParcelSpecsList;
  saveParcelData: (value: SetStateAction<ParcelFormProps>) => void;
  howToSendData: HowToSendFormFields;
  abroad: boolean;
  config: DynamicConfigRoot;
};

const setMutlipleSize = (
  saveParcelData: (value: SetStateAction<ParcelFormProps>) => void,
) => {
  saveParcelData((prev) => {
    if (prev.items?.length > 1) return prev;

    return {
      ...prev,
      items: [
        { size: ParcelSize.S, weight: null },
        { size: ParcelSize.S, weight: null },
      ],
    };
  });
};

const ButtonsGroup: FC<ButtonsGroupProps> = ({
  parcelSpecs,
  saveParcelData,
  howToSendData,
  abroad,
  config,
}) => {
  const [buttonsHover, setButtonsHover] = useState([
    false,
    false,
    false,
    false,
  ]);

  const hanldeHover = (index) => {
    setButtonsHover((prevState) =>
      prevState.map((item, i) => (i === index ? !item : item)),
    );
  };

  const cheapestWayDelivery = (size: ParcelSize) =>
    size !== ParcelSize.XL &&
    getSpecificCfg(
      config.constraints.deliveryParcelShops,
      howToSendData.sendToCountry,
    )
      ? PickupType.PARCEL_SHOP
      : PickupType.COURIER;

  const cheapestWayPickup = (size: ParcelSize) =>
    hasFeature('v3') && size !== ParcelSize.XL
      ? PickupType.PARCEL_SHOP
      : PickupType.COURIER;

  const setSize = (size: ParcelSize) => {
    const hasRanges = !!parcelSpecs[size].ranges?.length;
    saveParcelData((prev) => {
      let weight: number = null;
      if (hasRanges) {
        const prevWeight = prev?.items?.[0]?.weight;
        if (!prevWeight) weight = Math.floor(parcelSpecs[size].ranges[0][1]);
        else {
          const allowedWeights = parcelSpecs[size].ranges.map((x) =>
            Math.floor(x[1]),
          );
          weight = allowedWeights.includes(prevWeight)
            ? prevWeight
            : allowedWeights[0];
        }
      }

      return { ...prev, items: [{ size, weight }] };
    });
  };

  return (
    <>
      {Object.entries(parcelSpecs).map(([key, value], index) => {
        const size = key as ParcelSize;
        const dimensions = value.dimensions;

        return (
          <BoxIconButton
            buttonType={ButtonType.GROUP}
            height={abroad ? '310px' : '280px'}
            key={key}
            type="submit"
            onClick={() => setSize(size)}
            onMouseEnter={() => hanldeHover(index)}
            onMouseLeave={() => hanldeHover(index)}
          >
            <div>
              <Typography
                type={TypographyType.BodySmall}
                textAlign="center"
                color={colors.red_main}
              >
                <UpperCase mode="capitalize">
                  <Trans id="common.parcel">balík</Trans>&nbsp;{size}
                </UpperCase>

                {abroad && (
                  <>
                    <br />
                    <Trans id="common.in-abroad">do zahraničí</Trans>
                  </>
                )}
              </Typography>
              <br />
              <Typography
                type={TypographyType.BodyMicro}
                color={colors.gray_mid}
                textAlign="center"
              >
                <Trans id="common.maxParameters">Maximální parametry</Trans>
              </Typography>
            </div>

            <div>
              <BoxIconWrapper>
                {dimensions ? (
                  <>
                    <BoxIcon2 zoom={value.iconZoom || 1} size={size}>
                      {size !== 'XL' && (
                        <>
                          <Typography
                            type={TypographyType.BodyMicro}
                            textAlign="center"
                            color={colors.red_main}
                          >
                            {
                              dimensions.length === 3
                                ? dimensions[2] /* height*/
                                : dimensions[1] /*maxWidth*/
                            }
                          </Typography>

                          <Typography
                            type={TypographyType.BodyMicro}
                            textAlign="center"
                            color={colors.gray_mid}
                          >
                            &nbsp;cm
                          </Typography>
                        </>
                      )}
                    </BoxIcon2>
                  </>
                ) : (
                  <BoxIcon active zoom={value.iconZoom || 1} />
                )}
              </BoxIconWrapper>

              <Typography
                type={TypographyType.BodyMicro}
                color={colors.gray_mid}
                textAlign="center"
              >
                <ParcelDimensionsDescription
                  size={size}
                  specList={parcelSpecs}
                />
              </Typography>
            </div>

            <div>
              <Typography
                type={TypographyType.BodyMicro}
                color={colors.gray_mid}
                textAlign="center"
              >
                <Trans id="common.and">a</Trans>
              </Typography>
            </div>

            <div>
              <CenteredSpan>
                <WeightColorIcon />

                <Typography
                  type={TypographyType.BodyMicro}
                  textAlign="center"
                  color={colors.gray_mid}
                >
                  <ParcelWeightDescription
                    size={size}
                    specList={parcelSpecs}
                    highlightWeight
                    onlyWeight
                  />
                </Typography>
              </CenteredSpan>

              <PriceStyled isHoverState={buttonsHover[index]}>
                <Typography
                  type={TypographyType.BodyRegular}
                  textAlign="center"
                  color={colors.white}
                >
                  <Trans id="common.priceFrom">
                    od&nbsp;
                    <Money currency="CZK">
                      {
                        computePrice(
                          {
                            howToSend: {
                              ...howToSendData,
                              pickupType: cheapestWayPickup(size),
                              deliveryType: cheapestWayDelivery(size),
                            },
                            parcel: {
                              items: [{ size, weight: value.ranges?.[0]?.[0] }],
                            },
                          },
                          config,
                        ).total
                      }
                    </Money>
                  </Trans>
                </Typography>
              </PriceStyled>
            </div>
          </BoxIconButton>
        );
      })}
    </>
  );
};

export const HowToSendFormV3: FC<HowToSendFormProps> = ({
  selectedParcelShop,
  onSendToCountryChange,
}) => {
  const [showMPSInfo, setShowMPSInfo] = useState(false);

  useEffect(() => {
    if (showMPSInfo) {
      addDataLayerItem({
        event: 'mps_detail_interest',
      });
    }
  }, [showMPSInfo]);

  const { config } = useConfig();
  const {
    initialValues,
    handleSubmit,
    saveFormData,
    saveParcelData,
  } = useHowToSendForm(selectedParcelShop);

  const formik = useFormik<HowToSendFormFields>({
    initialValues,
    enableReinitialize: false,
    onSubmit: handleSubmit,
    validationSchema: useHowToSendValidationSchema(false),
  });

  useEffect(() => {
    saveFormData(formik.values);
  }, [formik.values]);

  useEffect(() => {
    if (onSendToCountryChange) {
      onSendToCountryChange(initialValues?.sendToCountry);
    }
  }, [initialValues?.sendToCountry]);

  const parcelSpecs = getSpecificCfg(
    config.parcelSpecifications,
    formik.values.sendToCountry,
  );

  const abroad = appConfig.app.defaultCountry !== formik.values.sendToCountry;

  const formHeading = (
    <H2Styled>
      <Typography type={TypographyType.Heading2Light}>
        <Trans id="homepage.howToSend.heading">Máte něco k odeslání?</Trans>
      </Typography>
    </H2Styled>
  );

  const multipackageButton = hasFeature('multiPackage') ? (
    <MultipackageRow>
      <Column2 hideOnDesktop>
        <CenteredRow marginSize="s">
          <BoxIconEmpty />
        </CenteredRow>
      </Column2>

      <Column2 withSpacing>
        <LayoutRow marginSize="xs">
          <Typography type={TypographyType.BodySmall} color={colors.red_main}>
            <Trans id="homepage.howToSend.mps.title">
              Více balíků pro stejného příjemce
            </Trans>
          </Typography>
        </LayoutRow>

        <HideOnMobile>
          <LayoutRow marginSize="s">
            <BoxIconEmpty zoom={0.8} />
          </LayoutRow>
        </HideOnMobile>

        <LayoutRow marginSize="xs">
          <Typography
            type={TypographyType.BodyMicroLight}
            color={colors.gray_mid}
          >
            <Trans id="homepage.howToSend.mps.text">
              V jedné objednávce můžete odeslat více balíků na jednu adresu.
            </Trans>
          </Typography>
        </LayoutRow>

        <LayoutRow marginSize="xs">
          <MultiPackageButton
            type="submit"
            buttonType={ButtonType.TEXT}
            onClick={() => setMutlipleSize(saveParcelData)}
          >
            <Trans id="homepage.howToSend.mps.buttonText">Poslat balíky</Trans>
          </MultiPackageButton>
        </LayoutRow>
      </Column2>
    </MultipackageRow>
  ) : (
    <MultipackageRow>
      <Note marginSize="m">
        <Button
          type="button"
          buttonType={ButtonType.TEXT}
          icon={<InfoIcon2 />}
          onClick={() => setShowMPSInfo((prev) => !prev)}
          canWrap
        >
          <Trans id="homepage.howToSend.button.repeat">
            Potřebujete poslat stejnému příjemci více balíků?
          </Trans>
        </Button>

        {showMPSInfo && (
          <NoteItem>
            <Trans id="homepage.howToSend.deliveryNote.repeat">
              Pokud potřebujete poslat více balíků stejnému příjemci, zvolte na
              konci objednávky možnost „Opakovat objednávku“.
            </Trans>
          </NoteItem>
        )}
      </Note>
    </MultipackageRow>
  );

  return (
    <HowToSendSection id="howToSendSection">
      <FormWrapper formik={formik}>
        <HideOnDesktop>{formHeading}</HideOnDesktop>

        <MediaQueryJS>
          {(isMobileScreen) => (
            <>
              <CenteredRow marginSize="m">
                <Column />

                <Column>
                  <HideOnMobile notFullWidth>{formHeading}</HideOnMobile>
                </Column>

                <CountryColumn fullWidth={isMobileScreen}>
                  {hasFeature('sourceCountry') ? (
                    <Row>
                      <Column2 centered smallerFlex>
                        <CountrySelectField<HowToSendFormFields>
                          formId={formId}
                          secondary={!isMobileScreen}
                          name="sendFromCountry"
                          label={
                            labels.howToSend.withSourceCountry.sendFromCountry
                          }
                          data-test="homepage.input.sendFromCountry"
                          filter={(country) => country === Country.CZ}
                          showAsCode
                          disabled={formik.isSubmitting}
                        />
                      </Column2>

                      <Divider />

                      <Column2 centered smallerFlex>
                        <CountrySelectField<HowToSendFormFields>
                          formId={formId}
                          secondary={!isMobileScreen}
                          name="sendToCountry"
                          onChange={(event) => {
                            if (onSendToCountryChange) {
                              onSendToCountryChange(event.target.value);
                            }
                          }}
                          label={
                            labels.howToSend.withSourceCountry.sendToCountry
                          }
                          data-test="homepage.input.sendToCountry"
                          showAsCode
                          disabled={formik.isSubmitting}
                        />
                      </Column2>
                    </Row>
                  ) : (
                    <CountrySelectField<HowToSendFormFields>
                      formId={formId}
                      secondary={!isMobileScreen}
                      name="sendToCountry"
                      onChange={(event) => {
                        if (onSendToCountryChange) {
                          onSendToCountryChange(event.target.value);
                        }
                      }}
                      label={labels.howToSend.sendToCountry}
                      data-test="homepage.input.sendToCountry"
                      disabled={formik.isSubmitting}
                    />
                  )}
                </CountryColumn>

                <Column />
              </CenteredRow>

              <Row>
                <ButtonsGroup
                  parcelSpecs={parcelSpecs}
                  abroad={abroad}
                  saveParcelData={saveParcelData}
                  howToSendData={formik.values}
                  config={config}
                />

                {!isMobileScreen && multipackageButton}
              </Row>

              {isMobileScreen && multipackageButton}
            </>
          )}
        </MediaQueryJS>
      </FormWrapper>
    </HowToSendSection>
  );
};
