import { Modal } from '@hp/modal';
import { addDataLayerItem } from '@hp/utils';
import React, { FC } from 'react';

import {
  MaxSizeCalculatorForm,
  MaxSizeCalculatorFormProps,
} from './MaxSizeCalculator';

type MaxSizeCalculatorModalProps = MaxSizeCalculatorFormProps & {
  opened?: boolean;
  onCloseRequest?: () => void;
};

export const MaxSizeCalculatorModal: FC<MaxSizeCalculatorModalProps> = ({
  onSubmit,
  opened = false,
  onCloseRequest,
}) => {
  return (
    opened && (
      <Modal onCloseRequest={onCloseRequest} desktopMaxWidth={392}>
        <MaxSizeCalculatorForm
          onSubmit={(values) => {
            onCloseRequest?.();
            onSubmit(values);
            addDataLayerItem({ event: 'calculation_parcel_fit' });
          }}
        />
      </Modal>
    )
  );
};
