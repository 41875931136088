import {
  AdditionalServicesFormFields,
  AllFormsData,
  HowToSendFormFields,
  ParcelFormProps,
  RecipientFormFields,
  SenderFormFields,
} from '../types';

export const refillStorage = (
  allFormsData: AllFormsData,
  setHowToSend: (value: React.SetStateAction<HowToSendFormFields>) => void,
  setParcel: (value: React.SetStateAction<ParcelFormProps>) => void,
  setRecipient: (value: React.SetStateAction<RecipientFormFields>) => void,
  setSender: (value: React.SetStateAction<SenderFormFields>) => void,
  setAdditionalParams: (
    value: React.SetStateAction<AdditionalServicesFormFields>,
  ) => void,
) => {
  const {
    additionalServices,
    howToSend,
    parcel,
    recipient,
    sender,
  } = allFormsData;
  setHowToSend(howToSend);
  setParcel(parcel);
  setRecipient(recipient);
  setSender(sender);
  setAdditionalParams(additionalServices);
};
