import { Strong } from '@hp/atomic';
import { CountryName } from '@hp/components';
import { Country } from '@hp/core/shared';
import { formatPhone } from '@hp/form/src/atomic/phoneInput/utils';
import { SpaceBetween } from '@hp/layout';
import { formatPostalCode } from '@hp/utils';
import { Trans } from '@lingui/macro';
import React, { FC } from 'react';

import { RecipientFormFields, SenderFormFields } from '../../types';
import { SubHeading } from './styled';

type AddressSummaryProps = {
  address: Partial<RecipientFormFields & SenderFormFields> & {
    country: string;
  };
};

export const AddressSummary: FC<AddressSummaryProps> = ({
  address: {
    city,
    email,
    name,
    phone,
    postalCode,
    street,
    country,
    contactPerson,
    ico,
    dic,
    note,
    nameInvoice,
    invoiceToAnotherAddress,
    dicInvoice,
    icoInvoice,
    cityInvoice,
    postalCodeInvoice,
    streetInvoice,
  },
}) => (
  <>
    {[name, contactPerson].map((value, i) => (
      <div key={i}>{value}</div>
    ))}
    {ico && (
      <div>
        <Trans id="common.ico.abrevation">IČ</Trans>: {ico}
      </div>
    )}
    {dic && (
      <div>
        <Trans id="common.dic.abrevation">DIČ</Trans>: {dic}
      </div>
    )}
    {[street, city].map((value, i) => (
      <div key={i}>{value}</div>
    ))}
    <div>{formatPostalCode(postalCode, country as Country)}</div>
    <div>
      <CountryName country={country} />
    </div>
    <br />
    <div>
      <Trans id="common.phone.abrevation">Tel</Trans>: {formatPhone(phone)}
    </div>
    <div>
      <Trans id="common.label.email">E-mail</Trans>: {email ? email : ' - '}
    </div>
    {note && (
      <div>
        <br />
        <Trans id="common.label.note">Poznámka</Trans>: {note}
      </div>
    )}
    {(invoiceToAnotherAddress || invoiceToAnotherAddress === false) && (
      <>
        <SpaceBetween>
          <SubHeading>
            <Strong>
              <Trans id="account.profile.billingAddress">
                Fakturační adresa
              </Trans>
            </Strong>
          </SubHeading>

          {/* <ChangeButton //TODO: needs to be refactored a a little if we have extra section for invoice
        className="gaButton gaButtonChange gaButtonChange_orderSender_2"
        to="orderSender"
        testId={`sumary.button.change.orderSender`}
      >
        <Trans id="common.button.change">Změnit</Trans>
      </ChangeButton> */}
        </SpaceBetween>

        {invoiceToAnotherAddress && (
          <>
            <div> {nameInvoice}</div>
            {icoInvoice && (
              <div>
                <Trans id="common.ico.abrevation">IČ</Trans>: {icoInvoice}
              </div>
            )}
            {dicInvoice && (
              <div>
                <Trans id="common.dic.abrevation">DIČ</Trans>: {dicInvoice}
              </div>
            )}
            {[streetInvoice, cityInvoice].map((value, i) => (
              <div key={i}>{value}</div>
            ))}
            <div>{formatPostalCode(postalCodeInvoice, country as Country)}</div>
            <div>
              <CountryName country={country} />
            </div>
          </>
        )}
        {invoiceToAnotherAddress === false && (
          <div>
            <Trans id="order.summary.invoiceAddress.sameAsSender">
              Shodná s údaji odesílatele
            </Trans>
          </div>
        )}
      </>
    )}
  </>
);
