import { Link } from '@hp/atomic';
import { useI18n } from '@hp/locale';
import React, { FC, PropsWithChildren } from 'react';

type Props = PropsWithChildren<Record<string, unknown>>;

const SalesFormLink: FC<Props> = ({ children }) => {
  const i18n = useI18n();

  const url =
    i18n.language === 'cs'
      ? 'https://www.dpd.com/cz/cs/poslat-balik/#kontaktni_formular_sales'
      : 'https://www.dpd.com/cz/en/sending-parcels/#kontaktni_formular_sales';

  return (
    <Link href={url} target="_blank">
      {children}
    </Link>
  );
};

export default SalesFormLink;
