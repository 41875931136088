import { useUserContext } from '@hp/context';
import { PickupType } from '@hp/core/shared';
import { QueryParamModeValue, useRouter, UtmParams } from '@hp/seo';
import { useObjectLocalStorage } from '@hp/utils';
import { useEffect } from 'react';

import { useCaseQuery } from '../../graphql';
import { usePayment } from '../../hooks';
import { useOtherDataLocalStorage } from '../../hooks/useOtherDataLocalStorage';
import {
  AdditionalServicesFormFields,
  ParcelFormProps,
  RecipientFormFields,
  SenderFormFields,
} from '../../types';

export const useThankYou = (id: string) => {
  const { user } = useUserContext();
  const { data } = useCaseQuery({
    variables: {
      id,
    },
  });
  const [other, setOther, utils] = useOtherDataLocalStorage();
  const withQrCode = data?.case?.sendHow !== PickupType.COURIER;
  const { qrCodes, processingPayment, status } = usePayment({
    caseId: id,
    withQrCode,

    onComplete: ({ closeInfo }) => {
      closeInfo();
    },
  });
  const router = useRouter();
  const [, setParcel] = useObjectLocalStorage<ParcelFormProps>('parcel');
  const [recipient, setRecipient] = useObjectLocalStorage<RecipientFormFields>(
    'recipient',
  );
  const [, setSender] = useObjectLocalStorage<SenderFormFields>('sender');
  const [, setUtmParams] = useObjectLocalStorage<UtmParams>('utm');

  const [, setAdditionalServices] = useObjectLocalStorage<
    AdditionalServicesFormFields
  >('additionalServices');

  useEffect(() => {
    setOther({
      ...other,
      selectedSenderAddressOverridenByUser: false,
      selectedSenderInvoiceAddressOverridenByUser: false,
      selectedSenderInvoiceAddressUsageOverridenByUser: false,
      bankAccountDataWasOverridenByUser: false,
      tmpThxPageData: recipient
        ? {
            recipient,
          }
        : undefined,
    });
    setUtmParams(null);
    setRecipient(null);
    setParcel(null);
    setAdditionalServices(null);
    if (user) setSender(null);
  }, []);

  const sendNewParcel = () => {
    utils.cleanPreviousOrder();
    router.push('landing');

    return;
  };

  const sendNextParcel = () => {
    router.push('orderSummary', {
      id: id,
      mode: QueryParamModeValue.resend,
    });

    return;
  };

  return {
    qrCodes,
    processingPayment,
    status,
    sendNewParcel,
    sendNextParcel,
    withQrCode,
    caseData: data?.case,
  };
};
