import { ChevronNavIcon, FlexItem } from '@hp/atomic';
import { spacing } from '@hp/theme';
import styled from 'styled-components';

type FormContentProps = {
  noTopMargin?: boolean;
  noBottomMargin?: boolean;
};

export const FormContentWrapper = styled.div<FormContentProps>`
  margin: ${spacing.m} auto;
  margin-top: ${({ noTopMargin }) => noTopMargin && 0};
  margin-bottom: ${({ noBottomMargin }) => noBottomMargin && 0};
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: ${spacing.m};
`;

export const Divider = styled(ChevronNavIcon)`
  display: flex;
  width: 22px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

export const CityPostalCodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 110%;
  margin: 0 -${spacing.s} 0 0;
  > * {
    margin: ${spacing['xs']} 0;
  }
`;

export const CityPostalCodeFlexItem = styled(FlexItem)`
  margin-right: ${spacing.s};
`;
