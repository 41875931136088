import { MobileChatColorsIcon } from '@hp/atomic';
import { Trans } from '@lingui/macro';
import React, { FC } from 'react';

import { IconParagraph } from './IconParagraph';

type RecipientNoteProps = {
  recipientStreet: string;
  destAddressType: string;
};

export const RecipientNote: FC<RecipientNoteProps> = ({
  recipientStreet,
  destAddressType,
}) => {
  return (
    <>
      <IconParagraph icon={<MobileChatColorsIcon />} marginBottomSize="l">
        {recipientStreet !== '' ? (
          <Trans id="order.thankYou.paragraph.recipientSmsInfo">
            Příjemci vaší zásilky pošleme smsku a email s možnostmi pro
            případnou změnu termínu a místa doručení.
          </Trans>
        ) : destAddressType === 'PARCEL_SHOP' ? (
          <Trans id="order.thankYou.paragraph.recipientSmsInfo.shop">
            Příjemci vaší zásilky dáme vědět, až bude zásilka připravená k
            vyzvednutí.
          </Trans>
        ) : (
          <Trans id="order.thankYou.paragraph.recipientSmsInfo.box">
            Příjemci vaší zásilky dáme vědět, až bude zásilka připravená k
            vyzvednutí a napíšeme kód pro otevření schránky DPD boxu.
          </Trans>
        )}
      </IconParagraph>
    </>
  );
};
