import { Nowrap, Typography, TypographyType } from '@hp/atomic';
import { MediaQueryJS } from '@hp/layout';
import { colors } from '@hp/theme';
import { hasFeature } from '@hp/utils';
import { Trans } from '@lingui/react';
import React from 'react';

import { Heading, Subheading } from './styled';

export const Headings = () => {
  return (
    <>
      <MediaQueryJS>
        {(isMobileScreen) => (
          <>
            <Heading>
              <Trans id="homepage.heading.part1">
                <Typography
                  type={
                    isMobileScreen
                      ? TypographyType.Heading1Light
                      : TypographyType.Heading1ExtraLight
                  }
                  color={colors.black}
                >
                  Posílejte balíky
                </Typography>
              </Trans>

              {isMobileScreen ? <br /> : <> </>}

              <Trans id="homepage.heading.part2">
                <Typography
                  type={
                    isMobileScreen
                      ? TypographyType.Heading1Light
                      : TypographyType.Heading1ExtraLight
                  }
                  color={colors.red_main}
                >
                  z ruky do ruky
                </Typography>
              </Trans>
            </Heading>

            <Subheading>
              <Typography type={TypographyType.BodyLight}>
                {hasFeature('v3') ? (
                  <Trans id="homepage.subheading.v3">
                    Všechno, co je třeba, zadáte jednoduše online. Odesílat
                    můžete <Nowrap>z 900</Nowrap> podacích míst nebo přímo{' '}
                    <Nowrap>z domova.</Nowrap> Ať už posíláte rodině, kupci{' '}
                    <Nowrap>z bazaru</Nowrap> nebo vracíte objednávku{' '}
                    <Nowrap>z e-shopu.</Nowrap>
                  </Trans>
                ) : (
                  <Trans id="homepage.subheading">
                    Zásilku u vás vyzvedneme <Nowrap>a spolehlivě</Nowrap> ji
                    doručíme. Ať už posíláte rodině, kupci{' '}
                    <Nowrap>z bazaru</Nowrap> nebo vracíte objednávku{' '}
                    <Nowrap>z e-shopu.</Nowrap>
                  </Trans>
                )}
              </Typography>
            </Subheading>
          </>
        )}
      </MediaQueryJS>
    </>
  );
};
