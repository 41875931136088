import { Button, ChevronNavIcon, H2 } from '@hp/atomic';
import { Column } from '@hp/layout';
import { breakpoints, colors, elements, spacing } from '@hp/theme';
import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: ${spacing.m};
  justify-content: space-between;

  @media (min-width: ${breakpoints.minDesktop}) {
    flex-wrap: nowrap;
  }
`;

export const CountryColumn = styled(Column)`
  min-width: 280px;
`;

export const MultipackageRow = styled(Row)`
  @media (min-width: ${breakpoints.minDesktop}) {
    max-width: 140px;
    padding: 20px 0 0;
  }

  @media (min-width: ${breakpoints.bigDesktop}) {
    max-width: 180px;
    padding: 20px 0 0;
  }
`;

export const HowToSendSection = styled.section`
  @media (min-width: ${breakpoints.minDesktop}) {
  }
`;

export const Divider = styled(ChevronNavIcon)`
  display: flex;
  width: 22px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

type PriceStyledProps = {
  isHoverState?: boolean;
};

export const PriceStyled = styled.div<PriceStyledProps>`
  height: ${elements.buttonHeightSmall};
  display: flex;
  background: ${({ isHoverState }) =>
    isHoverState
      ? colors.red_dark
      : 'linear-gradient(54.24deg, #b80951 6.62%, #ed1944 51.31%)'};
  align-items: center;
  justify-content: center;
  margin-top: ${spacing.xs};
`;

export const BoxIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: ${spacing.xxs} auto -10px;
`;

export const BoxIconButton = styled(Button)`
  padding-top: 12px;

  @media (min-width: ${breakpoints.minDesktop}) {
    margin-left: 4px;
    margin-right: 4px;
    max-width: 180px;
  }

  @media (min-width: ${breakpoints.bigDesktop}) {
    margin-left: 8px;
    margin-right: 8px;
    max-width: 180px;
  }
`;

export const MultiPackageButton = styled(Button)`
  justify-content: flex-start;
  text-align: left;
  text-transform: none;
`;

export const H2Styled = styled(H2)`
  width: fit-content;
`;

export const CenteredSpan = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
`;
