import { Inner } from '@hp/layout';
import React from 'react';

import { PrivacyPolicyContent } from '../content/PrivacyPolicy';

export const PrivacyPolicy = () => {
  return (
    <Inner>
      <PrivacyPolicyContent />
    </Inner>
  );
};
