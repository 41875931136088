import {
  CourierColorsIcon,
  HandColorsIcon,
  InfoBox,
  Link,
  Strong,
} from '@hp/atomic';
import { Country } from '@hp/core/shared';
import { useI18n } from '@hp/locale';
import { pickupDateOptions } from '@hp/utils';
import { Trans } from '@lingui/macro';
import React, { FC } from 'react';

import { usePickupTimeQuery } from '../../graphql';
import { IconParagraph } from './IconParagraph';

type CourierPickupProps = {
  number?: string;
  sendWhen: string;
  sendFromCountry: Country;
  pickupPostalCode: string;
};

export const CourierPickup: FC<CourierPickupProps> = ({
  number,
  sendFromCountry,
  sendWhen,
  pickupPostalCode,
  children,
}) => {
  const i18n = useI18n();
  const pickupOptions = pickupDateOptions(sendFromCountry, i18n);
  const data = usePickupTimeQuery({ variables: { code: pickupPostalCode } });
  const { fromHour, toHour } = data?.data?.pickupTime ?? {
    fromHour: 8,
    toHour: 17,
  };

  const selectedDateOption =
    pickupOptions.find((x) => x.value === sendWhen) || pickupOptions[0];

  return (
    <InfoBox>
      <IconParagraph icon={<CourierColorsIcon />}>
        <Trans id="order.thankYou.deliveryNote.courier">
          Kurýr vyzvedne zásilku číslo <Strong>{number}</Strong> na uvedené
          adrese <Strong>{selectedDateOption.label}</Strong>. Předpokládaný
          příjezd je mezi {fromHour}:00-{toHour}:00 hodinou.{' '}
          <Strong>
            Jednohodinový interval vám ještě upřesníme ráno v den vyzvednutí.
          </Strong>
        </Trans>
      </IconParagraph>

      <IconParagraph icon={<HandColorsIcon />}>
        <Trans id="order.thankYou.paragraph.label">
          Vytištěný přepravní štítek přiveze kurýr, na vás je pouze{' '}
          <Link
            href="https://www.dpd.com/cz/cs/podpora/podminky-prepravy/jak-spravne-zabalit-zasilky/"
            target="_blank"
          >
            zabalení zásilky
          </Link>
          .
        </Trans>
      </IconParagraph>

      {children}
    </InfoBox>
  );
};
