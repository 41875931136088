import {
  ArrowIcon,
  Button,
  ButtonType,
  ConditionalWrapper,
  H2,
  Hr,
  Note,
  NoteItem,
  QRCodeIcon,
  Strong,
  TimerColorsIcon,
  Typography,
  TypographyType,
} from '@hp/atomic';
import { Money } from '@hp/components';
import { useConfig } from '@hp/config';
import { getSpecificCfg } from '@hp/core/shared';
import { PickupType } from '@hp/core/shared/graphql/generated/types';
import {
  CheckboxField,
  Error,
  FormBackButton,
  FormWrapper,
  SearchField,
  SelectField,
  SwitchButtonField,
} from '@hp/form';
import { CenteredRow, MediaQueryJS, SpaceBetweenRow } from '@hp/layout';
import { labels, placeholders } from '@hp/locale';
import { Modal } from '@hp/modal';
import { colors, elements } from '@hp/theme';
import {
  addDataLayerItem,
  hasFeature,
  preventDefaultOnEnter,
  useObjectLocalStorage,
} from '@hp/utils';
import { Trans } from '@lingui/react';
import { flag } from 'country-emoji';
import countryName from 'country-name';
import { nameOf, NameOfPath } from 'easy-tsnameof';
import React, { useEffect, useState } from 'react';

import { sharedKeys } from '../../sharedKeys';
import { FormContentWrapper } from '../../styled';
import {
  HowToSendFormFields,
  ParcelFormProps,
  PickupFormType,
} from '../../types';
import { computePrice } from '../../utils';
import { computeDistance } from './computeDistance';
import { ParcelShopItem } from './ParcelShopItem';
import { ParcelShopItemDetail } from './ParcelShopItemDetail';
import { ParcelShopList } from './ParcelShopList';
import {
  ChevronNavIconStyled,
  FiltersWrapper,
  ModalContentWrapper,
  ParcelShopListWrapper,
  SearchBox,
  SearchBoxRow,
  SearchFieldRefWrapper,
} from './styled';
import { useDelivery } from './useDelivery';

const formId = 'deliveryForm';

export const Delivery = () => {
  const [eventsShouldBePushed, setEventsShouldBePushed] = useState(false);
  const [howToSend, saveHowToSend] = useObjectLocalStorage<HowToSendFormFields>(
    'howToSend',
  );
  const [parcel] = useObjectLocalStorage<ParcelFormProps>('parcel');

  const { config } = useConfig();

  const {
    formik,
    formikRef,
    pickup,
    delivery,
    deliveryDate,
    pickupOptionsWithDayNames,
    selectedDateOption,
    language,
    showAbroad,
    showDeliverySwitch,
    showPickupSwitch,
    hasL,
    hasXL,
    isMulti,
    supportedPickups,
  } = useDelivery(howToSend, parcel, saveHowToSend);

  useEffect(() => {
    if (!eventsShouldBePushed) {
      const t = setTimeout(() => setEventsShouldBePushed(true), 500);
      return () => clearTimeout(t);
    }
  }, []);

  useEffect(() => {
    if (eventsShouldBePushed) {
      if (formik?.values?.pickupFilter?.filter?.openOnWeekends) {
        addDataLayerItem({
          event: 'sender_open_on_weekends_on',
        });
      } else {
        addDataLayerItem({
          event: 'sender_open_on_weekends_off',
        });
      }
    }
  }, [formik?.values?.pickupFilter?.filter?.openOnWeekends]);

  useEffect(() => {
    if (eventsShouldBePushed) {
      if (formik?.values?.pickupFilter?.filter?.openInLateAfternoon) {
        addDataLayerItem({
          event: 'sender_open_in_late_afternoon_on',
        });
      } else {
        addDataLayerItem({
          event: 'sender_open_in_late_afternoon_off',
        });
      }
    }
  }, [formik?.values?.pickupFilter?.filter?.openInLateAfternoon]);

  useEffect(() => {
    if (eventsShouldBePushed) {
      if (formik?.values?.deliveryFilter?.filter?.openOnWeekends) {
        addDataLayerItem({
          event: 'recipient_open_on_weekends_on',
        });
      } else {
        addDataLayerItem({
          event: 'recipient_open_on_weekends_off',
        });
      }
    }
  }, [formik?.values?.deliveryFilter?.filter?.openOnWeekends]);

  useEffect(() => {
    if (eventsShouldBePushed) {
      if (formik?.values?.deliveryFilter?.filter?.openInLateAfternoon) {
        addDataLayerItem({
          event: 'recipient_open_in_late_afternoon_on',
        });
      } else {
        addDataLayerItem({
          event: 'recipient_open_in_late_afternoon_off',
        });
      }
    }
  }, [formik?.values?.deliveryFilter?.filter?.openInLateAfternoon]);

  useEffect(() => {
    if (eventsShouldBePushed) {
      if (formik?.values?.deliveryFilter?.filter?.cardPaymentAllowed) {
        addDataLayerItem({
          event: 'recipient_cod_card_payment_on',
        });
      } else {
        addDataLayerItem({
          event: 'recipient_cod_card_payment_off',
        });
      }
    }
  }, [formik?.values?.deliveryFilter?.filter?.cardPaymentAllowed]);

  const formContent = (
    <FormContentWrapper>
      <FormWrapper
        formik={formik}
        flex
        marginBottomSize="xl"
        disableAutoComplete
        onKeyDown={preventDefaultOnEnter}
      >
        {/* if you pray, turning off autocomplete will work */}
        <input type="hidden" value="prayer" />

        <SearchBoxRow>
          <ConditionalWrapper
            condition={showDeliverySwitch || showAbroad}
            wrapper={(children) => (
              <SearchBox withShadow={pickup.listOpen}>{children}</SearchBox>
            )}
          >
            {supportedPickups.includes(PickupFormType.PARCELSHOP_OR_BOX) && (
              <H2 marginSize="zero">
                <Typography type={TypographyType.BodyLight}>
                  <Trans id="delivery.heading.pickup">
                    Kde nám zásilku předáte
                  </Trans>
                </Typography>
              </H2>
            )}
            {showPickupSwitch && (
              <SwitchButtonField<PickupFormType>
                formId={formId}
                name="pickupFormType"
                extraMarginBottom
                type="chip"
              >
                {[
                  {
                    value: PickupFormType.PARCELSHOP_OR_BOX,
                    disabled: formik.isSubmitting,
                    content: () => (
                      <Typography
                        type={TypographyType.BodySmall}
                        textAlign="center"
                      >
                        {hasFeature('allowLockers') ? (
                          <Trans id="delivery.pickupType.parcelShopOrBox">
                            Pobočka / Box
                          </Trans>
                        ) : (
                          <Trans id="delivery.pickupType.parcelShop">
                            Pobočka
                          </Trans>
                        )}
                      </Typography>
                    ),
                    testId: 'delivery.pickupType.parcelShop',
                  },
                  {
                    value: PickupFormType.COURIER,
                    disabled: formik.isSubmitting,
                    content: () => (
                      <Typography
                        type={TypographyType.BodySmall}
                        textAlign="center"
                      >
                        <Trans id="delivery.pickupType.address">
                          Adresa +
                          <Money currency="CZK">
                            {
                              getSpecificCfg(
                                config.priceList,
                                formik.values.sendToCountry,
                              ).courierPickUpShipment
                            }
                          </Money>
                        </Trans>
                      </Typography>
                    ),
                    testId: 'delivery.pickupType.address',
                  },
                ]}
              </SwitchButtonField>
            )}

            {formik.values.pickupFormType ===
            PickupFormType.PARCELSHOP_OR_BOX ? (
              <>
                {pickup.selectedItem ? (
                  <ParcelShopListWrapper selected>
                    <ParcelShopItem
                      item={pickup.selectedItem}
                      onClick={() => pickup.setOpenItemDetail(true)}
                      onEditClick={() => pickup.setSelectedItem(null)}
                      selected
                      mode="pickup"
                    />
                  </ParcelShopListWrapper>
                ) : (
                  <>
                    <SearchFieldRefWrapper ref={pickup.refs.search}>
                      <SearchField<HowToSendFormFields>
                        height={elements.inputHeight}
                        extraTopPadding={6}
                        formId={formId}
                        name={
                          nameOf<HowToSendFormFields>(
                            (f: HowToSendFormFields) =>
                              f.pickupFilter.addressText,
                          ) as NameOfPath<HowToSendFormFields>
                        }
                        autoComplete="off"
                        placeholderDescriptor={placeholders.search}
                        disabled={formik.isSubmitting}
                        onFocus={() =>
                          pickup.focusHandler(
                            formik.values.pickupFilter?.filter,
                            delivery.setListOpen,
                          )
                        }
                        onChange={() =>
                          pickup.changeHandler(formikRef, delivery.setListOpen)
                        }
                        ref={pickup.refs.input}
                      />
                      {formik.touched.pickupFilter?.addressText &&
                        pickup.formError && <Error>{pickup.formError}</Error>}
                    </SearchFieldRefWrapper>
                  </>
                )}
                {pickup.listOpen && (
                  <ParcelShopListWrapper ref={pickup.refs.list}>
                    <FiltersWrapper>
                      <CheckboxField<HowToSendFormFields>
                        formId={formId}
                        name={
                          nameOf<HowToSendFormFields>(
                            (f: HowToSendFormFields) =>
                              f.pickupFilter.filter.openOnWeekends,
                          ) as NameOfPath<HowToSendFormFields>
                        }
                        badge
                        label={
                          <Trans id="common.filter.openOnWeekends">
                            otevřeno o víkendu{' '}
                          </Trans>
                        }
                        disabled={formik.isSubmitting}
                      />
                      <CheckboxField<HowToSendFormFields>
                        formId={formId}
                        name={
                          nameOf<HowToSendFormFields>(
                            (f: HowToSendFormFields) =>
                              f.pickupFilter.filter.openInLateAfternoon,
                          ) as NameOfPath<HowToSendFormFields>
                        }
                        badge
                        label={
                          <Trans id="common.filter.openInLateAfternoon">
                            otevřeno po 17:00
                          </Trans>
                        }
                        disabled={formik.isSubmitting}
                      />
                    </FiltersWrapper>
                    <ParcelShopList
                      formik={formik}
                      isLoading={pickup.loading}
                      type="pickup"
                    >
                      {pickup.items?.map((item) => (
                        <ParcelShopItem
                          key={item.id}
                          item={item}
                          onClick={() => {
                            pickup.setOpenItem(item);
                            pickup.setOpenItemDetail(true);
                          }}
                          distance={computeDistance(
                            formik.values.pickupFilter?.filter,
                            item.location?.coordinates,
                          )}
                          mode="pickup"
                        />
                      ))}
                    </ParcelShopList>
                  </ParcelShopListWrapper>
                )}
              </>
            ) : (
              <SelectField<HowToSendFormFields>
                formId={formId}
                name="sendWhen"
                label={labels.howToSend.sendWhen}
                options={pickupOptionsWithDayNames}
                noCheckmark
                data-test="homepage.input.sendWhen"
                disabled={formik.isSubmitting}
              />
            )}
          </ConditionalWrapper>

          {showDeliverySwitch && (
            <SearchBox withShadow={delivery.listOpen}>
              <H2 marginSize="zero">
                <Typography type={TypographyType.BodyLight}>
                  <Trans id="delivery.heading.delivery">
                    Kam budeme doručovat
                  </Trans>
                </Typography>
              </H2>

              <SwitchButtonField<PickupFormType>
                formId={formId}
                name="deliveryFormType"
                extraMarginBottom
                type="chip"
              >
                {[
                  {
                    value: PickupFormType.PARCELSHOP_OR_BOX,
                    disabled: formik.isSubmitting,
                    content: () => (
                      <Typography
                        type={TypographyType.BodySmall}
                        textAlign="center"
                      >
                        {hasFeature('allowLockers') ? (
                          <Trans id="delivery.pickupType.parcelShopOrBox">
                            Pobočka / Box
                          </Trans>
                        ) : (
                          <Trans id="delivery.pickupType.parcelShop">
                            Pobočka
                          </Trans>
                        )}
                      </Typography>
                    ),
                    testId: 'delivery.deliveryType.parcelShop',
                  },
                  {
                    value: PickupFormType.COURIER,
                    disabled: formik.isSubmitting,
                    content: () => (
                      <Typography
                        type={TypographyType.BodySmall}
                        textAlign="center"
                      >
                        <Trans id="delivery.deliveryType.address">
                          Adresa +
                          <Money currency="CZK">
                            {
                              getSpecificCfg(
                                config.priceList,
                                formik.values.sendToCountry,
                              ).courierDeliveryShipment
                            }
                          </Money>
                        </Trans>
                      </Typography>
                    ),
                    testId: 'delivery.deliveryType.address',
                  },
                ]}
              </SwitchButtonField>

              {formik.values.deliveryFormType ===
                PickupFormType.PARCELSHOP_OR_BOX && (
                <>
                  {delivery.selectedItem ? (
                    <ParcelShopListWrapper selected>
                      <ParcelShopItem
                        item={delivery.selectedItem}
                        onEditClick={() => delivery.setSelectedItem(null)}
                        selected
                        mode="delivery"
                      />
                    </ParcelShopListWrapper>
                  ) : (
                    <>
                      <SearchFieldRefWrapper ref={delivery.refs.search}>
                        <SearchField<HowToSendFormFields>
                          height={elements.inputHeight}
                          extraTopPadding={6}
                          formId={formId}
                          name={
                            nameOf<HowToSendFormFields>(
                              (f: HowToSendFormFields) =>
                                f.deliveryFilter.addressText,
                            ) as NameOfPath<HowToSendFormFields>
                          }
                          autoComplete="off"
                          placeholderDescriptor={placeholders.search}
                          disabled={formik.isSubmitting}
                          onFocus={() =>
                            delivery.focusHandler(
                              formik.values.deliveryFilter?.filter,
                              pickup.setListOpen,
                            )
                          }
                          onChange={() =>
                            delivery.changeHandler(
                              formikRef,
                              pickup.setListOpen,
                            )
                          }
                          ref={delivery.refs.input}
                        />
                        {formik.touched.deliveryFilter?.addressText &&
                          delivery.formError && (
                            <Error>{delivery.formError}</Error>
                          )}
                      </SearchFieldRefWrapper>
                    </>
                  )}

                  {delivery.listOpen && (
                    <ParcelShopListWrapper ref={delivery.refs.list}>
                      <FiltersWrapper>
                        <CheckboxField<HowToSendFormFields>
                          formId={formId}
                          name={
                            nameOf<HowToSendFormFields>(
                              (f: HowToSendFormFields) =>
                                f.deliveryFilter.filter.openOnWeekends,
                            ) as NameOfPath<HowToSendFormFields>
                          }
                          badge
                          label={
                            <Trans id="common.filter.openOnWeekends">
                              otevřeno o víkendu{' '}
                            </Trans>
                          }
                          disabled={formik.isSubmitting}
                        />
                        <CheckboxField<HowToSendFormFields>
                          formId={formId}
                          name={
                            nameOf<HowToSendFormFields>(
                              (f: HowToSendFormFields) =>
                                f.deliveryFilter.filter.openInLateAfternoon,
                            ) as NameOfPath<HowToSendFormFields>
                          }
                          badge
                          label={
                            <Trans id="common.filter.openInLateAfternoon">
                              otevřeno po 17:00
                            </Trans>
                          }
                          disabled={formik.isSubmitting}
                        />
                        <CheckboxField<HowToSendFormFields>
                          formId={formId}
                          name={
                            nameOf<HowToSendFormFields>(
                              (f: HowToSendFormFields) =>
                                f.deliveryFilter.filter.cardPaymentAllowed,
                            ) as NameOfPath<HowToSendFormFields>
                          }
                          badge
                          label={
                            <Trans id="common.filter.cardPaymentAllowed">
                              platba dobírky kartou
                            </Trans>
                          }
                          disabled={formik.isSubmitting}
                        />
                      </FiltersWrapper>
                      <ParcelShopList
                        formik={formik}
                        isLoading={delivery.loading}
                        type="delivery"
                      >
                        {delivery.items?.map((item) => (
                          <ParcelShopItem
                            distance={computeDistance(
                              formik.values.deliveryFilter?.filter,
                              item.location?.coordinates,
                            )}
                            key={item.id}
                            item={item}
                            onClick={() => {
                              delivery.setOpenItem(item);
                              delivery.setOpenItemDetail(true);
                            }}
                            mode="delivery"
                          />
                        ))}
                      </ParcelShopList>
                    </ParcelShopListWrapper>
                  )}
                </>
              )}
            </SearchBox>
          )}
          {showAbroad && (
            <SearchBox>
              <H2 marginSize="zero">
                <Typography type={TypographyType.BodyLight}>
                  <Trans id="delivery.heading.delivery">
                    Kam budeme doručovat
                  </Trans>
                </Typography>
              </H2>
              {flag(howToSend.sendToCountry)} &nbsp;{' '}
              {countryName(howToSend.sendToCountry, language)}
            </SearchBox>
          )}
        </SearchBoxRow>

        <Hr />

        <SpaceBetweenRow>
          <div>{sharedKeys.price}</div>
          <Money currency="CZK">
            {
              computePrice(
                {
                  howToSend: {
                    ...howToSend,
                    /* these values are sanitazed after submitform, but we need make a fix for compute price */
                    deliveryType:
                      howToSend.deliveryFormType === PickupFormType.COURIER
                        ? PickupType.COURIER
                        : howToSend.deliveryType === PickupType.COURIER
                        ? PickupType.PARCEL_SHOP /* user does not know box or shop*/
                        : howToSend.deliveryType /* user has already selected box or shop */,
                    pickupType:
                      howToSend.pickupFormType === PickupFormType.COURIER
                        ? PickupType.COURIER
                        : howToSend.pickupType === PickupType.COURIER
                        ? PickupType.PARCEL_SHOP /* user does not know box or shop*/
                        : howToSend.pickupType /* user has already selected box or shop */,
                  },
                  parcel,
                },
                config,
              ).total
            }
          </Money>
        </SpaceBetweenRow>

        {formik.touched && (
          <Note marginSize="m">
            {formik.values.pickupFormType === PickupFormType.COURIER ? (
              <>
                <NoteItem icon={<TimerColorsIcon />}>
                  <Trans id="homepage.howToSend.deliveryNote.courier.pickupTime">
                    Pokud u vás zásilku vyzvedneme {selectedDateOption.label},
                    doručíme ji <Strong>{deliveryDate}</Strong>.
                  </Trans>
                </NoteItem>

                <NoteItem icon={<QRCodeIcon />}>
                  <Trans id="delivery.deliveryNote.courier.qrCodeInfo">
                    Přepravní štítek tisknout nemusíte, přiveze ho kurýr. Na vás
                    je pouze zabalení zásilky.
                  </Trans>
                </NoteItem>
              </>
            ) : (
              <>
                {/* <NoteItem icon={<TimerColorsIcon />}>
                  <Trans id="delivery.deliveryNote.parcelShop.pickupTime">
                    Pokud nám zabalenou zásilku přinesete na vybrané podací
                    místo do 12. hodiny v nejbližší pracovní den, měli bychom ji
                    doručit během toho následujícího.
                  </Trans>
                </NoteItem> */}

                <NoteItem icon={<QRCodeIcon />}>
                  <Trans id="delivery.deliveryNote.parcelshop.qrCodeInfo">
                    Přepravní štítek vám vytiskneme na podacím místě.
                  </Trans>
                </NoteItem>
              </>
            )}
          </Note>
        )}

        <SpaceBetweenRow marginSize="m" noBottomMargin>
          <FormBackButton
            to={hasXL || isMulti ? 'orderParcel' : 'landing'}
            type="button"
            className="gaButton gaButtonBack_delivery"
            buttonType={ButtonType.TEXT}
            data-test="delivery.button.back"
          >
            <Trans id="common.button.back">Zpět</Trans>
          </FormBackButton>

          <Button
            bottom
            type="submit"
            className="gaButton gaButtonSubmit_delivery"
            fakeDisabled={!formik.isValid}
            buttonType={ButtonType.PRIMARY}
            icon={
              <ArrowIcon
                color={!formik.isValid ? colors.gray_warm : colors.white}
              />
            }
            animate={formik.isValid}
            data-test="delivery.button.submit"
            onKeyPress={(e) => {
              if (e.key === 'Enter') e.preventDefault();
            }}
          >
            <Trans id="common.button.continue">Pokračovat</Trans>
          </Button>
        </SpaceBetweenRow>
      </FormWrapper>
    </FormContentWrapper>
  );

  return (
    <>
      <MediaQueryJS>
        {(isMobileScreen) => (
          <>
            {isMobileScreen ? (
              <>
                {pickup.openItemDetail &&
                  pickup.openItem &&
                  !delivery.openItemDetail && (
                    <>
                      <SpaceBetweenRow marginSize="m">
                        <div onClick={() => pickup.setOpenItemDetail(false)}>
                          <ChevronNavIconStyled color={colors.black} />
                        </div>
                        {pickup.openItem.type === 'parcelShop' ? (
                          <Trans id="delivery.modalTitle.pickup.shop">
                            Pobočka pro odeslání
                          </Trans>
                        ) : (
                          <Trans id="delivery.modalTitle.pickup.box">
                            Box pro odeslání
                          </Trans>
                        )}

                        <div />
                      </SpaceBetweenRow>

                      <ParcelShopItemDetail
                        item={pickup.openItem}
                        showInfoForParcelL={hasL}
                        onClick={() => {
                          pickup.setOpenItemDetail(false);
                          pickup.setSelectedItem(pickup.openItem);
                          pickup.setListOpen(false);
                          formik.setValues({
                            ...formik.values,
                            pickupType:
                              pickup.openItem.type === 'locker'
                                ? PickupType.LOCKER
                                : PickupType.PARCEL_SHOP,
                            pickupLockerProvider:
                              pickup.openItem.lockerProvider,
                          });
                        }}
                        mode="pickup"
                      />
                    </>
                  )}

                {delivery.openItemDetail &&
                  delivery.openItem &&
                  !pickup.openItemDetail && (
                    <>
                      <SpaceBetweenRow marginSize="m">
                        <div onClick={() => delivery.setOpenItemDetail(false)}>
                          <ChevronNavIconStyled color={colors.black} />
                        </div>
                        {delivery.openItem.type === 'parcelShop' ? (
                          <Trans id="delivery.modalTitle.delivery.shop">
                            Pobočka pro doručení
                          </Trans>
                        ) : (
                          <Trans id="delivery.modalTitle.delivery.box">
                            Box pro doručení
                          </Trans>
                        )}

                        <div />
                      </SpaceBetweenRow>

                      <ParcelShopItemDetail
                        item={delivery.openItem}
                        onClick={() => {
                          delivery.setOpenItemDetail(false);
                          delivery.setSelectedItem(delivery.openItem);
                          delivery.setListOpen(false);
                          formik.setValues({
                            ...formik.values,
                            deliveryType:
                              delivery.openItem.type === 'locker'
                                ? PickupType.LOCKER
                                : PickupType.PARCEL_SHOP,
                            deliveryLockerProvider:
                              delivery.openItem.lockerProvider,
                          });
                        }}
                        mode="delivery"
                      />
                    </>
                  )}

                {!pickup.openItemDetail && !delivery.openItemDetail && (
                  <>{formContent}</>
                )}
              </>
            ) : (
              <>
                {pickup.openItemDetail &&
                  pickup.openItem &&
                  !delivery.openItemDetail && (
                    <Modal
                      onCloseRequest={() => pickup.setOpenItemDetail(false)}
                      desktopMaxWidth={1200}
                    >
                      <CenteredRow>
                        {pickup.openItem.type === 'parcelShop' ? (
                          <Trans id="delivery.modalTitle.pickup.shop">
                            Pobočka pro odeslání
                          </Trans>
                        ) : (
                          <Trans id="delivery.modalTitle.pickup.box">
                            Box pro odeslání
                          </Trans>
                        )}
                      </CenteredRow>

                      <ModalContentWrapper>
                        <ParcelShopItemDetail
                          showInfoForParcelL={hasL}
                          item={pickup.openItem}
                          onClick={() => {
                            pickup.setOpenItemDetail(false);
                            pickup.setSelectedItem(pickup.openItem);
                            pickup.setListOpen(false);
                            formik.setValues({
                              ...formik.values,
                              pickupType:
                                pickup.openItem.type === 'locker'
                                  ? PickupType.LOCKER
                                  : PickupType.PARCEL_SHOP,
                              pickupLockerProvider:
                                pickup.openItem.lockerProvider,
                            });
                          }}
                          mode="pickup"
                        />
                      </ModalContentWrapper>
                    </Modal>
                  )}

                {delivery.openItemDetail &&
                  delivery.openItem &&
                  !pickup.openItemDetail && (
                    <Modal
                      onCloseRequest={() => delivery.setOpenItemDetail(false)}
                      desktopMaxWidth={1200}
                    >
                      <CenteredRow>
                        {delivery.openItem.type === 'parcelShop' ? (
                          <Trans id="delivery.modalTitle.delivery.shop">
                            Pobočka pro doručení
                          </Trans>
                        ) : (
                          <Trans id="delivery.modalTitle.delivery.box">
                            Box pro doručení
                          </Trans>
                        )}
                      </CenteredRow>

                      <ModalContentWrapper>
                        <ParcelShopItemDetail
                          item={delivery.openItem}
                          onClick={() => {
                            delivery.setOpenItemDetail(false);
                            delivery.setSelectedItem(delivery.openItem);
                            delivery.setListOpen(false);
                            formik.setValues({
                              ...formik.values,
                              deliveryType:
                                delivery.openItem?.type === 'locker'
                                  ? PickupType.LOCKER
                                  : PickupType.PARCEL_SHOP,
                              deliveryLockerProvider:
                                delivery.openItem.lockerProvider,
                            });
                          }}
                          mode="delivery"
                        />
                      </ModalContentWrapper>
                    </Modal>
                  )}

                {formContent}
              </>
            )}
          </>
        )}
      </MediaQueryJS>
    </>
  );
};
