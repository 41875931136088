import { useUserContext } from '@hp/context';
import { Address, Profile } from '@hp/core/shared';
import { joinPhone } from '@hp/form/src/atomic/phoneInput/utils';
import { groupBy } from '@hp/utils';

import { useProfileWithAdressessQuery } from '../../graphql';
import {
  UsersProfileSenderAddressFormFragment,
  UsersProfileSenderInvoiceAddressFormFragment,
  UseSavedAddressesType,
} from './types';

const splitAdresses = (
  addresses: Address[],
  selectedAddressId?: string | null,
  selectedInvoiceAddressId?: string | null,
) => {
  const grouped = groupBy(addresses, (a) => a.forInvoicing);
  const allInvoiceAddresses = grouped.get(true) ?? [];
  const allAdresses = grouped.get(false) ?? [];

  return {
    invoiceAddress:
      allInvoiceAddresses?.find((x) => x.id === selectedInvoiceAddressId) ??
      null,
    address: allAdresses?.find((x) => x.id === selectedAddressId) ?? null,
    allInvoiceAddresses,
    allAdresses,
  };
};

const convertAddress = (
  address: Address,
  {
    name: profileName,
    phone: profilePhone,
    phonePrefix: profilePhonePrefix,
  }: Pick<Profile, 'phone' | 'phonePrefix' | 'name'>,
): UsersProfileSenderAddressFormFragment => {
  //todo: zamyslet se co s country
  const {
    city,
    name,
    name2,
    street,
    ico,
    dic,
    phone: phoneMain,
    phonePrefix,
    zip: postalCode,
    type: sender,
  } = address;
  const phone = joinPhone(phonePrefix, phoneMain);
  const form: UsersProfileSenderAddressFormFragment = {
    name: name || name2 || profileName,
    city,
    street,
    ico,
    dic,
    phone: phone || joinPhone(profilePhonePrefix, profilePhone),
    postalCode,
    sender,
  };

  return form;
};

const convertInvoiceAddress = (
  address: Address,
  { name: profileName }: Pick<Profile, 'name'>,
): UsersProfileSenderInvoiceAddressFormFragment => {
  //todo: zamyslet se co s country
  const {
    city: cityInvoice,
    name,
    name2,
    street: streetInvoice,
    ico: icoInvoice,
    dic: dicInvoice,
    zip: postalCodeInvoice,
  } = address;

  const form: UsersProfileSenderInvoiceAddressFormFragment = {
    nameInvoice: name || name2 || profileName,
    cityInvoice,
    streetInvoice,
    icoInvoice,
    dicInvoice,
    postalCodeInvoice,
  };

  return form;
};

/** returns all saved addresses for current user */
export const useSavedAddresses = (): UseSavedAddressesType => {
  const { user } = useUserContext();
  const { data } = useProfileWithAdressessQuery({
    skip: !user,
  });

  if (!data || !user) return {};

  const { profile, addresses } = data;
  const { allAdresses, allInvoiceAddresses } = splitAdresses(addresses);

  return {
    addreses: allAdresses.map((origin) => ({
      origin,
      formData: convertAddress(origin, profile),
    })),
    invoiceAddreses: allInvoiceAddresses.map((origin) => ({
      origin,
      formData: convertInvoiceAddress(origin, profile),
    })),
  };
};
