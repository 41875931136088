import { Typography, TypographyType } from '@hp/atomic';
import React, { FC } from 'react';

import { IconWrapper, ParagraphWrapper } from './styled';
import { IconParagraphProps } from './types';

export const IconParagraph: FC<IconParagraphProps> = ({
  icon,
  marginBottomSize = 'm',
  children,
  ...props
}) => (
  <ParagraphWrapper marginBottomSize={marginBottomSize} {...props}>
    <IconWrapper>{icon}</IconWrapper>
    <Typography type={TypographyType.BodyLight}>{children}</Typography>
  </ParagraphWrapper>
);
