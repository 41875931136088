import { config } from '@hp/config';
import axios from 'axios';
import { useEffect, useState } from 'react';

export const useSearchParcelShop = () => {
  const [items, setItems] = useState(null);
  const [mapCoordinates, setMapCoordinates] = useState({
    latitude: 50.60072,
    longitude: 13.61419,
  });
  const [limit, setLimit] = useState(3);
  const [url, setUrl] = useState(
    `${config.app.parcelShopSearchApiUrl}?address=Mesto%2C+Ulice%2C+PSC&limit=`,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [inThrottle, setInThrottle] = useState(false);

  const increaseLimit = () => {
    setLimit((prev) => (prev < 99 ? prev + 3 : prev));
  };

  const updateSearch = (searchInput: string) => {
    const newUrl = `${config.app.parcelShopSearchApiUrl}?address=${searchInput}&limit=`;
    setUrl(newUrl);
  };

  const fetchData = async () => {
    setIsError(false);
    setIsLoading(true);

    try {
      const result = await axios(url + '' + limit);
      if (result.status === 200) {
        setItems(result?.data?.data?.items.slice(0, limit));
      } else {
        setIsError(true);
      }
    } catch (error) {
      setIsError(true);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (!inThrottle) {
      setInThrottle(true);
      fetchData();
      setTimeout(() => setInThrottle(false), 2000);
    }
  }, [url]);

  useEffect(() => {
    if (!inThrottle) {
      fetchData();
    }
    const t = setTimeout(() => setInThrottle(false), 2000);
    return () => clearTimeout(t);
  }, [inThrottle]);

  useEffect(() => {
    fetchData();
  }, [limit]);

  return {
    items,
    mapCoordinates,
    setMapCoordinates,
    isLoading,
    isError,
    setUrl,
    limit,
    setLimit,
    increaseLimit,
    updateSearch,
  };
};
