import { useUserContext } from '@hp/context';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { useCallback, useEffect } from 'react';

import { useClearLocalStorage } from './useClearLocalStorage';

export const useUserLogoutEvent = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  const { registerHook } = useUserContext();
  const { clearLocalStorage } = useClearLocalStorage();

  const clearLocalStorageForUser = () => {
    client
      .clearStore()
      .then(() => clearLocalStorage())
      .then(() => console.log('user logged out'));
  };
  const cb = useCallback(clearLocalStorageForUser, []);

  useEffect(() => {
    return registerHook('logout', cb);
  }, [cb, registerHook]);
};
