import {
  Button,
  ButtonType,
  HandColorsIcon,
  InfoBox,
  Link,
  LockerIcon,
  ParcelShopColorsIcon,
  QRCodeIcon,
  Strong,
} from '@hp/atomic';
import { useSystemModal } from '@hp/core/src/providers/SystemModal';
import { CenteredRow } from '@hp/layout';
import { addDataLayerItem, downloadFile } from '@hp/utils';
import { Trans } from '@lingui/macro';
import React, { FC } from 'react';

import { useFetchForParcelShopId } from '../../hooks/useFetchForParcelShopIds';
import { useDetailDialog } from '../Delivery/useDetailDialog';
import { IconParagraph } from './IconParagraph';
import { QrCodeWrapper } from './styled';

type ParcelShopPickupProps = {
  number?: string;
  items: Array<{
    parcelNumber: string;
    qrCode: string;
  }>;
  srcPudoId: string;
};

export const ParcelShopPickup: FC<ParcelShopPickupProps> = ({
  number,
  items,
  srcPudoId,
  children,
}) => {
  const { data, loading, error } = useFetchForParcelShopId(srcPudoId);
  const { showErrorInfo } = useSystemModal();
  const { handler } = useDetailDialog();
  const handleClick = () => {
    if (error)
      showErrorInfo(
        <Trans id="order.thankYou.parcelShopLoadingError">
          Data o pobočce/boxu se nepodařilo načíst
        </Trans>,
      );
    else if (loading)
      showErrorInfo(
        <Trans id="order.thankYou.parcelShopLoading">
          Data o pobočce/boxu se stále načítají, opakujte prosím za chvíli.
        </Trans>,
      );
    else {
      handler(data, 'delivery');
    }
  };

  return (
    <InfoBox>
      {!loading && (
        <>
          <IconParagraph
            icon={
              data.type === 'parcelShop' ? (
                <ParcelShopColorsIcon />
              ) : (
                <LockerIcon />
              )
            }
          >
            <Trans id="order.thankYou.paragraph.parcel2">
              Zabalenou zásilku číslo <Strong>{number}</Strong> prosím přineste
              na{' '}
              <Link
                onClick={() => handleClick()}
                className="gaButton gaButtonAction_sendingPlace"
              >
                vybrané podací místo
              </Link>
              .
            </Trans>{' '}
            {data.type === 'locker' && (
              <Trans id="order.thankYou.paragraph.locker">
                Balík můžete do boxu vložit nejdříve za 5 minut od vytvoření
                objednávky.
              </Trans>
            )}
          </IconParagraph>

          <IconParagraph icon={<QRCodeIcon />}>
            {data.type === 'parcelShop' ? (
              <Trans id="order.thankYou.paragraph.qrCode">
                Na pobočce stačí ukázat QR kód, který jsme vám také poslali
                e&#8209;mailem.
              </Trans>
            ) : (
              <Trans id="order.thankYou.paragraph.qrCode.box">
                Na obrazovce boxu najdete instrukce, jak postupovat. Začnete
                naskenováním QR kódu, který jsme vám také poslali e-mailem.
              </Trans>
            )}
          </IconParagraph>

          {items.map(({ parcelNumber, qrCode }, index) => (
            <QrCodeWrapper key={index}>
              <CenteredRow marginSize="m">
                <img
                  src={`data:image/png;base64, ${qrCode}`}
                  alt={`qr code ${index + 1}`}
                />
              </CenteredRow>

              <CenteredRow
                marginSize="m"
                style={{ position: 'relative', top: '-8px' }}
              >
                <Button
                  type="button"
                  className="gaButton gaButtonAction_saveQR"
                  buttonType={ButtonType.TEXT}
                  onClick={(e) => {
                    e.preventDefault();
                    addDataLayerItem({ event: 'qr_download' });
                    downloadFile(`dpd-QR-${parcelNumber}.png`, qrCode);
                  }}
                  data-test="thankYou.button.saveQR"
                >
                  <Trans id="order.thankYou.button.saveQR">
                    Uložit QR kód jako obrázek
                  </Trans>
                </Button>
              </CenteredRow>
            </QrCodeWrapper>
          ))}

          <IconParagraph icon={<HandColorsIcon />}>
            {data.type === 'parcelShop' ? (
              <Trans id="order.thankYou.paragraph.printingIsManagedByShop">
                Tisk a nalepení přepravního štítku zařídí pobočka.
              </Trans>
            ) : (
              <Trans id="order.thankYou.paragraph.printingIsManagedInBox">
                Přepravní štítek si vytisknete přímo v DPD boxu a nalepíte ho na
                zásilku.
              </Trans>
            )}
          </IconParagraph>

          {children}
        </>
      )}
    </InfoBox>
  );
};
