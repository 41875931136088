import { useConfig } from '@hp/config';
import { Country, getSpecificCfg } from '@hp/core/shared';
import { useYup } from '@hp/form';
import { inlineKeys, useI18n } from '@hp/locale';
import {
  SUPPORTED_IBAN_COUNTRIES,
  validateBankNumber,
  validateIban,
  validateIbanCountry,
  validateSwift,
} from '@hp/utils';
import { NumberSchema } from 'yup';

import { AdditionalServicesFormFields } from '../../types';

export const useAdditionalServicesValidationSchema = (
  recipientCountry: Country,
) => {
  const Yup = useYup();
  const i18n = useI18n();
  const { config } = useConfig();

  const {
    constraints: {
      maxInsurance,
      maxOptionalInsurance,
      minCashOnDelivery,
      maxCashOnDelivery,
    },
  } = config;
  const currentMaxCashOnDelivery = getSpecificCfg(
    maxCashOnDelivery,
    recipientCountry,
  );
  const currentMinCashOnDelivery = getSpecificCfg(
    minCashOnDelivery,
    recipientCountry,
  );

  return Yup.object().shape<AdditionalServicesFormFields>({
    paymentOption: Yup.string().required(),
    fragile: Yup.boolean(),
    doNotTip: Yup.boolean(),
    cashOnDelivery: Yup.boolean(),
    insurance: Yup.boolean(),

    //@ts-ignore
    parcelValues: Yup.array().when('insurance', {
      is: (value) => {
        return value === true;
      },
      then: Yup.array().of(
        Yup.object().shape<{ value: number; on: boolean }>({
          value: Yup.number().when(
            'on',
            (on: boolean, schema: NumberSchema<number>) => {
              return on
                ? schema.required().test(
                    'parcelValues',
                    i18n._(inlineKeys.parcelValueValidation.key, {
                      minPrice: new Intl.NumberFormat(i18n.language, {
                        style: 'currency',
                        currency: 'CZK',
                        maximumFractionDigits: 0,
                      }).format(maxInsurance + 1),
                      // When changing the maxPrice, also change the value in:
                      // modules/order/src/components/AdditionalServices/AdditionalServicesForm.tsx
                      maxPrice: new Intl.NumberFormat(i18n.language, {
                        style: 'currency',
                        currency: 'CZK',
                        maximumFractionDigits: 0,
                      }).format(maxOptionalInsurance),
                    }),
                    (value) => {
                      return value
                        ? value > maxInsurance && value <= maxOptionalInsurance
                        : true;
                    },
                  )
                : schema.nullable();
            },
          ),

          on: Yup.boolean().required(),
        }),
      ),
      otherwise: Yup.array().of(
        Yup.object().shape<{ value: number; on: boolean }>({
          value: Yup.number().nullable(),
          on: Yup.boolean(),
        }),
      ),
    }),

    cashOnDeliveryValue: Yup.number().when('cashOnDelivery', {
      is: (value) => value === true,
      then: Yup.number()
        .integer()
        .required()
        .test(
          'cashOnDeliveryValue',
          i18n._(
            recipientCountry === Country.CZ
              ? inlineKeys.cashOnDeliveryValidation.key
              : inlineKeys.cashOnDeliveryValidationSK.key,
          ),
          (value: number) =>
            value >= currentMinCashOnDelivery &&
            value <= currentMaxCashOnDelivery,
        ),
      otherwise: Yup.number().nullable(),
    }),
    accountNumber: Yup.string().when('cashOnDelivery', {
      is: (value) => value === true && recipientCountry === Country.CZ,
      then: Yup.string()
        .required()
        .test(
          'accountNumber',
          i18n._(inlineKeys.accountValidation.key),
          validateBankNumber,
        ),
      otherwise: Yup.string(),
    }),
    bankCode: Yup.string().when('cashOnDelivery', {
      is: (value) => value === true && recipientCountry === Country.CZ,
      then: Yup.string()
        .max(4)
        .matches(/^\d+$/, i18n._(inlineKeys.bankCodeValidation.key))
        .required(),
      otherwise: Yup.string(),
    }),
    iban: Yup.string().when('cashOnDelivery', {
      is: (value) => value === true && recipientCountry !== Country.CZ,
      then: Yup.string()
        .required()
        .test(
          'ibanCountry',
          i18n._(inlineKeys.ibanCountryValidation.key, {
            countries: SUPPORTED_IBAN_COUNTRIES.join(', '),
          }),
          validateIbanCountry,
        )
        .test('iban', i18n._(inlineKeys.ibanValidation.key), validateIban),
      otherwise: Yup.string(),
    }),
    swift: Yup.string().when('cashOnDelivery', {
      is: (value) => value === true && recipientCountry !== Country.CZ,
      then: Yup.string()
        .required()
        .test('swift', i18n._(inlineKeys.swiftValidation.key), validateSwift),
      otherwise: Yup.string(),
    }),
    addPaymentDetails: Yup.boolean(),
    variableSymbol: Yup.string().when('addPaymentDetails', {
      is: (value) => value === true,
      then: Yup.string()
        .max(10)
        .matches(
          /^(\s*|\d+)$/,
          i18n._(inlineKeys.variableSymbolValidation.key),
        ),
    }),
  });
};
