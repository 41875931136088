import {
  AccountSwitchIcon,
  ChevronSmallIcon,
  EnterIcon,
  FillDataIcon,
  H1,
  H2,
  Note,
  PackagingIcon,
} from '@hp/atomic';
import { breakpoints, colors, elements, misc, spacing } from '@hp/theme';
import styled, { css } from 'styled-components';

export const HeaderWrapper = styled.div`
  padding: 0 16px;
`;

export const Heading = styled(H1)`
  margin: 32px 0 10px;

  @media (min-width: ${breakpoints.minDesktop}) {
    text-align: center;
    margin: 72px 0 32px;
  }
`;

export const Subheading = styled.div`
  margin: 0 0 16px;

  @media (min-width: ${breakpoints.minDesktop}) {
    text-align: center;
    max-width: 600px;
    margin: 0 auto 40px;
  }
`;

export const HowItWorksSectionWrapper = styled.div`
  width: 100%;
  background-color: ${colors.background};
`;

export const HowItWorksHeading = styled(H2)`
  margin: 24px 0 16px;
  padding: 0 16px;

  @media (min-width: ${breakpoints.minDesktop}) {
    text-align: center;
    padding: 0;
    margin: ${spacing.l} 0 0;
    font-size: 32px;
    line-height: 40px;
  }
`;

export const HowItWorksWrapper = styled.div`
  padding: 0 4px;
  margin-bottom: 8px;
`;

export const HowItWorksContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: flex-start;
  max-width: ${breakpoints.fullWidth};
  margin: auto;
  padding: 0;

  @media (min-width: ${breakpoints.minDesktop}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: ${spacing.xxl};
  }
`;

export const HowItWorksContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media (min-width: ${breakpoints.minDesktop}) {
    flex-direction: row;
    width: 100%;
  }
`;

export const IconContent = styled.div`
  z-index: 10;
`;

export const Image = styled.img<{ isLeft?: boolean }>`
  position: absolute;
  display: block;
  height: auto;
  z-index: 5;

  ${({ isLeft }) =>
    isLeft
      ? css`
          left: 0;
        `
      : css`
          right: 0;
        `};

  @media (min-width: ${breakpoints.minDesktop}) {
    width: 400px;
  }

  @media (min-width: ${breakpoints.bigDesktop}) {
    width: 500px;
  }

  @media (min-width: ${breakpoints.largeDesktop}) {
    width: 574px;

    ${({ isLeft }) =>
      isLeft
        ? css`
            left: -50px;
          `
        : css`
            right: -50px;
          `};
  }
`;

export const Text = styled.div`
  padding: 4px 4px;
  max-width: 200px;

  @media (min-width: ${breakpoints.minDesktop}) {
    padding: 10px 4px;
  }
`;

export const Divider = styled(ChevronSmallIcon)`
  flex-shrink: 0;
  margin-top: 10px;
`;

export const DividerDesktop = styled(EnterIcon)`
  display: flex;
  flex-shrink: 0;
  color: ${colors.gray_mid};
  margin: ${spacing.l} auto 40px;
  transform: rotate(0deg);
`;

export const Gradient = styled.div`
  width: 100%;
  flex-grow: 1;
  position: relative;

  @media (min-width: ${breakpoints.minDesktop}) {
    width: 100%;
    background: linear-gradient(#ebeff2 25%, white);
    padding-bottom: ${spacing.xl};
  }
`;

export const Columns = styled.div`
  padding: 0 ${spacing.m};

  @media (min-width: ${breakpoints.minDesktop}) {
    display: flex;
  }
`;

export const NoteStyled = styled(Note)`
  @media (min-width: ${breakpoints.minDesktop}) {
    width: calc(100vw - 480px);
    max-width: 393px;
    box-shadow: ${misc.shadow_note};
  }
`;

export const NoteHeading = styled.div`
  margin: ${spacing.s} 0 12px;
`;

const IconCss = css`
  height: 32px;
  width: auto;
  z-index: 10;
  overflow: hidden;

  @media (min-width: ${breakpoints.minDesktop}) {
    height: 64px;
    width: auto;
  }
`;

export const PackagingIconStyled = styled(PackagingIcon)`
  ${IconCss};
`;

export const AccountSwitchIconStyled = styled(AccountSwitchIcon)`
  ${IconCss};
`;

export const FillDataIconStyled = styled(FillDataIcon)`
  ${IconCss};
`;

export const BannerWrapper = styled.div`
  display: none;

  @media (min-width: ${breakpoints.minDesktop}) {
    display: block;
    width: 100%;
    background-color: ${colors.white};
  }
`;

export const Banner = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: ${elements.innerElementMaxWidth};
  margin: auto;

  @media (min-width: ${breakpoints.minDesktop}) {
    width: ${breakpoints.minDesktop};
  }

  @media (min-width: ${breakpoints.bigDesktop}) {
    width: ${breakpoints.bigDesktop};
  }
`;

export const BannerImage = styled.img`
  @media (min-width: ${breakpoints.minDesktop}) {
    width: 250px;
    height: auto;
  }

  @media (min-width: ${breakpoints.bigDesktop}) {
    width: 392px;
    height: auto;
  }
`;

export const BannerText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin: auto auto;
  padding: 0 32px;
  text-align: center;
`;
