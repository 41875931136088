import { Typography, TypographyType, WeightIcon } from '@hp/atomic';
import { ParcelWeightDescription } from '@hp/components';
import { ParcelSpecsList } from '@hp/core/shared';
import { SwitchButtonField } from '@hp/form';
import { colors } from '@hp/theme';
import React, { FC, useEffect } from 'react';

import { ParcelPartDescription } from '../../types';
import { BoxIconWrapper } from './styled';

type WeightSwitchProps = {
  formId: string;
  part: number;
  item: ParcelPartDescription;
  onItemChanged?: (item: ParcelPartDescription) => void;
  specList: ParcelSpecsList;
};

export const WeightSwitch: FC<WeightSwitchProps> = ({
  formId,
  part,
  item,
  onItemChanged,
  specList,
}) => {
  useEffect(() => {
    onItemChanged?.(item);
  }, [item.size]);

  const ranges = specList.XL.ranges;
  const zooms = [0.8, 1, 1.2];

  return (
    <SwitchButtonField<number>
      formId={formId}
      name={`items[${part}].weight`}
      extraMarginBottom
      hasIntValue
    >
      {ranges.map(([, max], i) => {
        const fMax = Math.floor(max);
        return {
          testId: `parcel.input.XLweight.${fMax}`,
          value: fMax,
          content: ({ checked }) => {
            const color = checked ? colors.red_main : colors.gray_mid;
            return (
              <>
                <BoxIconWrapper>
                  <WeightIcon color={color} zoom={zooms[i]} />
                </BoxIconWrapper>

                <Typography
                  type={TypographyType.BodyMicro}
                  textAlign="center"
                  color={color}
                >
                  <ParcelWeightDescription value={max} />
                </Typography>
              </>
            );
          },
        };
      })}
    </SwitchButtonField>
  );
};
