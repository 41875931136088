import {
  ArrowIcon,
  Button,
  ButtonType,
  Hr,
  Typography,
  TypographyType,
} from '@hp/atomic';
import { MapInfo } from '@hp/components';
import { SearchField } from '@hp/form';
import { CenteredRow, Inner, Row, SpaceBetweenRow } from '@hp/layout';
import { placeholders } from '@hp/locale';
import { Modal } from '@hp/modal';
import { colors } from '@hp/theme';
import { Trans } from '@lingui/macro';
import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';

import {
  MapColumn,
  ParcelShopListItem,
  ParcelShopListWrapper,
  SearchColumn,
} from './styled';
import { useSearchParcelShop } from './useSearchParcelShop';

type SearchParcelShopModalProps = {
  visible?: boolean;
  setVisible?: Dispatch<SetStateAction<boolean>>;
};

export const SearchParcelShopModal: FC<SearchParcelShopModalProps> = ({
  visible,
  setVisible,
}) => {
  const onCloseRequest = () => {
    setVisible(false);
  };

  const {
    items,
    mapCoordinates,
    setMapCoordinates,
    increaseLimit,
    updateSearch,
  } = useSearchParcelShop();

  useEffect(() => {
    if (items?.length > 0) {
      setMapCoordinates({
        latitude: items[0].latitude,
        longitude: items[0].longitude,
      });
    }
  }, [items]);

  return (
    visible && (
      <Modal onCloseRequest={onCloseRequest} desktopMaxWidth={808}>
        <CenteredRow>
          <Typography type={TypographyType.BodyMicro} color={colors.black}>
            <Trans id="modal.searchParcelShop.heading">Vyhledat pobočku</Trans>
          </Typography>
        </CenteredRow>
        <Inner paddingY="m" paddingX="m">
          <Row>
            <SearchColumn>
              <CenteredRow>
                <SearchField
                  formId="search"
                  name="search"
                  placeholderDescriptor={placeholders.searchParcelShop}
                  onChange={(e) => updateSearch(e.target.value)}
                />
              </CenteredRow>

              <ParcelShopListWrapper>
                {items?.map((item) => {
                  const address = `${item.street} ${item.house_number}, ${item.city}`;

                  return (
                    <ParcelShopListItem key={item.id}>
                      <SpaceBetweenRow marginSize="xs">
                        <Typography type={TypographyType.BodySmall}>
                          {item.company}
                        </Typography>
                        <Typography type={TypographyType.BodyMicroLight}>
                          180m
                        </Typography>
                      </SpaceBetweenRow>
                      <SpaceBetweenRow marginSize="xs">
                        <Typography type={TypographyType.BodyMicroLight}>
                          Po - Pa, 10 - 17
                        </Typography>
                        <Typography
                          type={TypographyType.BodyMicroLight}
                          color={colors.green}
                        >
                          otevreno
                        </Typography>
                      </SpaceBetweenRow>
                      <SpaceBetweenRow marginSize="xs">
                        <Typography type={TypographyType.BodyMicroLight}>
                          {address}
                        </Typography>
                      </SpaceBetweenRow>
                      <Hr noMargin />
                    </ParcelShopListItem>
                  );
                })}

                <CenteredRow marginSize="m" noBottomMargin>
                  <Button
                    buttonType={ButtonType.TEXT}
                    type="button"
                    className="gaButton gaButtonAction_searchParcelShopModal"
                    onClick={() => increaseLimit()}
                  >
                    <Trans id="common.button.showMore">Zobrazit další</Trans>
                  </Button>
                </CenteredRow>
              </ParcelShopListWrapper>

              <Button
                buttonType={ButtonType.PRIMARY}
                type="button"
                className="gaButton gaButtonAction_searchParcelShopModalContinue"
                icon={<ArrowIcon />}
                animate
              >
                <Trans id="common.button.continue">Pokračovat</Trans>
              </Button>
            </SearchColumn>
            <MapColumn>
              <MapInfo parcelShop={mapCoordinates} alwaysExpanded />
            </MapColumn>
          </Row>
        </Inner>
      </Modal>
    )
  );
};
