import { Button, H2, Hr, Note } from '@hp/atomic';
import { breakpoints, colors, spacing, typography } from '@hp/theme';
import styled from 'styled-components';

export const HeadingV2 = styled(H2)`
  margin-bottom: ${spacing.s};
`;
export const Heading = styled(H2)`
  margin-bottom: ${spacing.s};
  font-size: ${typography.fontSize.body};
  line-height: ${typography.lineHeight.h2};
  font-weight: 400;
`;

export const SubHeading = styled(Heading)`
  font-size: 15px;
  @media (min-width: ${breakpoints.minDesktop}) {
    font-size: 16px;
  }
`;

export const ChangeButton = styled(Button)`
  margin-top: ${spacing.s};
`;

export const SummarySectionWrapper = styled.div`
  margin: 12px 0 24px 0;

  :last-child {
    margin-bottom: ${spacing.xl};
  }

  @media (min-width: ${breakpoints.minDesktop}) {
    :last-child {
      margin-bottom: 24px;
    }
  }
`;

export const HrStyled = styled(Hr)`
  margin-top: ${spacing.m};
  margin-bottom: ${spacing.s};
`;

export const ErrorInfo = styled.div`
  margin-top: ${spacing.m};
  margin-bottom: ${spacing.s};
  border-left: 4px ${colors.error} solid;
  padding-left: ${spacing.m};
`;

export const ModalWrapper = styled.div`
  padding: ${spacing.m};
`;

export const PaymentGateInfo = styled.div`
  padding: ${spacing.m} 0;
`;

export const NoteSummaryStyled = styled(Note)`
  padding: ${spacing.zero} ${spacing.m} ${spacing.m} ${spacing.m};
`;
