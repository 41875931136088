import { CalculatorIcon } from '@hp/atomic';
import { spacing } from '@hp/theme';
import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  margin-top: ${spacing.l};
`;

export const CalculatorIconStyled = styled(CalculatorIcon)`
  height: 28px;
  width: auto;
`;
