import { useConfig } from '@hp/config';
import { useUserContext } from '@hp/context';
import { DynamicConfigRoot, getSpecificCfg, PickupType } from '@hp/core/shared';
import { useRouter } from '@hp/seo';
import { useObjectLocalStorage } from '@hp/utils';

import { useBankCodes } from '../../hooks';
import { useOtherDataLocalStorage } from '../../hooks/useOtherDataLocalStorage';
import {
  AdditionalServicesFormFields,
  HowToSendFormFields,
  ParcelFormProps,
  PaymentOptionType,
  SenderFormFields,
} from '../../types';
import { useSavedBankAccount } from './useSavedBankAccount';

export const emptyValues: (
  config: DynamicConfigRoot,
) => AdditionalServicesFormFields = (_config) => {
  return {
    paymentOption: PaymentOptionType.CARD_FORWARD,
    fragile: false,
    doNotTip: false,
    cashOnDelivery: false,
    insurance: false,
    parcelValues: [],
    cashOnDeliveryValue: null,
    accountNumber: '',
    bankCode: '',
    iban: '',
    swift: '',
    addPaymentDetails: false,
    variableSymbol: '',
  };
};

export const useAdditionalServicesForm = () => {
  const { config } = useConfig();
  const [howToSend] = useObjectLocalStorage<HowToSendFormFields>('howToSend');
  const [parcel] = useObjectLocalStorage<ParcelFormProps>('parcel');
  const [sender] = useObjectLocalStorage<SenderFormFields>('sender');

  const isSupportedCashOnDelivery =
    getSpecificCfg(
      config.constraints.maxCashOnDelivery,
      howToSend.sendToCountry,
    ) > 0;

  const { user } = useUserContext();

  const router = useRouter();

  const bankCodes = useBankCodes();

  const { accountNumber, bankCode } = useSavedBankAccount(user) ?? {};

  const [savedFormData, saveFormData] = useObjectLocalStorage(
    'additionalServices',
    emptyValues(config),
  );

  // adjust parcelValues length according to parcel count
  const parcelValues =
    parcel.items.length === savedFormData?.parcelValues?.length
      ? savedFormData.parcelValues
      : parcel.items.map(() => ({ value: null, on: false }));

  if (parcelValues.length === 1) parcelValues[0].on = true;

  const [other, , utils] = useOtherDataLocalStorage();

  const formData: AdditionalServicesFormFields =
    accountNumber && !other.bankAccountDataWasOverridenByUser
      ? {
          ...savedFormData,
          parcelValues,
          accountNumber,
          bankCode,
        }
      : { ...savedFormData, parcelValues };

  if (!isSupportedCashOnDelivery && formData.cashOnDelivery) {
    formData.cashOnDelivery = false;
  }

  if (sender?.pickup !== PickupType.COURIER) {
    formData.fragile = false;
    formData.doNotTip = false;
  }

  const handleSubmit = async (values: AdditionalServicesFormFields) => {
    saveFormData(values);
    const bankAccountDataWasOverridenByUser =
      accountNumber &&
      (bankCode !== values.bankCode || accountNumber !== values.accountNumber);

    //cleaning previous order's data
    utils.cleanPreviousOrder({ bankAccountDataWasOverridenByUser });

    router.push('orderSummary');
  };

  return {
    saveFormData,
    handleSubmit,
    initialValues: formData,
    bankCodes,
    isSupportedCashOnDelivery,
    howToSend,
    emptyValues: emptyValues(config),
  };
};
