import {
  BoxIcon,
  Button,
  ButtonType,
  CloseIcon,
  H2,
  Typography,
  TypographyType,
} from '@hp/atomic';
import { ParcelWeightDescription } from '@hp/components';
import { useConfig } from '@hp/config';
import {
  Country,
  getSpecificCfg,
  ParcelSize,
  ParcelSpecsList,
  PickupType,
} from '@hp/core/shared';
import { SwitchButtonField } from '@hp/form';
import { colors } from '@hp/theme';
import { hasFeature } from '@hp/utils';
import { Trans } from '@lingui/macro';
import { NameOfPath } from 'easy-tsnameof';
import React, { useEffect } from 'react';

import { ParcelPartDescription } from '../../types';
import {
  BoxIconWrapper,
  CloseIconWrapper,
  ParcelPartNumber,
  ParcelWeightContainer,
  WeightSwitchContainer,
} from './styled';
import { WeightSwitch } from './WeightSwitch';

type ParcelWeightFormContentProps<T> = {
  formId: string;
  part: number;
  name: NameOfPath<T>;
  item: ParcelPartDescription;
  pickupType: PickupType;
  sendFromCountry: Country;
  sendToCountry: Country;
  removePart?: () => void;
  onItemChanged?: (item: ParcelPartDescription) => void;
  disabled?: boolean;
  isMuplipleMode: boolean;
};

export const getParcelWeightItem = (
  size: ParcelSize,
  part: number,
  disabled: boolean,
  specList: ParcelSpecsList,
) => {
  return {
    value: size,
    disabled: disabled,
    content: ({ checked, value }) => (
      <>
        <BoxIconWrapper>
          <BoxIcon active={checked} zoom={specList[size].iconZoom || 1} />
        </BoxIconWrapper>
        <Typography
          type={TypographyType.BodySmall}
          textAlign="center"
          color={checked ? colors.red_main : colors.black}
        >
          {value}
        </Typography>
        <Typography
          type={TypographyType.BodyMicroLight}
          textAlign="center"
          color={checked ? colors.red_main : colors.black}
        >
          <ParcelWeightDescription size={size} specList={specList} />
        </Typography>
      </>
    ),
    testId: `parcel.package${part + 1}.${size}`,
  };
};

export const ParcelWeightFormContent: <T>(
  props: ParcelWeightFormContentProps<T>,
) => JSX.Element = ({
  formId,
  part,
  item,
  removePart,
  onItemChanged,
  disabled,
  sendToCountry,
  isMuplipleMode,
}) => {
  const { config } = useConfig();

  useEffect(() => {
    onItemChanged?.(item);
  }, [item.size]);
  const specList = getSpecificCfg(config.parcelSpecifications, sendToCountry);

  return (
    <ParcelWeightContainer>
      <H2>
        {!removePart ? (
          <>
            {!hasFeature('v3') && (
              <Trans id="order.parcel.shipmentWeight">Hmotnost</Trans>
            )}
          </>
        ) : (
          <Trans id="order.parcel.shipmentWeight.mps">
            Hmotnost balíku č.{' '}
          </Trans>
        )}
        {removePart && <ParcelPartNumber>{part + 1}</ParcelPartNumber>}
      </H2>

      {removePart && (
        <CloseIconWrapper>
          <Button
            buttonType={ButtonType.TEXT}
            onClick={removePart}
            type="button"
            className={`gaButton gaButtonAction_package${part + 1}`}
            data-test={`parcel.package${part + 1}.button.cancel`}
            disabled={disabled}
          >
            <CloseIcon />
          </Button>
        </CloseIconWrapper>
      )}

      {(!hasFeature('v3') || isMuplipleMode) && (
        <SwitchButtonField<ParcelSize>
          formId={formId}
          name={`items[${part}].size`}
          extraMarginBottom
        >
          {Object.keys(specList).map((size: ParcelSize) =>
            getParcelWeightItem(size, part, disabled, specList),
          )}
        </SwitchButtonField>
      )}

      {!!specList[item.size].ranges?.length && (
        <WeightSwitchContainer>
          <WeightSwitch
            formId={formId}
            item={item}
            part={part}
            specList={specList}
          />
        </WeightSwitchContainer>
      )}
    </ParcelWeightContainer>
  );
};
