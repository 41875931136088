import { Button, ButtonType, H1, Typography, TypographyType } from '@hp/atomic';
import { EcoBanner, PromoBanner } from '@hp/components';
import { useUserContext } from '@hp/context';
import { PayUStatus } from '@hp/core/shared';
import { CenteredRow } from '@hp/layout';
import { useRouter } from '@hp/seo';
import { colors } from '@hp/theme';
import { addDataLayerItem, hasFeature } from '@hp/utils';
import { Trans } from '@lingui/macro';
import React, { FC, useEffect, useState } from 'react';

import { Required } from '../Required';
import { SearchParcelShopModal } from '../SearchParcelShopModal';
import { CourierPickup } from './CourierPickup';
import { ParcelShopPickup } from './ParcelShopPickup';
import { RecipientNote } from './RecipientNote';
import { Wrapper } from './styled';
import { useThankYou } from './useThankYou';

type ThankYouProps = {
  id?: string;
};

export const ThankYou: FC<ThankYouProps> = ({ id }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const router = useRouter();

  const {
    qrCodes,
    withQrCode,
    sendNewParcel,
    sendNextParcel,
    processingPayment,
    status,
    caseData,
  } = useThankYou(id);

  const { user } = useUserContext();

  useEffect(() => {
    if (caseData?.sender?.type) {
      addDataLayerItem({
        event: 'order_completed',
        orderType: caseData?.sender?.type,
      });
    }
  }, [caseData]);

  return (
    <Required
      data={processingPayment || status === PayUStatus.COMPLETED}
      fallbackRoute="orderSummary"
    >
      {() => {
        return (
          <Wrapper hasBottomButton={!!user}>
            {!processingPayment && (
              <>
                <H1 marginSize="l" textAlign="center">
                  <Trans id="order.thankYou.title">
                    <Typography
                      type={TypographyType.Heading1Light}
                      color={colors.red_main}
                    >
                      Děkujeme
                    </Typography>
                    <br />
                    <Typography
                      type={TypographyType.Heading1Light}
                      color={colors.black}
                    >
                      za objednávku
                    </Typography>
                  </Trans>
                </H1>

                {caseData && (
                  <>
                    {withQrCode ? (
                      <ParcelShopPickup
                        number={caseData.items[0]?.parcelNumber}
                        srcPudoId={caseData.srcPudoId}
                        items={
                          qrCodes
                            ? caseData.items.map(({ parcelNumber }, i) => {
                                return { parcelNumber, qrCode: qrCodes[i] };
                              })
                            : []
                        }
                      >
                        <RecipientNote
                          recipientStreet={caseData?.recipient?.street}
                          destAddressType={caseData?.destAddress?.type}
                        />
                      </ParcelShopPickup>
                    ) : (
                      <CourierPickup
                        number={caseData.items[0]?.parcelNumber}
                        sendWhen={caseData.sendWhen}
                        sendFromCountry={caseData.sender.country}
                        pickupPostalCode={caseData.sender.postalCode}
                      >
                        <RecipientNote
                          recipientStreet={caseData?.recipient?.street}
                          destAddressType={caseData?.destAddress?.type}
                        />
                      </CourierPickup>
                    )}
                  </>
                )}

                {user && (
                  <CenteredRow marginSize="l">
                    <Button
                      bottom
                      type="button"
                      className="gaButton gaButtonAction_track"
                      buttonType={ButtonType.PRIMARY}
                      onClick={() =>
                        router.push('accountArchiveDetail', null, id)
                      }
                    >
                      <Trans id="thankYou.button.track">Sledovat zásilku</Trans>
                    </Button>
                  </CenteredRow>
                )}

                <CenteredRow marginSize="m">
                  <Typography type={TypographyType.BodySmall}>
                    <Trans id="order.thankYou.sendAnotherPackage.question">
                      Potřebujete odeslat další balík?
                    </Trans>
                  </Typography>
                </CenteredRow>

                <Typography
                  type={TypographyType.BodySmallLight}
                  textAlign="center"
                >
                  <p>
                    <Trans id="order.thankYou.sendAnotherPackage.info">
                      Na základě předchozí objednávky jsme vám předvyplnili
                      údaje.
                    </Trans>
                  </p>
                </Typography>

                {!hasFeature('multiPackage') && (
                  <CenteredRow marginSize="m">
                    <Button
                      type="button"
                      buttonType={ButtonType.SECONDARY}
                      className="gaButton gaButtonAction_sendAgain"
                      data-test="thankYou.button.sendAnotherPackage"
                      onClick={sendNextParcel}
                    >
                      <Trans id="common.button.resend">
                        Opakovat objednávku
                      </Trans>
                    </Button>
                  </CenteredRow>
                )}

                <CenteredRow marginSize="m">
                  <Button
                    type="button"
                    buttonType={ButtonType.SECONDARY}
                    className="gaButton gaButtonAction_sendAnotherPackage"
                    data-test="thankYou.button.sendAnotherPackage"
                    onClick={sendNewParcel}
                  >
                    <Trans id="common.button.sendAnotherPackage">
                      Poslat další balík
                    </Trans>
                  </Button>
                </CenteredRow>

                {user ? (
                  <EcoBanner placement="order" />
                ) : (
                  <PromoBanner placement="order" />
                )}

                {withQrCode && (
                  <SearchParcelShopModal
                    visible={modalVisible}
                    setVisible={setModalVisible}
                  />
                )}
              </>
            )}
          </Wrapper>
        );
      }}
    </Required>
  );
};
