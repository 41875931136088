import {
  Country,
  ParcelSize,
  ParcelSpecsList,
  PickupType,
} from '@hp/core/shared';
import { useFlow } from '@hp/seo';
import { useObjectLocalStorage } from '@hp/utils';
import { FieldArrayRenderProps } from 'formik';

import { ParcelFormProps, ParcelPartDescription } from '../../types';
import { useParcelValidationSchema } from './useParcelValidationSchema';

const createPart: () => ParcelPartDescription = () => ({
  size: ParcelSize.S,
  weight: null,
});

export const initialValues: ParcelFormProps = {
  items: [createPart()],
};

export const useParcel = (pickupType: PickupType, targetCountry: Country) => {
  const flow = useFlow();
  const [formData, saveFormData] = useObjectLocalStorage(
    'parcel',
    initialValues,
  );

  const validationSchema = useParcelValidationSchema(pickupType, targetCountry);

  const handleSubmit = (values: ParcelFormProps) => {
    saveFormData(values);
    flow.nextPage();
  };

  const addPart = (arrayHelpers: FieldArrayRenderProps) => {
    arrayHelpers.push(createPart());
  };

  const removePart = (
    arrayHelpers: FieldArrayRenderProps,
    partIndex: number,
  ) => {
    arrayHelpers.remove(partIndex);
  };

  const correctPart = (
    arrayHelpers: FieldArrayRenderProps,
    part: number,
    item: ParcelPartDescription,
    specList: ParcelSpecsList,
  ) => {
    const { minWeight, maxWeight, ranges } = specList[item.size];
    const hasRanges = !!ranges?.length;

    arrayHelpers.replace(part, {
      ...item,
      weight: hasRanges
        ? item.weight && item.weight < maxWeight && item.weight >= minWeight
          ? item.weight
          : ranges[0][1]
        : null,
    });
  };

  return {
    initialValues: formData,
    saveFormData,
    handleSubmit,
    validationSchema,
    addPart,
    removePart,
    correctPart,
  };
};
