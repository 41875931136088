import { useUserContext } from '@hp/context';
import { Country, PickupType } from '@hp/core/shared';
import { useState } from 'react';
import { ObjectSchema, ValidationError } from 'yup';

import { useAdditionalServicesValidationSchema } from '../components/AdditionalServices/useAdditionalServicesValidationSchema';
import { useHowToSendValidationSchema } from '../components/HowToSendForm/useHowToSendValidationSchema';
import { useParcelValidationSchema } from '../components/Parcel/useParcelValidationSchema';
import { useRecipientValidationSchema } from '../components/Recipient/useRecipientValidationSchema';
import { useSenderValidationSchema } from '../components/Sender/useSenderValidationSchema';
import { AllFormsData } from '../types';

export enum ErrorInAllOrder {
  before,
  myself,
  after,
}

type ValidationErrorDescription = {
  errorInAllOrder: ErrorInAllOrder;
  error: ValidationError | null;
};

type ValidationResult = Partial<
  Record<keyof AllFormsData, ValidationErrorDescription>
> & {
  validated: boolean;
  validationStarted: boolean;
};

export const useAllFormsDataValidation = (
  allFormsData: AllFormsData | null,
  options?: { skip?: boolean },
) => {
  const [result, setResult] = useState<ValidationResult>({
    validated: false,
    validationStarted: false,
  });

  const { user, loading } = useUserContext();
  const skip = options?.skip || loading;
  const { howToSend, parcel, recipient, sender, additionalServices } =
    allFormsData ?? {};

  const countryTo = skip ? Country.CZ /*anything*/ : howToSend.sendToCountry;
  const countryFrom = skip
    ? Country.CZ /*anything*/
    : howToSend.sendFromCountry;
  const pickup = skip ? PickupType.COURIER /*anything*/ : howToSend.pickupType;

  const howToSendValSchema = useHowToSendValidationSchema(true);
  const parcelValSchema = useParcelValidationSchema(pickup, countryTo);
  const senderValSchema = useSenderValidationSchema(
    countryFrom,
    user,
    !!howToSend?.pickupId,
  );
  const recipientValSchema = useRecipientValidationSchema(
    countryTo,
    !!howToSend?.deliveryId,
  );
  const additionalServicesSchema = useAdditionalServicesValidationSchema(
    countryTo,
  );

  const validate = async <T>(
    // eslint-disable-next-line @typescript-eslint/ban-types
    schema: ObjectSchema<object & T, object>,
    data: T,
  ) => {
    try {
      await schema.validate(data);
      return null;
    } catch (e) {
      console.log(e, user);
      return e as ValidationError;
    }
  };

  const validateAll = async () => {
    setResult((prev) => ({
      ...prev,
      validationStarted: true,
      validated: false,
    }));

    const all = await Promise.all([
      validate(howToSendValSchema, howToSend),
      validate(parcelValSchema, parcel),
      validate(recipientValSchema, recipient),
      validate(senderValSchema, sender),
      validate(additionalServicesSchema, additionalServices),
    ]);
    let hasError = false;
    const errorInAllOrdersFlags = all.map((valErr) => {
      if (hasError) return ErrorInAllOrder.after;
      if (valErr) {
        hasError = true;
        return ErrorInAllOrder.myself;
      }
      return ErrorInAllOrder.before;
    });
    setResult({
      validated: true,
      validationStarted: false,
      howToSend: {
        errorInAllOrder: errorInAllOrdersFlags[0],
        error: all[0],
      },
      parcel: {
        errorInAllOrder: errorInAllOrdersFlags[1],
        error: all[1],
      },
      recipient: {
        errorInAllOrder: errorInAllOrdersFlags[2],
        error: all[2],
      },
      sender: {
        errorInAllOrder: errorInAllOrdersFlags[3],
        error: all[3],
      },
      additionalServices: {
        errorInAllOrder: errorInAllOrdersFlags[4],
        error: all[4],
      },
    });
  };
  if (skip) return { validateAll, validating: false, isValid: true };
  if (!result.validated && !result.validationStarted) validateAll();
  const { validated, validationStarted, ...errors } = result;
  return {
    validateAll, //todo: use validateAll it in submit again
    errors,
    validating: !validated || validationStarted,
    isValid: (Object.values(errors) as ValidationErrorDescription[]).every(
      (x) => !x.error,
    ),
  };
};
