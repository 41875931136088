import { useUserContext } from '@hp/context';
import { useStoreAccountSourceMutation } from '@hp/order/src/graphql';
import { RoutesType, useUtmSource } from '@hp/seo';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { useCallback, useEffect } from 'react';

export const useStoreAccountSource = (
  client: ApolloClient<NormalizedCacheObject>,
  route: keyof RoutesType,
  asPath: string,
) => {
  const [utmParams, loaded] = useUtmSource();
  const { registerHook } = useUserContext();

  const [invoke] = useStoreAccountSourceMutation({ client });

  const getCampaignFromPath = (url: string) => {
    const parts = url?.split('/');
    return parts?.length ? parts[parts.length - 1] : null;
  };

  const getSource = () => {
    switch (route) {
      case 'orderSender':
        return 'order';
      case 'login':
      case 'register': {
        return utmParams?.utmData?.campaign || 'register';
      }
      case 'registerLanding': {
        return (
          utmParams?.utmData?.campaign ||
          getCampaignFromPath(asPath) ||
          'registerLanding'
        );
      }
    }
    return null;
  };
  const source = getSource();

  const setAccountSource = () => {
    if (!source) return;
    invoke({ variables: { source } });
  };
  const cb = useCallback(setAccountSource, [source, invoke]);

  useEffect(() => {
    return registerHook('createUser', cb);
  }, [cb, loaded, utmParams, route, asPath]);

  return { setAccountSource };
};
