import {
  ArrowIcon,
  Button,
  ButtonType,
  ConfirmIcon,
  LoadingIcon,
  Typography,
  TypographyType,
  WarningIcon,
} from '@hp/atomic';
import { useConfig } from '@hp/config';
import { PayUStatus } from '@hp/core/shared';
import {
  ModalWellKnowResult,
  useSystemModal,
} from '@hp/core/src/providers/SystemModal';
import { CenteredRow } from '@hp/layout/src/components/row';
import { colors } from '@hp/theme';
import { addDataLayerItem, usePrevious } from '@hp/utils';
import { Trans } from '@lingui/macro';
import useInterval from '@rooks/use-interval';
import React, { useEffect, useState } from 'react';

import { usePaymentQuery, useQrCodesQuery } from '../graphql';

const emptyArray: string[] = [];
const hasQrCode = (status?: PayUStatus) => {
  return status === PayUStatus.COMPLETED;
};

const isLoadingState = (status?: PayUStatus) => {
  //info: https://developers.payu.com/en/restapi.html
  return (
    status === PayUStatus.PENDING ||
    status === PayUStatus.WAITING_FOR_CONFIRMATION ||
    status === PayUStatus.CREATED
  );
};

const showErrorAndReload = (
  showErrorInfo: ReturnType<typeof useSystemModal>['showErrorInfo'],
) => {
  showErrorInfo(null, (invokeClose) => (
    <Button
      buttonType={ButtonType.PRIMARY}
      onClick={() => invokeClose(ModalWellKnowResult.OK)}
    >
      <Trans id="common.button.repeat">Opakovat</Trans>
    </Button>
  )).then(() => document.location.reload());
};

type UsePaymentResult = {
  /** status of payment OR undefined when payment has not been triggered yet */
  status?: PayUStatus;
  /** loading OR payment still in process */
  processingPayment: boolean;
  qrCodes: string[];
};

type UsePaymentProps = {
  bypassWaiting?: boolean;
  caseId: string | null;
  /** cb called when payment reach COMPLETED state */
  onComplete?: ({ closeInfo }: { closeInfo: () => void }) => void;
  tryAgain?: () => void;
  /** download QR code? */
  withQrCode?: boolean;
};

const ModalContent: React.FC<{ icon: React.ReactNode }> = ({
  children,
  icon,
}) => {
  return (
    <>
      {icon}
      <Typography type={TypographyType.BodyMicro} color={colors.green}>
        {children}
      </Typography>
    </>
  );
};

export const usePayment = (
  {
    caseId,
    onComplete,
    withQrCode,
    bypassWaiting,
    tryAgain,
  }: UsePaymentProps = {
    caseId: null,
  },
) => {
  const { showErrorInfo, showInfo, close } = useSystemModal();
  const {
    config: {
      constraints: { paymentWaitingTimeoutMs },
    },
  } = useConfig();

  const hasPayment = !!caseId;

  const { data, loading, error, refetch } = usePaymentQuery({
    variables: { id: caseId },
    skip: !hasPayment,
  });

  const status = data?.payment?.status || null;
  const [counterCreatedAt] = useState(new Date());
  const [delayMs, setDelayMs] = useState(1000);
  const { data: qrCode } = useQrCodesQuery({
    variables: { id: caseId },
    skip: !hasPayment || !hasQrCode(status) || !withQrCode,
  });
  const processingPayment = loading || isLoadingState(status);

  const checkResult = () => {
    if (error) {
      //refetch does not work when call failed
      showErrorAndReload(showErrorInfo);
      return;
    }
    if (processingPayment) {
      refetch();
      const nextAttempt = Math.round(Math.min(delayMs * 1.1, 10000));
      console.log(`Next check in ${nextAttempt} sec.`);
      setDelayMs(nextAttempt);
    }
  };

  const [start, stop] = useInterval(checkResult, delayMs);

  const previousStatus = usePrevious(status);

  const now = new Date();
  const delta = now.getTime() - counterCreatedAt.getTime();
  const previousDelta = usePrevious(delta);

  useEffect(() => {
    if (!hasPayment) return;
    if (previousDelta > paymentWaitingTimeoutMs) return;

    if (delta > paymentWaitingTimeoutMs /* 20 seconds */) {
      //when hook-effect alives after e.g. 1 minut, we can not show a dialog again...
      if (!processingPayment) return;

      stop();
      showErrorInfo(
        <Trans id="order.summary.modal.payment.timeout">
          Stav vaší platby se nepodařilo zjistit, zkuste prosím platbu provést
          znovu..
        </Trans>,
        () => null,
      );
      return;
    }

    if (previousStatus === status) return;

    if (hasPayment && loading) {
      showInfo(
        <ModalContent icon={<LoadingIcon />}>
          <Trans id="order.summary.modal.payment.loading">
            Ověřuji stav platby.
          </Trans>
        </ModalContent>,
        () => null,
        { disableClose: true },
      );
    }

    if (isLoadingState(status) && !bypassWaiting) {
      start();
      showInfo(
        <ModalContent icon={<LoadingIcon />}>
          <Trans id="order.summary.modal.payment.pending">
            Vaše platba právě probíhá, čekejte prosím.
          </Trans>
        </ModalContent>,
        (invokeClose) => (
          <Button
            buttonType={ButtonType.PRIMARY}
            onClick={() => invokeClose(ModalWellKnowResult.OK)}
          >
            <Trans id="common.button.ok">OK</Trans>
          </Button>
        ),
        { disableClose: true },
      );
    }

    if (status === PayUStatus.CANCELED && !bypassWaiting) {
      addDataLayerItem({ event: 'payment_canceled' });
      stop();
      showInfo(
        <div>
          <CenteredRow marginSize="s">
            <WarningIcon />
          </CenteredRow>
          <CenteredRow marginSize="s">
            <Typography
              type={TypographyType.BodySmall}
              color={colors.red_main}
              textAlign="center"
            >
              <Trans id="order.summary.modal.payment.canceled">
                Vaše platba bohužel neproběhla. Zkuste to prosím znovu.
              </Trans>
            </Typography>
          </CenteredRow>
        </div>,
        (close) =>
          tryAgain ? (
            <Button
              type="button"
              className="gaButton gaButtonSubmit_summary"
              buttonType={ButtonType.PRIMARY}
              icon={<ArrowIcon />}
              onClick={() => close(ModalWellKnowResult.OK)}
              animate={true}
            >
              <Trans id="order.summary.button.payAgain">Zaplatit znovu</Trans>
            </Button>
          ) : null,
      ).then((result) => {
        if (result === ModalWellKnowResult.OK) {
          addDataLayerItem({ event: 'payment_initiated_again' });
          tryAgain();
        }
      });
    }

    if (status === PayUStatus.COMPLETED) {
      stop();
      if (!onComplete) {
        //we ensure, all previous dialogs are closed
        close();
        return;
      }
      showInfo(
        <ModalContent icon={<ConfirmIcon color={colors.green} width={52} />}>
          <Trans id="order.summary.modal.payment.completed">
            Platba potvrzena, vyčkejte ...
          </Trans>
        </ModalContent>,
        () => null,
        { disableClose: true },
      );
      onComplete?.({ closeInfo: close });
    }
  }, [
    status,
    previousStatus,
    showInfo,
    start,
    stop,
    close,
    onComplete,
    hasPayment,
    counterCreatedAt,
    delta,
    previousDelta,
  ]);

  //no payment yet
  if (!hasPayment)
    return {
      status: undefined,
      qrCodes: emptyArray,
      processingPayment,
    } as UsePaymentResult;

  //still loading
  if (!data || loading)
    return {
      status: PayUStatus.WAITING_FOR_CONFIRMATION,
      qrCodes: emptyArray,
      processingPayment,
    } as UsePaymentResult;

  return {
    status,
    processingPayment,
    qrCodes: (qrCode?.qrCodes ?? emptyArray) as string[],
  } as UsePaymentResult;
};
