import {
  ArrowIcon,
  Button,
  ButtonType,
  H2,
  LinkButton,
  MapSearchIcon,
  MultiPackageIcon,
  Note,
  NoteButton,
  NoteItem,
  QRCodeIcon,
  SendPackageIcon,
  Strong,
  TimerColorsIcon,
  Typography,
  TypographyType,
} from '@hp/atomic';
import { config as appConfig, Language, useConfig } from '@hp/config';
import { useUserContext } from '@hp/context';
import { Country, PickupType } from '@hp/core/shared';
import { useLanguage } from '@hp/core/src/providers/LanguageProvider';
import { FormWrapper } from '@hp/form/src/atomic/FormWrapper';
import { CountrySelectField } from '@hp/form/src/fields/CountrySelectField';
import { SelectField } from '@hp/form/src/fields/SelectField';
import { SwitchButtonField } from '@hp/form/src/fields/SwitchButtonField';
import {
  Column2,
  HideOnDesktop,
  HideOnMobile,
  Row as LayoutRow,
  WideWrapperMobile,
} from '@hp/layout';
import { labels, useI18n } from '@hp/locale';
import { colors } from '@hp/theme';
import { getDeliveryDate, hasFeature, pickupDateOptions } from '@hp/utils';
import { Trans } from '@lingui/react';
import { useFormik } from 'formik';
import React, { FC, useEffect } from 'react';

import { HowToSendFormFields, HowToSendFormProps } from '../../../types';
import { Divider, HowToSendSection } from '../styled';
import { useHowToSendForm } from '../useHowToSendForm';
import { useHowToSendValidationSchema } from '../useHowToSendValidationSchema';
import { CourierIconStyled, ParcelShopIconStyled, Row } from './styled';

const formId = 'howToSendForm';

export const HowToSendFormV2: FC<HowToSendFormProps> = ({
  selectedParcelShop,
  onSendToCountryChange,
}) => {
  const { language } = useLanguage();
  const { config } = useConfig();

  const {
    app: { parcelShopSearchUrl, parcelShopSearchUrlEn },
  } = appConfig;

  const searchUrlLang =
    language === Language.CS ? parcelShopSearchUrl : parcelShopSearchUrlEn;

  const { user } = useUserContext();
  const { initialValues, handleSubmit, saveFormData } = useHowToSendForm(
    selectedParcelShop,
  );

  const formik = useFormik<HowToSendFormFields>({
    initialValues,
    enableReinitialize: false,
    onSubmit: handleSubmit,
    validationSchema: useHowToSendValidationSchema(false),
  });
  const i18n = useI18n();

  useEffect(() => {
    saveFormData(formik.values);
  }, [formik.values]);

  useEffect(() => {
    if (onSendToCountryChange) {
      onSendToCountryChange(initialValues?.sendToCountry);
    }
  }, [initialValues?.sendToCountry]);

  const pickupOptions = pickupDateOptions(formik.values.sendFromCountry, i18n);
  const pickupOptionsWithDayNames = pickupDateOptions(
    formik.values.sendFromCountry,
    i18n,
    true,
    language,
  );

  const selectedDateOption =
    pickupOptions.find((x) => x.value === formik.values.sendWhen) ||
    pickupOptions[0];

  const deliveryDate = getDeliveryDate(
    selectedDateOption.date,
    formik.values.sendFromCountry,
    formik.values.sendToCountry,
    config,
    i18n,
  );

  const searchUrl =
    window.location.pathname === '/account'
      ? `${searchUrlLang}/?return=${window.location.origin}/account/parcelshop`
      : `${searchUrlLang}/?return=${window.location.origin}/parcelshop`;

  return (
    <HowToSendSection id="howToSendSection">
      <FormWrapper formik={formik}>
        <H2>
          <Typography type={TypographyType.Heading2Light}>
            <Trans id="homepage.howToSend.heading">Máte něco k odeslání?</Trans>
          </Typography>
        </H2>
        <Row>
          {hasFeature('sourceCountry') ? (
            <>
              <Column2 centered>
                <CountrySelectField<HowToSendFormFields>
                  formId={formId}
                  name="sendFromCountry"
                  label={labels.howToSend.withSourceCountry.sendFromCountry}
                  data-test="homepage.input.sendFromCountry"
                  filter={(country) => country === Country.CZ}
                  showAsCode
                  disabled={formik.isSubmitting}
                />
              </Column2>
              <Divider />
              <Column2 centered>
                <CountrySelectField<HowToSendFormFields>
                  formId={formId}
                  name="sendToCountry"
                  label={labels.howToSend.withSourceCountry.sendToCountry}
                  data-test="homepage.input.sendToCountry"
                  onChange={(event) => {
                    if (onSendToCountryChange) {
                      onSendToCountryChange(event.target.value);
                    }
                  }}
                  showAsCode
                  disabled={formik.isSubmitting}
                />
              </Column2>
            </>
          ) : (
            <CountrySelectField<HowToSendFormFields>
              formId={formId}
              name="sendToCountry"
              label={labels.howToSend.sendToCountry}
              data-test="homepage.input.sendToCountry"
              disabled={formik.isSubmitting}
            />
          )}
        </Row>
        <Row>
          <SwitchButtonField<PickupType>
            formId={formId}
            name="pickupType"
            extraMarginBetween
          >
            {[
              {
                value: PickupType.PARCEL_SHOP,
                disabled: !hasFeature('v3') || formik.isSubmitting,
                content: ({ checked, disabled }) => {
                  const color = checked
                    ? colors.red_main
                    : disabled
                    ? colors.gray_warm
                    : colors.black;
                  return (
                    <>
                      <ParcelShopIconStyled
                        color={disabled && colors.gray_warm}
                      />
                      <Typography
                        type={TypographyType.BodySmall}
                        textAlign="center"
                        color={color}
                      >
                        <Trans id="order.sender.pickupType.parcelShop">
                          Zásilku přinesu na pobočku
                        </Trans>
                      </Typography>
                      <Typography
                        type={TypographyType.BodyMicroLight}
                        textAlign="center"
                        color={color}
                      >
                        {disabled ? (
                          <Trans id="order.sender.pickupType.disabled">
                            Brzy k dispozici
                          </Trans>
                        ) : (
                          <span />
                        )}
                      </Typography>
                    </>
                  );
                },
                testId: 'homepage.input.pickupType.parcelShop',
              },
              {
                value: PickupType.COURIER,
                disabled: formik.isSubmitting,
                content: ({ checked, disabled }) => (
                  <>
                    <CourierIconStyled color={disabled && colors.gray_warm} />
                    <Typography
                      type={TypographyType.BodySmall}
                      textAlign="center"
                      color={checked ? colors.red_main : colors.black}
                    >
                      <Trans id="order.sender.pickupType.courier">
                        Zásilku u mě vyzvedněte
                      </Trans>
                    </Typography>
                  </>
                ),
                testId: 'homepage.input.pickupType.courier',
              },
            ]}
          </SwitchButtonField>
        </Row>
        {formik.values.pickupType === PickupType.COURIER && (
          <SelectField<HowToSendFormFields>
            formId={formId}
            name="sendWhen"
            label={labels.howToSend.sendWhen}
            options={pickupOptionsWithDayNames}
            noCheckmark
            data-test="homepage.input.sendWhen"
            disabled={formik.isSubmitting}
          />
        )}
        <WideWrapperMobile>
          <Note marginSize="m">
            {formik.values.pickupType !== PickupType.COURIER ? (
              <>
                <NoteItem icon={<TimerColorsIcon />}>
                  <Trans id="homepage.howToSend.deliveryNote.parcelShop.deliveryTime">
                    Pokud zásilku přinesete na jednu z našich 900 poboček
                    do&nbsp;12:00, doručíme ji následující pracovní den.
                  </Trans>
                </NoteItem>
                <NoteItem icon={<QRCodeIcon />}>
                  <Trans id="homepage.howToSend.deliveryNote.parcelshop.qrCodeInfo">
                    O přepravní štítek se postaráme my, na vás je pouze zabalení
                    zásilky.
                  </Trans>
                </NoteItem>
              </>
            ) : (
              <>
                <NoteItem icon={<TimerColorsIcon />}>
                  <Trans id="homepage.howToSend.deliveryNote.courier.deliveryTime">
                    Pokud u vás zásilku vyzvedneme {selectedDateOption.label},
                    doručíme ji <Strong>{deliveryDate}</Strong>.
                  </Trans>
                </NoteItem>
                <NoteItem icon={<QRCodeIcon />}>
                  <Trans id="homepage.howToSend.deliveryNote.courier.qrCodeInfo">
                    Přepravní štítek tisknout nemusíte, přiveze ho kurýr. Na vás
                    je pouze zabalení zásilky.
                  </Trans>
                </NoteItem>
              </>
            )}
            {!hasFeature('multiPackage') && (
              <NoteItem icon={<MultiPackageIcon />}>
                <Trans id="homepage.howToSend.deliveryNote.parcelshop.multipackage">
                  Stejnému příjemci můžete poslat více balíků. Po zaplacení
                  klikněte na „Opakovat objednávku” a údaje zůstanou vyplněné.
                </Trans>
              </NoteItem>
            )}

            {formik.values.pickupType === PickupType.PARCEL_SHOP && (
              <>
                <NoteButton
                  className="gaButton gaButtonAction_searchParcelShop"
                  icon={<MapSearchIcon />}
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = searchUrl;
                  }}
                  data-test="homepage.button.searchParcelShop"
                >
                  <Trans id="homepage.howToSend.deliveryNote.parcelShop.button">
                    Vyhledat nejbližší pobočku
                  </Trans>
                </NoteButton>

                {selectedParcelShop ? (
                  <NoteItem>
                    {selectedParcelShop.parcelshop_name}
                    <br />
                    {selectedParcelShop.parcelshop_address}
                  </NoteItem>
                ) : (
                  <>
                    {formik.values.selectedParcelShopId && (
                      <NoteItem>
                        {formik.values.selectedParcelShopName}
                        <br />
                        {formik.values.selectedParcelShopAddress}
                      </NoteItem>
                    )}
                  </>
                )}
              </>
            )}
          </Note>
        </WideWrapperMobile>
        <LayoutRow marginSize="m">
          <Button
            fullWidth
            type="submit"
            className="gaButton gaButtonSubmit_howToSend"
            fakeDisabled={!formik.isValid}
            buttonType={ButtonType.PRIMARY}
            icon={
              <>
                <HideOnDesktop className="gaButton gaButtonSubmit_howToSend">
                  <ArrowIcon
                    color={!formik.isValid ? colors.gray_warm : colors.white}
                  />
                </HideOnDesktop>
                <HideOnMobile className="gaButton gaButtonSubmit_howToSend">
                  <SendPackageIcon
                    color={!formik.isValid ? colors.gray_warm : colors.white}
                  />
                </HideOnMobile>
              </>
            }
            animate={formik.isValid}
            data-test="homepage.button.submit"
          >
            <HideOnDesktop className="gaButton gaButtonSubmit_howToSend">
              <span className="gaButton gaButtonSubmit_howToSend">
                <Trans id="common.button.continue">Pokračovat</Trans>
              </span>
            </HideOnDesktop>
            <HideOnMobile className="gaButton gaButtonSubmit_howToSend">
              <span className="gaButton gaButtonSubmit_howToSend">
                <Trans id="common.button.sendPackage">Poslat balík</Trans>
              </span>
            </HideOnMobile>
          </Button>
        </LayoutRow>{' '}
        {!user && (
          <HideOnMobile>
            <LayoutRow marginSize="m">
              <LinkButton
                buttonType={ButtonType.SECONDARY}
                to="register"
                fullWidth
                data-test="homepage.button.createAccount"
                className="gaButton gaButtonAction_createAccount"
              >
                <Trans id="homepage.button.createAccount">Vytvořit účet</Trans>
              </LinkButton>
            </LayoutRow>
          </HideOnMobile>
        )}
      </FormWrapper>
    </HowToSendSection>
  );
};
