import { NextLink } from '@hp/atomic';
import { breakpoints, colors, elements, spacing, typography } from '@hp/theme';
import styled from 'styled-components';

export const ParcelWeightContainer = styled.div`
  position: relative;
  background: ${colors.input};
  width: 100%;
  padding: 1px ${spacing.s} 0px;
  border-radius: 2px;
  box-shadow: inset 0px 3px 3px rgba(49, 50, 48, 0.05);

  @media (min-width: ${breakpoints.midMobile}) {
    padding: 1px ${spacing.m} 0px;
  }
`;

export const ParcelPartNumber = styled.div`
  color: ${colors.gray_mid};
  display: inline-block;
  margin-left: ${spacing.s};
  font-weight: ${typography.fontWeight.normal};
`;

export const CloseIconWrapper = styled.div`
  position: absolute;
  padding: ${spacing.s};
  right: 0;
  top: 0;
`;

export const BoxIconWrapper = styled.div`
  margin: ${spacing.xxs} auto;
  vertical-align: middle;
  width: ${elements.buttonHeight};
  height: ${elements.buttonHeight};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ParcelInputWrapper = styled.div`
  margin-left: ${spacing.l};
  width: ${elements.buttonMaxWidth};
`;

export const ChangePickupLink = styled(NextLink)`
  color: ${colors.red_dark};
  text-decoration: underline;

  :hover {
    color: ${colors.red_dark};
  }
`;

export const WeightSwitchContainer = styled.div`
  margin-top: ${spacing.m};
`;
