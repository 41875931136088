import { useConfig } from '@hp/config';
import {
  Country,
  getSpecificCfg,
  ParcelPartDescription,
  ParcelSize,
  PickupType,
} from '@hp/core/shared';
import { useYup } from '@hp/form';

import { ParcelFormProps } from '../../types';

export const useParcelValidationSchema = (
  pickupType: PickupType,
  targetCountry: Country,
) => {
  const Yup = useYup();
  const { config } = useConfig();
  const specList = getSpecificCfg(config.parcelSpecifications, targetCountry);
  const validationSchema = Yup.object().shape<ParcelFormProps>({
    items: Yup.array().of(
      Yup.object().shape<ParcelPartDescription>({
        size: Yup.mixed<ParcelSize>()
          .oneOf(Object.values(ParcelSize))
          .required(),
        weight: Yup.number().when('size', (size: ParcelSize, schema) => {
          const hasRanges = !!specList[size].ranges?.length;
          if (!hasRanges) return schema.nullable();

          const minWeight = specList[size].minWeight;
          const maxWeight =
            pickupType !== PickupType.COURIER
              ? Math.min(
                  config.constraints.maxWeightParcelShop,
                  specList[size].maxWeight,
                )
              : specList[size].maxWeight;

          return schema.min(minWeight).max(maxWeight).required();
        }),
      }),
    ),
  });
  return validationSchema;
};
