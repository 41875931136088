import { H2 } from '@hp/atomic';
import { breakpoints, spacing } from '@hp/theme';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: ${spacing.m};
`;

export const Heading = styled(H2)`
  margin-top: 0;

  @media (min-width: ${breakpoints.minDesktop}) {
    margin-bottom: 30px;
  }
`;
