import { DayOfTheWeek } from '@hp/utils';
import { FormikContextType } from 'formik';
import React from 'react';

import { OpenClosePair, ParcelShopItemType } from '../../types';

export type ParcelShopMode = 'pickup' | 'delivery';

export type ParcelShopItemProps = {
  selected?: boolean;
  onClick?: (item: ParcelShopItemType, mode: ParcelShopMode) => void;
  onEditClick?: () => void;
  item: ParcelShopItemType | string;
  hideOpeningStatus?: boolean;
  hideIcon?: boolean;
  mini?: boolean;
  mode: ParcelShopMode;
  distance?: JSX.Element;
};

export type ParcelShopItemDetailProps = (
  | {
      mode: 'pickup';
      showInfoForParcelL?: boolean;
    }
  | {
      mode: 'delivery';
      showInfoForParcelL?: false;
    }
) & {
  item: ParcelShopItemType;
  onClick?: () => void;
};

export type ParcelShopListProps = {
  formik: FormikContextType<any>;
  isLoading: boolean;
  increaseLimit?: () => void;
  children: React.ReactNodeArray;
  type: ParcelShopMode;
};

export type PairWithDay = {
  dayName: DayOfTheWeek;
  pair: OpenClosePair;
};

export type MultiDayInterval = {
  dayOpen: DayOfTheWeek;
  dayClose: DayOfTheWeek;
  nonstop?: true;
} & OpenClosePair;

export enum ShopState {
  'nonstop' = 'nonstop',
  'opened' = 'opened',
  'closed' = 'closed',
  'permanentlyClosed' = 'permanentlyClosed',
}
