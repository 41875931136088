import { H2, Link, NoteItem, Strong, UpperCase } from '@hp/atomic';
import { ReadMore } from '@hp/auth';
import { WideNoteOnMobile } from '@hp/components';
import { CheckboxField, PasswordField } from '@hp/form';
import { fields, labels } from '@hp/locale';
import { colors } from '@hp/theme';
import { Trans } from '@lingui/react';
import { useField } from 'formik';
import React, { FC } from 'react';

import { SenderFormFields } from '../../types';

type RegisterNoteProps = {
  formId: string;
  disabled?: boolean;
  emailIsKnown: boolean;
  onLoginClick: () => void;
};

export const RegisterNote: FC<RegisterNoteProps> = ({
  formId,
  disabled,
  emailIsKnown,
  onLoginClick,
}) => {
  const [{ value: createAccount }] = useField('createAccount');
  const [{ value: email }] = useField('email');

  return (
    <WideNoteOnMobile>
      <H2>
        <Trans id="order.sender.registerNote.head">
          Vytvořte si účet a příště už nic vyplňovat nemusíte.
        </Trans>
      </H2>

      <NoteItem>
        <CheckboxField<SenderFormFields>
          disabledColor={colors.gray_mid}
          formId={formId}
          name="createAccount"
          label={fields.createAccount}
          className="gaButton gaButtonCheckbox_createAccount"
          disabled={disabled || emailIsKnown}
        />
      </NoteItem>
      <NoteItem>
        <UpperCase mode="capitalize">
          <Trans id="common.user">uživatel</Trans>:&nbsp;
        </UpperCase>
        <Strong>{email}</Strong>
      </NoteItem>
      {createAccount && !emailIsKnown && (
        <PasswordField<SenderFormFields>
          formId={formId}
          name="password"
          label={labels.setPassword}
          disabled={disabled}
        />
      )}
      <NoteItem>
        {emailIsKnown ? (
          <Trans id="order.sender.registerNote.alreadyRegistered">
            K této e-mailové adrese už máte vytvořený účet.{' '}
            <Link onClick={onLoginClick}>
              Přihlaste se nebo si nechte poslat nové heslo.
            </Link>
            .
          </Trans>
        ) : createAccount ? (
          <ReadMore />
        ) : null}
      </NoteItem>
    </WideNoteOnMobile>
  );
};
