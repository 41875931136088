import { Loading, Strong } from '@hp/atomic';
import { PickupType } from '@hp/core/shared';
import { formatDate } from '@hp/utils';
import { Trans } from '@lingui/macro';
import React, { FC } from 'react';

import { getErrorMsg, ParcelShopItem } from '../Delivery/ParcelShopItem';
import { useSummary } from './useSummary';

export const PickupAndDelivery: FC<Pick<
  ReturnType<typeof useSummary>,
  'howToSend' | 'shopData'
>> = ({
  howToSend: { pickupType, sendWhen, deliveryType },
  shopData: { delivery, pickup },
}) => (
  <>
    <div>
      <Strong>
        <Trans id="common.sending">Odeslání</Trans>:
      </Strong>

      {pickupType === PickupType.COURIER ? (
        <>
          <br /> <Trans id="common.fromAddress">Z adresy</Trans>,{' '}
          <Trans id="order.summary.pickupType.courier">
            vyzvednutí kurýrem
          </Trans>{' '}
          {formatDate(Date.parse(sendWhen), 'DD.MM.YYYY')}
        </>
      ) : (
        <>
          {pickup.loading ? (
            <Loading height="60px" />
          ) : pickup.error ? (
            getErrorMsg(pickup.error)
          ) : (
            <ParcelShopItem
              item={pickup.data}
              selected
              hideOpeningStatus
              hideIcon
              mode="pickup"
            />
          )}
        </>
      )}
    </div>

    <br />

    <div>
      <Strong>
        <Trans id="common.delivery">Doručení</Trans>:
      </Strong>
      {deliveryType === PickupType.COURIER ? (
        <>
          <br />

          <Trans id="common.toAddress">Na adresu</Trans>
        </>
      ) : (
        <>
          {delivery.loading ? (
            <Loading height="50px" />
          ) : delivery.error ? (
            getErrorMsg(delivery.error)
          ) : (
            <ParcelShopItem
              item={delivery.data}
              selected
              hideOpeningStatus
              hideIcon
              mode="delivery"
            />
          )}
        </>
      )}
    </div>
  </>
);
