import { Typography, TypographyType } from '@hp/atomic';
import { FormWrapper } from '@hp/form';
import { CenteredRow } from '@hp/layout';
import { colors } from '@hp/theme';
import { Trans } from '@lingui/react';
import { useFormik } from 'formik';
import React, { FC } from 'react';

import { ConfirmFormContent } from './ConfirmContent';
import { DimensionFormContent } from './DimensionFormContent';
import { CalculatorFormProps, useCalculator } from './useCalculator';

const formId = 'maxSizeCalculatorForm';

export type MaxSizeCalculatorFormProps = {
  onSubmit?: (values: CalculatorFormProps) => void;
};

export const MaxSizeCalculatorForm: FC<MaxSizeCalculatorFormProps> = ({
  onSubmit,
}) => {
  const {
    initialValues,
    handleSubmit,
    reset,
    validationSchema,
    isComputed,
    hasProperDimension,
    circumference,
  } = useCalculator(onSubmit);

  const formik = useFormik<CalculatorFormProps>({
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema,
  });

  return (
    <>
      <CenteredRow>
        <Typography type={TypographyType.BodyMicro} color={colors.black}>
          <Trans id="order.parcel.maxSizeCalculator.computeDimensions">
            Spočítat rozměry
          </Trans>
        </Typography>
      </CenteredRow>

      <FormWrapper formik={formik}>
        {isComputed ? (
          <ConfirmFormContent
            dimension={formik.values}
            hasProperDimension={hasProperDimension}
            circumference={circumference}
            reset={reset}
          />
        ) : (
          <DimensionFormContent formId={formId} />
        )}
      </FormWrapper>
    </>
  );
};
