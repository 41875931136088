import { Button, ButtonType, Typography, TypographyType } from '@hp/atomic';
import { LoginRegisterFormFields, LoginRegisterType } from '@hp/auth';
import { LoginContent } from '@hp/auth/src/components/LoginRegister/LoginContent';
import { useLoginRegisterForm } from '@hp/auth/src/components/LoginRegister/useLoginRegisterForm';
import { FormWrapper, SwitchButtonField, SwitchButtonProps } from '@hp/form';
import { CenteredRow } from '@hp/layout';
import { hasFeature, preventDefaultOnEnter } from '@hp/utils';
import { Trans } from '@lingui/react';
import { useFormik } from 'formik';
import React, { FC, useEffect } from 'react';

import { FormContentWrapper } from '../../styled';

export enum AccountSwitchType {
  HASACCOUNT = 'hasAccount',
  HASNOTACCOUNT = 'hasNotAccount',
}

type Props = Omit<SwitchButtonProps, 'children'>;

const AccountSwitchInner: FC<Props> = ({ name, formId }) => {
  return (
    <SwitchButtonField<LoginRegisterType>
      formId={formId}
      name={name}
      extraMarginBottom
      type={hasFeature('v3') ? 'chip' : 'button'}
    >
      {[
        {
          value: LoginRegisterType.REGISTER,
          content: () => (
            <Typography type={TypographyType.BodySmall} textAlign="center">
              <Trans id="sender.IDontHaveAccount">Nemám účet</Trans>
            </Typography>
          ),
          testId: 'sender.senderType.person',
        },
        {
          value: LoginRegisterType.LOGIN,
          content: () => (
            <Typography type={TypographyType.BodySmall} textAlign="center">
              <Trans id="sender.IAlreadyHaveAccount">Mám účet</Trans>
            </Typography>
          ),
          testId: 'sender.senderType.company',
        },
      ]}
    </SwitchButtonField>
  );
};

type AccountSwitchProps = {
  type: AccountSwitchType;
  onChangeType?: (type: AccountSwitchType) => void;
};

/** loginRegisterForm uses types: Register="No account", and "Login"="Already has account"  */
const accountSwitchType2RegType = (type: AccountSwitchType) =>
  type === AccountSwitchType.HASACCOUNT
    ? LoginRegisterType.LOGIN
    : LoginRegisterType.REGISTER;
const regType2accountSwitchType = (type: LoginRegisterType) =>
  type === LoginRegisterType.LOGIN
    ? AccountSwitchType.HASACCOUNT
    : AccountSwitchType.HASNOTACCOUNT;

export const AccountSwitch: FC<AccountSwitchProps> = ({
  type,
  onChangeType,
}) => {
  const {
    initialValues,
    validationSchema,
    handleSubmit,
    isSubmitValid,
    forgottenPasswordButton,
    forgottenPasswordModal,
    checkAccountByEmail,
  } = useLoginRegisterForm({ type: accountSwitchType2RegType(type) });

  const formik = useFormik<LoginRegisterFormFields>({
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema,
  });

  useEffect(() => {
    onChangeType?.(regType2accountSwitchType(formik.values.type));
  }, [formik.values.type, onChangeType]);

  const formId = 'accountSwitch';

  return (
    <FormContentWrapper noBottomMargin>
      <FormWrapper
        formik={formik}
        flex
        disableAutoComplete
        marginBottomSize="zero"
        onKeyDown={preventDefaultOnEnter}
      >
        <AccountSwitchInner formId={formId} name="type" />
        {type === AccountSwitchType.HASACCOUNT && (
          <>
            <LoginContent
              formId={formId}
              onEmailBlur={() =>
                checkAccountByEmail(formik.values.email).then(() =>
                  formik.validateForm(),
                )
              }
            />
            {forgottenPasswordButton}
            <CenteredRow>
              <Button
                bottom
                fullWidth
                type="submit"
                className="gaButton gaButtonSubmit_login"
                buttonType={ButtonType.PRIMARY}
                fakeDisabled={
                  /* because we are not able to check (and get programmatically) autocompleted value - for login we check only valid email */
                  !!formik.errors.email || !isSubmitValid(formik.values.type)
                }
                data-test="auth.button.submit"
              >
                <Trans id="auth.login.button">Přihlásit se</Trans>
              </Button>
            </CenteredRow>
          </>
        )}
      </FormWrapper>
      {forgottenPasswordModal}
    </FormContentWrapper>
  );
};
