import {
  BestWayIcon,
  CalendarClockIcon,
  NoteItem,
  Nowrap,
  QRCodeIcon,
  ShieldIcon,
  Strong,
  Typography,
  TypographyType,
} from '@hp/atomic';
import { Money } from '@hp/components';
import { useConfig } from '@hp/config';
import { CenteredRow } from '@hp/layout';
import { breakpoints, colors, spacing } from '@hp/theme';
import { Trans } from '@lingui/react';
import React, { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: ${colors.white};
  padding: ${spacing.m};
  border-radius: 5px;
  margin: 40px 8px 0;
`;

const NoteItemStyled = styled(NoteItem)`
  width: 290px;

  @media (min-width: ${breakpoints.bigDesktop}) {
    width: 320px;
    padding: 0 20px;
  }
`;

interface Props {
  isCodAllowed: boolean;
}

export const IncludedDesktop: FC<Props> = ({ isCodAllowed }) => {
  const {
    config: {
      constraints: { maxInsurance },
    },
  } = useConfig();

  return (
    <Container>
      <CenteredRow marginBottom="m">
        <Strong>
          <Typography type={TypographyType.BodyExtraLargeLight}>
            <Trans id="homepage.included.heading">Služba zahrnuje</Trans>:
          </Typography>
        </Strong>
      </CenteredRow>

      <CenteredRow>
        <NoteItemStyled icon={<CalendarClockIcon />}>
          {isCodAllowed ? (
            <Trans id="homepage.included.printAddressLabel">
              Doručení následující pracovní den, <Nowrap>s dobírkou</Nowrap>{' '}
              <Nowrap>i bez</Nowrap>
            </Trans>
          ) : (
            <Trans id="homepage.included.printAddressLabelWithoutCOD">
              Doručení následující pracovní den,{' '}
              <Nowrap>pouze bez dobírky</Nowrap>
            </Trans>
          )}
        </NoteItemStyled>

        <NoteItemStyled icon={<BestWayIcon />}>
          <Trans id="homepage.included.deliveryGuarantee">
            Příjemce si může změnit adresu <Nowrap>i termín</Nowrap>
          </Trans>
        </NoteItemStyled>
      </CenteredRow>

      <CenteredRow>
        <NoteItemStyled icon={<ShieldIcon />}>
          <Trans id="homepage.included.insurance">
            Pojištění do{' '}
            <Nowrap>
              <Money currency="CZK">{maxInsurance}</Money>
            </Nowrap>{' '}
            <Nowrap>v ceně</Nowrap> přepravy
          </Trans>
        </NoteItemStyled>

        <NoteItemStyled icon={<QRCodeIcon />}>
          <Trans id="homepage.included.label">
            Přepravní štítek, který nemusíte tisknout
          </Trans>
        </NoteItemStyled>
      </CenteredRow>
    </Container>
  );
};
