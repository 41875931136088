import {
  ArrowIcon,
  Button,
  ButtonType,
  H2,
  Hr,
  Link,
  Note,
  Strong,
  Typography,
  TypographyType,
  UpperCase,
} from '@hp/atomic';
import { CountryNameLikeField, Money } from '@hp/components';
import { useConfig } from '@hp/config';
import { AddressType, Country, Language, PickupType } from '@hp/core/shared';
import { useLanguage } from '@hp/core/src/providers/LanguageProvider';
import { useSystemModal } from '@hp/core/src/providers/SystemModal';
import {
  CheckboxField,
  FieldWrapper,
  FormBackButton,
  FormWrapper,
  PhoneField,
  SwitchButtonField,
  TextAreaField,
  TextField,
} from '@hp/form';
import { SpaceBetweenRow } from '@hp/layout';
import { fields, labels } from '@hp/locale';
import { colors } from '@hp/theme';
import {
  formatPostalCodeSimple,
  hasFeature,
  isPostalCodeValidable,
  objectEquals,
  preventDefaultOnEnter,
  unformatPostalCode,
  useEffectSaveForm,
  useEffectValidateOnInitForm,
  useObjectLocalStorage,
  usePrevious,
} from '@hp/utils';
import { Trans } from '@lingui/react';
import { useFormik } from 'formik';
import React, { FC, useEffect, useState } from 'react';

import { sharedKeys } from '../../sharedKeys';
import {
  CityPostalCodeContainer,
  CityPostalCodeFlexItem,
  FormContentWrapper,
} from '../../styled';
import {
  ParcelFormProps,
  RecipientFormFields,
  SenderFormFields,
} from '../../types';
import { computePrice } from '../../utils';
import { ParcelShopItem } from '../Delivery/ParcelShopItem';
import { AccountSwitch, AccountSwitchType } from './AccountSwitch';
import { RegisterNote } from './RegisterNote';
import { HeaderWrapper, PrivacyInformation } from './styled';
import { SenderFormProps } from './types';
import { useSenderForm } from './useSenderForm';

const formId = 'senderForm';

export const SenderForm: FC<SenderFormProps> = ({ selectedParcelShop }) => {
  const [parcel] = useObjectLocalStorage<ParcelFormProps>('parcel');
  const [recipient] = useObjectLocalStorage<RecipientFormFields>('recipient');
  const [hasAccountType, setHasAccountType] = useState(
    AccountSwitchType.HASNOTACCOUNT,
  );

  const {
    initialValues,
    saveFormData,
    handleSubmit,
    checkAccountByEmail,
    emailIsKnown,
    user,
    userIsLoading,
    chooseAddress,
    chooseInvoiceAddress,
    validationSchema,
    howToSendFormData,
    emptyValues,
    pickupParcelShopId,
    changePickupPoint,
  } = useSenderForm(selectedParcelShop);

  const formik = useFormik<SenderFormFields>({
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema,
    validateOnMount: false,
  });

  useEffect(() => {
    formik.validateForm();
    if (user) {
      formik.setFieldValue('createAccount', false);
      formik.setFieldValue('email', user.user.email);
    }
  }, [user]);

  useEffect(() => {
    if (emailIsKnown) formik.setFieldValue('createAccount', false);
  }, [emailIsKnown]);

  const prevInitValues = usePrevious(initialValues);

  //reseting validation after changing initValues, but fix is weird :-(
  useEffect(() => {
    if (!objectEquals(initialValues, prevInitValues, true)) {
      //weird, it does not works without timeout
      setTimeout(() => formik.validateForm(), 1000);
      //weird, clear intinterval does not works as well:
      //return clearTimeout(h);
    }
  }, [initialValues]);

  const { language } = useLanguage();
  const { config } = useConfig();

  useEffectSaveForm(formik, saveFormData, true);
  //we will validate everytime when initalValus are changed (user was loaded, query loaded data etc.)
  useEffectValidateOnInitForm(
    formik,
    emptyValues,
    true,
    Object.values(initialValues),
  );
  const { showInfo } = useSystemModal();

  const externalLinks =
    config.externalLinks[language] ?? config.externalLinks.default;

  const showMainForm =
    hasAccountType === AccountSwitchType.HASNOTACCOUNT || !!user;

  const showAccountSwitch = !user;

  const allErrors = Object.keys(formik.errors);
  const termsAggrementError =
    allErrors.length === 1 && !!formik.errors.termsAgreement;
  //when only agreement is missing, button should be active
  const showConfirmActive = allErrors.length === 0 || termsAggrementError;

  return (
    <>
      {showAccountSwitch && (
        <AccountSwitch type={hasAccountType} onChangeType={setHasAccountType} />
      )}
      {showMainForm && (
        <FormContentWrapper noTopMargin={showAccountSwitch}>
          <FormWrapper
            formik={formik}
            flex
            disableAutoComplete
            marginTopSize={showAccountSwitch ? 'zero' : 'l'}
            marginBottomSize="xl"
            onKeyDown={preventDefaultOnEnter}
          >
            {/* if you pray, turning off autocomplete will work */}
            <input type="hidden" value="prayer" />
            <HeaderWrapper>
              <H2 marginSize="zero">
                <Strong>
                  <UpperCase mode="capitalize">
                    <Trans id="common.sender">odesílatel</Trans>
                  </UpperCase>
                </Strong>
              </H2>

              {user && (
                <Typography type={TypographyType.BodyMicroLight}>
                  {user.user.email}
                </Typography>
              )}
            </HeaderWrapper>
            <SwitchButtonField<AddressType>
              formId={formId}
              name="sender"
              extraMarginBottom
              type={hasFeature('v3') ? 'chip' : 'button'}
            >
              {[
                {
                  value: AddressType.PERSON,
                  disabled: formik.isSubmitting,
                  content: () => (
                    <Typography
                      type={TypographyType.BodySmall}
                      textAlign="center"
                    >
                      <Trans id="common.subjectType.person">Osoba</Trans>
                    </Typography>
                  ),
                  testId: 'sender.senderType.person',
                },
                {
                  value: AddressType.COMPANY,
                  disabled: formik.isSubmitting,
                  content: () => (
                    <Typography
                      type={TypographyType.BodySmall}
                      textAlign="center"
                    >
                      <Trans id="common.subjectType.company">Firma</Trans>
                    </Typography>
                  ),
                  testId: 'sender.senderType.company',
                },
              ]}
            </SwitchButtonField>

            <TextField<SenderFormFields>
              formId={formId}
              name="name"
              label={
                formik.values.sender === AddressType.COMPANY
                  ? labels.recipient.companyName
                  : labels.sender.fullName
              }
              testId="sender.input.name"
              disabled={formik.isSubmitting}
            />

            {formik.values.sender === AddressType.COMPANY && (
              <>
                <TextField<SenderFormFields>
                  formId={formId}
                  name="ico"
                  label={fields.ico}
                  testId="sender.input.ico"
                  disabled={formik.isSubmitting}
                />

                <TextField<SenderFormFields>
                  formId={formId}
                  name="dic"
                  label={fields.dicNotRequired}
                  testId="sender.input.dic"
                  disabled={formik.isSubmitting}
                />
              </>
            )}

            <TextField<RecipientFormFields>
              formId={formId}
              name="street"
              label={labels.recipient.street}
              testId="recipient.input.street"
              disabled={formik.isSubmitting}
            />

            <CityPostalCodeContainer>
              <CityPostalCodeFlexItem width={['auto', 'auto']}>
                <TextField<SenderFormFields>
                  formId={formId}
                  name="city"
                  label={labels.recipient.city}
                  testId="sender.input.city"
                  disabled={formik.isSubmitting}
                />
              </CityPostalCodeFlexItem>

              <CityPostalCodeFlexItem width={['auto', '150px']}>
                <TextField<SenderFormFields>
                  formId={formId}
                  name="postalCode"
                  label={labels.recipient.postalCode}
                  format={
                    howToSendFormData.sendToCountry === Country.CZ ||
                    howToSendFormData.sendToCountry === Country.SK
                      ? formatPostalCodeSimple
                      : unformatPostalCode
                  }
                  convert={unformatPostalCode}
                  testId="sender.input.postalCode"
                  validateOnlyOnBlur={(value) =>
                    //incomplete Postal codes we'll validate only onblur
                    !isPostalCodeValidable(
                      value,
                      howToSendFormData.sendFromCountry,
                    )
                  }
                  disabled={formik.isSubmitting}
                />
              </CityPostalCodeFlexItem>
            </CityPostalCodeContainer>

            <CountryNameLikeField
              country={howToSendFormData.sendFromCountry}
              showOnlyNonDefault
              label={labels.sender.pickupCountry}
            />
            <PhoneField<SenderFormFields>
              formId={formId}
              name="phone"
              label={fields.phoneNumber}
              defaultCountry={howToSendFormData.sendFromCountry.toLowerCase()}
              testId="sender.input.phone"
              autoComplete="tel"
              disabled={formik.isSubmitting}
            />

            {!user && (
              <TextField<SenderFormFields>
                formId={formId}
                name="email"
                label={labels.sender.email}
                testId="sender.input.email"
                type="email"
                autocorrect="off"
                autocapitalize="none"
                onBlur={() => checkAccountByEmail(formik.values.email)}
                disabled={!!user || formik.isSubmitting}
              />
            )}

            {chooseAddress ? (
              <Button
                type="button"
                buttonType={ButtonType.TEXT}
                onClick={() =>
                  chooseAddress((changedValues: Partial<SenderFormFields>) =>
                    formik.setValues({ ...formik.values, ...changedValues }),
                  )
                }
                className="gaButton gaButtonAction_chooseAnotherAddress"
                disabled={formik.isSubmitting}
              >
                <Trans id="order.sender.chooseAnotherAddress">
                  Vybrat jinou adresu
                </Trans>
              </Button>
            ) : null}

            <CheckboxField<SenderFormFields>
              formId={formId}
              name="invoiceToAnotherAddress"
              label={fields.invoiceToAnotherAddress}
              data-test="sender.checkbox.invoiceToAnotherAddress"
              disabled={formik.isSubmitting}
            />

            {formik.values.invoiceToAnotherAddress && (
              <>
                <TextField<SenderFormFields>
                  formId={formId}
                  name="nameInvoice"
                  label={labels.sender.invoiceName}
                  testId="sender.input.name.invoice"
                  disabled={formik.isSubmitting}
                />

                <TextField<SenderFormFields>
                  formId={formId}
                  name="icoInvoice"
                  label={fields.icoNotRequired}
                  testId="sender.input.ico.invoice"
                  disabled={formik.isSubmitting}
                />

                <TextField<SenderFormFields>
                  formId={formId}
                  name="dicInvoice"
                  label={fields.dicNotRequired}
                  testId="sender.input.dic.invoice"
                  disabled={formik.isSubmitting}
                />

                <TextField<SenderFormFields>
                  formId={formId}
                  name="streetInvoice"
                  label={labels.recipient.street}
                  testId="sender.input.street.invoice"
                  disabled={formik.isSubmitting}
                />

                <CityPostalCodeContainer>
                  <CityPostalCodeFlexItem width={['auto', 'auto']}>
                    <TextField<SenderFormFields>
                      formId={formId}
                      name="cityInvoice"
                      label={labels.recipient.city}
                      testId="sender.input.city.invoice"
                      disabled={formik.isSubmitting}
                    />
                  </CityPostalCodeFlexItem>

                  <CityPostalCodeFlexItem width={['auto', '150px']}>
                    <TextField<SenderFormFields>
                      formId={formId}
                      name="postalCodeInvoice"
                      label={labels.recipient.postalCode}
                      testId="sender.input.postalCode.invoice"
                      disabled={formik.isSubmitting}
                      format={
                        howToSendFormData.sendToCountry === Country.CZ ||
                        howToSendFormData.sendToCountry === Country.SK
                          ? formatPostalCodeSimple
                          : unformatPostalCode
                      }
                      convert={unformatPostalCode}
                    />
                  </CityPostalCodeFlexItem>
                </CityPostalCodeContainer>

                {chooseInvoiceAddress ? (
                  <Button
                    type="button"
                    buttonType={ButtonType.TEXT}
                    onClick={() =>
                      chooseInvoiceAddress(
                        (changedValues: Partial<SenderFormFields>) =>
                          formik.setValues({
                            ...formik.values,
                            ...changedValues,
                          }),
                      )
                    }
                    className="gaButton gaButtonAction_chooseAnotherAddress"
                    disabled={formik.isSubmitting}
                  >
                    <Trans id="order.sender.chooseAnotherAddress">
                      Vybrat jinou adresu
                    </Trans>
                  </Button>
                ) : null}
              </>
            )}

            {formik.values.pickup === PickupType.COURIER && (
              <TextAreaField<SenderFormFields>
                formId={formId}
                name="note"
                label={labels.sender.noteNotRequired}
                testId="sender.input.comment"
                disabled={formik.isSubmitting}
              />
            )}
            {pickupParcelShopId && (
              <FieldWrapper>
                <Typography type={TypographyType.BodyLight}>
                  <Trans id="common.pickupPlace">Místo odeslání</Trans>
                </Typography>
                <Note>
                  <ParcelShopItem
                    item={pickupParcelShopId}
                    selected
                    onEditClick={changePickupPoint}
                    mode="pickup"
                  />
                </Note>
              </FieldWrapper>
            )}

            {!userIsLoading && !user && !formik.errors.email && (
              <RegisterNote
                formId={formId}
                disabled={formik.isSubmitting}
                emailIsKnown={emailIsKnown}
                onLoginClick={() =>
                  setHasAccountType(AccountSwitchType.HASACCOUNT)
                }
              />
            )}

            {!userIsLoading && !user && (
              <>
                <CheckboxField<SenderFormFields>
                  formId={formId}
                  name="termsAgreement"
                  label={
                    <Trans id="common.field.termsAgreement.v2">
                      Souhlasím se{' '}
                      <Link
                        className="gaLink gaLink_terms"
                        href={externalLinks.termsConditions}
                        target="_blank"
                        testId="sender.link.terms"
                      >
                        všeobecnými obchodními podmínkami
                      </Link>
                    </Trans>
                  }
                  data-test="sender.checkbox.terms"
                  disabled={formik.isSubmitting}
                />

                <PrivacyInformation>
                  <Typography type={TypographyType.BodyMicroLight}>
                    {language === Language.CS && (
                      <>
                        Přečtěte si,{' '}
                        <Link
                          className="gaLink gaLink_privacy"
                          href={externalLinks.privacyPolicy}
                          target="_blank"
                          testId="sender.link.privacy"
                        >
                          jak zpracováváme vaše osobní údaje
                        </Link>
                        . Než nám zásilku předáte, podívejte se také{' '}
                        <Link
                          className="gaLink gaLink_howWrap"
                          href={externalLinks.howWrap}
                          target="_blank"
                          testId="sender.link.howWrap"
                        >
                          na zásady správného balení
                        </Link>{' '}
                        a&nbsp;
                        <Link
                          className="gaLink gaLink_restrictedGoods"
                          href={externalLinks.restrictedGoods}
                          target="_blank"
                          testId="sender.link.restrictedGoods"
                        >
                          seznam věcí, které nemůžeme přepravit
                        </Link>
                        .
                      </>
                    )}
                    {language !== Language.CS && (
                      <>
                        Read about{' '}
                        <Link
                          className="gaLink gaLink_privacy"
                          href={externalLinks.privacyPolicy}
                          target="_blank"
                          testId="sender.link.privacy"
                        >
                          how we use your personal data
                        </Link>{' '}
                        and{' '}
                        <Link
                          className="gaLink gaLink_termsForTransport"
                          href={externalLinks.termsForTransport}
                          target="_blank"
                          testId="sender.link.termsForTransport"
                        >
                          our terms for the shipping
                        </Link>
                        .
                      </>
                    )}
                  </Typography>
                </PrivacyInformation>
              </>
            )}

            <Hr />

            <SpaceBetweenRow>
              <div>{sharedKeys.price}</div>
              <Money currency="CZK">
                {
                  computePrice(
                    {
                      howToSend: howToSendFormData,
                      parcel,
                      recipient,
                      sender: formik.values,
                    },
                    config,
                  ).total
                }
              </Money>
            </SpaceBetweenRow>

            <SpaceBetweenRow marginSize="m" noBottomMargin>
              <FormBackButton
                to="orderRecipient"
                type="button"
                className="gaButton gaButtonBack_sender"
                buttonType={ButtonType.TEXT}
                data-test="sender.button.back"
              >
                <Trans id="common.button.back">Zpět</Trans>
              </FormBackButton>

              <Button
                bottom
                type="submit"
                className="gaButton gaButtonSubmit_sender"
                onClick={() => {
                  if (termsAggrementError)
                    showInfo(
                      <Trans id="sender.termsAgreementMissing">
                        Ve formuláři prosím zaškrtněte souhlas s obchodními
                        podmínkami.
                      </Trans>,
                      null,
                      { timeout: 3000 },
                    );
                }}
                fakeDisabled={!showConfirmActive}
                buttonType={ButtonType.PRIMARY}
                icon={
                  <ArrowIcon
                    color={!formik.isValid ? colors.gray_warm : colors.white}
                  />
                }
                animate={formik.isValid}
                data-test="sender.button.submit"
              >
                <Trans id="common.button.continue">Pokračovat</Trans>
              </Button>
            </SpaceBetweenRow>
          </FormWrapper>
        </FormContentWrapper>
      )}
    </>
  );
};
