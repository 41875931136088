import { useUserContext } from '@hp/context';
import { useClearLocalStorage } from '@hp/order/src/hooks';
import { RoutesType } from '@hp/seo';
import { addDataLayerItem } from '@hp/utils/src/gtm';
import firebase from 'firebase/app';
import { useCallback, useEffect } from 'react';

export const useUserLoginEvent = (route: keyof RoutesType) => {
  const { registerHook } = useUserContext();
  const {
    clearLocalStorage,
    clearLocalStorageAfterSender,
  } = useClearLocalStorage();

  const clearLocalStorageForUser = (user: firebase.User) => {
    const { email } = user;
    if (route === 'orderSender') clearLocalStorageAfterSender();
    else clearLocalStorage(email);
  };
  const cb = useCallback(clearLocalStorageForUser, [route]);

  useEffect(() => {
    const cleanup1 = registerHook('login', cb);
    const cleanup2 = registerHook('createUser', cb);

    return () => {
      cleanup1();
      cleanup2();
    };
  }, [cb, registerHook, route]);

  useEffect(() => {
    const cleanup = registerHook('createUser', () =>
      addDataLayerItem({ event: 'account_created' }),
    );
    return cleanup;
  }, [registerHook]);
};
