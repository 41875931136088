import { AddressType, PickupType } from '@hp/core/shared';
import { useFlow } from '@hp/seo';
import { useObjectLocalStorage } from '@hp/utils';

import { HowToSendFormFields, RecipientFormFields } from '../../types';
import { useSenderForm } from '../Sender/useSenderForm';
import { useRecipientValidationSchema } from './useRecipientValidationSchema';

const emptyValues: RecipientFormFields = {
  recipient: AddressType.PERSON,
  name: '',
  contactPerson: '',
  street: '',
  city: '',
  postalCode: '',
  phone: '',
  email: '',
  note: '',
};

export const useRecipientForm = ({
  deliveryType,
  deliveryId,
  pickupType,

  sendToCountry,
}: HowToSendFormFields) => {
  const flow = useFlow();

  const isDeliveryToParcelShopOrBox = deliveryType !== PickupType.COURIER;
  const isPickupFromParcelShopOrBox = pickupType !== PickupType.COURIER;

  const [formData, saveFormData] = useObjectLocalStorage(
    'recipient',
    emptyValues,
  );
  const validationSchema = useRecipientValidationSchema(
    sendToCountry,
    isDeliveryToParcelShopOrBox,
  );
  const {
    saveFormData: saveSenderFormData,
    initialValues: senderInitialValues,
    validationSchema: senderValidationSchema,
    user,
  } = useSenderForm();

  const handleSubmit = async (values: RecipientFormFields) => {
    let normalizedValues = values;
    if (isDeliveryToParcelShopOrBox) {
      //user has previously filled a form in address-address mode, then went back
      //and he had changed address to pacrcelshop.
      //we must clean previosly inputed data:
      const { name, phone, email, contactPerson, recipient } = values;
      normalizedValues = {
        ...emptyValues,
        name,
        phone,
        email,
        contactPerson,
        recipient,
      };
    }

    saveFormData(normalizedValues);
    const senderDataAreValid = await senderValidationSchema.isValid(
      senderInitialValues,
    );
    if (user && senderDataAreValid && !isPickupFromParcelShopOrBox) {
      saveSenderFormData(senderInitialValues);
      flow.nextPage('orderAdditionalServices');
    } else {
      flow.nextPage('orderSender');
    }
  };

  return {
    saveFormData,
    handleSubmit,
    initialValues: formData,
    validationSchema,
    emptyValues,
    deliveryParcelShopId: isDeliveryToParcelShopOrBox ? deliveryId : undefined,
  };
};
