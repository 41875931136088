import {
  Button,
  ButtonType,
  Hr,
  Loading,
  Typography,
  TypographyType,
  UpperCase,
} from '@hp/atomic';
import { PickupType } from '@hp/core/shared';
import { CenteredRow, Row } from '@hp/layout';
import { Trans } from '@lingui/macro';
import React from 'react';

import {
  NoItemsContainer,
  ParcelShopListContainer,
  ParcelShopListStyled,
} from './styled';
import { ParcelShopListProps } from './types';

export const ParcelShopList: (props: ParcelShopListProps) => JSX.Element = ({
  type,
  formik,
  isLoading,
  increaseLimit,
  children,
}) => {
  if (isLoading) return <Loading height="300px" />;
  const empty = !children?.length;
  return (
    <ParcelShopListContainer empty={empty}>
      {!empty ? (
        <>
          <ParcelShopListStyled>
            {children.map((ch, i) => (
              <React.Fragment key={i}>
                {ch}
                <Hr noMargin />
              </React.Fragment>
            ))}
            {increaseLimit && (
              <CenteredRow marginSize="l">
                <Button
                  buttonType={ButtonType.TEXT}
                  type="button"
                  className="gaButton gaButtonAction_searchParcelShopModal"
                  onClick={() => increaseLimit()}
                >
                  <Trans id="common.button.showMore">Zobrazit další</Trans>
                </Button>
              </CenteredRow>
            )}
          </ParcelShopListStyled>
        </>
      ) : (
        <NoItemsContainer>
          <Row marginSize="s">
            <Typography type={TypographyType.BodySmallLight}>
              <Trans id="delivery.search.emptyResult">
                Litujeme, ale v blízkosti této adresy jsme nenalezli žádnou
                pobočku odpovídající zvoleným parametrům. Změňte adresu nebo
                způsob předání.
              </Trans>
            </Typography>
          </Row>

          <Row marginSize="s">
            {type === 'pickup' ? (
              <Button
                type="button"
                buttonType={ButtonType.TEXT}
                onClick={() =>
                  formik.setValues({
                    ...formik.values,
                    pickupType: PickupType.COURIER,
                  })
                }
              >
                <UpperCase mode="capitalize">
                  <Trans id="delivery.changePickup.button">
                    Předám na adrese +40 Kč
                  </Trans>
                </UpperCase>
              </Button>
            ) : (
              <Button
                type="button"
                buttonType={ButtonType.TEXT}
                onClick={() =>
                  formik.setValues({
                    ...formik.values,
                    deliveryType: PickupType.COURIER,
                  })
                }
              >
                <UpperCase mode="capitalize">
                  <Trans id="delivery.changeDelivery.button">
                    Doručit na adresu +40 Kč
                  </Trans>
                </UpperCase>
              </Button>
            )}
          </Row>
        </NoItemsContainer>
      )}
    </ParcelShopListContainer>
  );
};
