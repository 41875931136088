import { Button, ButtonType, LinkButton, SendPackageIcon } from '@hp/atomic';
import { useConfig } from '@hp/config';
import { getSpecificCfg } from '@hp/core/shared';
import {
  ExtraPaddings,
  FullHeightLayout,
  HideOnDesktop,
  HideOnMobile,
  HorizontalLayout,
  MediaQueryJS,
  Row,
} from '@hp/layout';
import { breakpoints, colors } from '@hp/theme';
import { hasFeature, scrollIntoViewIfNeeded } from '@hp/utils';
import { Trans } from '@lingui/react';
import React, { FC, useEffect, useState } from 'react';

import { HowToSendFormV3 } from '../HowToSendForm';
import { HowToSendFormV2 } from '../HowToSendForm/V2/HowToSendForm';
import { Headings } from './Headings';
import { HowItWorks } from './HowItWorks';
import { Included } from './Included';
import { IncludedDesktop } from './IncludedDesktop';
import { HowItWorksSectionWrapper } from './styled';
import { HomepageProps } from './types';
import { useAppInfo } from './useAppInfo';

export { useAppInfo } from './useAppInfo';

export const Homepage: FC<HomepageProps> = ({ selectedParcelShop }) => {
  const sectionId = 'howToSendSection';
  const { config } = useConfig();
  const [sendToCountry, setSendToCountry] = useState(null);
  const HowToSendForm = hasFeature('v3') ? HowToSendFormV3 : HowToSendFormV2;

  const scrollToForm = () => {
    scrollIntoViewIfNeeded(document.getElementById(sectionId), {
      scrollMode: 'always',
      block: 'start',
      delta: '-100px',
    });
  };

  useEffect(() => {
    if (window.location.pathname === '/parcelshop') {
      scrollToForm();
    }
  }, []);

  useAppInfo();

  const isCodAllowedForSendToCountry =
    sendToCountry &&
    getSpecificCfg(config.constraints.maxCashOnDelivery, sendToCountry) > 0;

  return (
    <>
      <FullHeightLayout>
        <MediaQueryJS>
          {(isMobileScreen) => (
            <HorizontalLayout
              bodyWidth={breakpoints.bigDesktop}
              bgColor={isMobileScreen ? colors.white : 'gradient'}
            >
              <ExtraPaddings vertical noBottom>
                <Headings />

                <HideOnDesktop>
                  <Row marginSize="m">
                    <Button
                      className="gaButton gaButtonAction_scrollToSendForm"
                      buttonType={ButtonType.PRIMARY}
                      icon={<SendPackageIcon />}
                      animate
                      onClick={scrollToForm}
                    >
                      <span className="gaButton gaButtonAction_scrollToSendForm">
                        <Trans id="common.button.sendPackage">
                          Poslat balík
                        </Trans>
                      </span>
                    </Button>
                  </Row>

                  <Row marginSize="m">
                    <LinkButton
                      buttonType={ButtonType.SECONDARY}
                      to="register"
                      className="gaButton gaButtonAction_createAccount_mobile"
                    >
                      <Trans id="homepage.button.createAccount">
                        Vytvořit účet
                      </Trans>
                    </LinkButton>
                  </Row>

                  <HowItWorks />
                </HideOnDesktop>

                {!isMobileScreen && (
                  <HowToSendForm
                    selectedParcelShop={selectedParcelShop}
                    onSendToCountryChange={setSendToCountry}
                  />
                )}

                {isMobileScreen ? (
                  <Included isCodAllowed={isCodAllowedForSendToCountry} />
                ) : (
                  <IncludedDesktop
                    isCodAllowed={isCodAllowedForSendToCountry}
                  />
                )}

                {isMobileScreen && (
                  <HowToSendForm
                    selectedParcelShop={selectedParcelShop}
                    onSendToCountryChange={setSendToCountry}
                  />
                )}
              </ExtraPaddings>
            </HorizontalLayout>
          )}
        </MediaQueryJS>

        <HowItWorksSectionWrapper>
          <HorizontalLayout bodyWidth={breakpoints.bigDesktop}>
            <HideOnMobile>
              <HowItWorks />
            </HideOnMobile>
          </HorizontalLayout>

          {/* <BannerWrapper>
            <Banner>
              <BannerImage src="/images/depoVan.png" alt="Dodavka pred depem" />

              <BannerText>
                <Trans id="homepage.banner.text">
                  Vaše balíky doručíme na hodinu přesně.
                </Trans>
              </BannerText>

              <BannerImage
                src="/images/packageDelivery.png"
                alt="Doruceni zasilky"
              />
            </Banner>
          </BannerWrapper> */}
        </HowItWorksSectionWrapper>
      </FullHeightLayout>
    </>
  );
};
