import { useConfig } from '@hp/config';
import { PickupType } from '@hp/core/shared';
import { useFetchedData } from '@hp/utils';

import { HowToSendFormFields, ParcelShopItemType } from '../types';

type OptionsType = Parameters<typeof useFetchedData>[1];

export const useFetchForParcelShopId = (id?: string, options?: OptionsType) => {
  const {
    config: {
      other: { parcelShopSearchByIdApiUrl: url },
    },
  } = useConfig();
  const { fetchData: f, ...rest } = useFetchedData<ParcelShopItemType>(
    id ? `${url}${id}` : null,
    options,
  );
  const fetchData = (id: string) => f(`${url}${id}`);
  return { fetchData, ...rest };
};

export const useFetchForParcelShopIds = (
  howToSend:
    | Pick<
        HowToSendFormFields,
        'deliveryId' | 'deliveryType' | 'pickupId' | 'pickupType'
      >
    | null
    | undefined,
  options?: OptionsType,
) => {
  const pickup = useFetchForParcelShopId(
    howToSend?.pickupType !== PickupType.COURIER ? howToSend?.pickupId : null,
    options,
  );
  const delivery = useFetchForParcelShopId(
    howToSend?.deliveryType !== PickupType.COURIER
      ? howToSend?.deliveryId
      : null,
    options,
  );
  return { pickup, delivery };
};

export type ParcelShopFetchingData = ReturnType<
  typeof useFetchForParcelShopIds
>;
