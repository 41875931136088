import { Button, ButtonType } from '@hp/atomic';
import { config, Environment } from '@hp/config';
import { useLanguage } from '@hp/core/src/providers/LanguageProvider';
import {
  ElementBuilderFunction,
  ModalWellKnowResult,
  useSystemModal,
} from '@hp/core/src/providers/SystemModal';
import { Inner } from '@hp/layout';
import { formatDate, hasFeature, useObjectLocalStorage } from '@hp/utils';
import { Trans } from '@lingui/react';
import React, { useEffect } from 'react';

import { OtherData } from '../../types';

const isProd = config.app.environment === Environment.PRODUCTION;

const About = () => {
  return (
    <Inner>
      this is preview version ! <br />
      {window.dpdAppVersion}
      <br />
      <h1
        style={{
          fontSize: '100px',
          textShadow: '10px -10px 5px silver',
        }}
      >
        🍃 v3 ☔️
      </h1>
    </Inner>
  );
};

const notToday = 'notToday';

const createButtons: ElementBuilderFunction<typeof notToday> = (
  invokeClose,
) => {
  return (
    <>
      <Button
        buttonType={ButtonType.SECONDARY}
        onClick={() => invokeClose(ModalWellKnowResult.OK)}
      >
        <Trans id="common.button.ok">OK</Trans>
      </Button>
      &nbsp;
      <Button
        buttonType={ButtonType.SECONDARY}
        onClick={() => invokeClose(notToday)}
      >
        <Trans id="common.button.ok">OK</Trans> a dnes neukazovat
      </Button>
    </>
  );
};

export const useAppInfo = () => {
  //@ts-ignore
  const { language } = useLanguage();
  const [other, saveOther] = useObjectLocalStorage<OtherData>('other', {});
  const { showInfo } = useSystemModal();

  useEffect(() => {
    if (
      isProd ||
      other?.appInfoNotShowIn == formatDate(new Date(), 'YYYY-MM-DD') ||
      !hasFeature('v3')
    )
      return;

    showInfo<typeof notToday>(<About />, createButtons).then((choice) => {
      if (choice === notToday) {
        saveOther({
          ...other,
          appInfoNotShowIn: formatDate(new Date(), 'YYYY-MM-DD'),
        });
      }
    });
  }, [showInfo]);

  return null;
};
