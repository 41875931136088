import { colors } from '@hp/theme';
import { Trans } from '@lingui/react';
import React from 'react';

import { Heading, Wrapper } from './styled';

export const PrivacyPolicyContent = () => {
  return (
    <Wrapper>
      <Heading color={colors.black}>
        <Trans id="legal.privacy.title">Ochrana osobních údajů</Trans>
      </Heading>
      <p>
        Cupcake ipsum dolor sit amet. Chocolate bar donut jelly jelly lollipop
        danish. Chocolate cake candy jelly beans carrot cake gummi bears lemon
        drops icing sweet bear claw. Sesame snaps soufflé cheesecake biscuit
        candy canes candy canes caramels oat cake. Wafer lemon drops bear claw
        sesame snaps. Powder chocolate cake chocolate marshmallow. Wafer halvah
        chupa chups chocolate cake apple pie candy canes cotton candy. Marzipan
        ice cream cheesecake cake toffee. Sugar plum jelly-o gummi bears sesame
        snaps dessert caramels brownie pie fruitcake.
      </p>
      <p>
        Pudding sweet roll halvah sweet wafer bear claw bear claw pudding. Candy
        canes jelly-o icing apple pie powder toffee. Sweet roll dessert wafer
        apple pie gummi bears caramels marshmallow bonbon sesame snaps. Pastry
        donut soufflé sweet roll. Biscuit candy donut marzipan. Ice cream muffin
        tart. Cotton candy dragée jelly beans chupa chups gummi bears. Toffee
        bear claw jelly pie. Soufflé brownie jelly beans bear claw dragée
        croissant dessert topping. Tiramisu gummies topping tart fruitcake.
      </p>
      <p>
        Tart muffin muffin lemon drops carrot cake liquorice. Jelly beans
        chocolate bar topping cupcake. Chocolate cake gummi bears chocolate cake
        powder candy gingerbread sweet brownie sweet. Dessert caramels icing
        candy chocolate bar gingerbread pastry danish. Carrot cake biscuit
        dessert brownie tart. Toffee jelly beans cheesecake donut.
      </p>
      <p>
        Donut dessert muffin. Marshmallow icing donut tiramisu icing. Lemon
        drops icing sweet sugar plum danish donut marzipan candy candy.
        Liquorice chocolate cake muffin biscuit lemon drops macaroon cookie.
        Croissant croissant apple pie chocolate tootsie roll jelly chupa chups
        jujubes. Cheesecake muffin pastry pie candy brownie. Topping cheesecake
        cake sugar plum oat cake sugar plum chocolate bar.
      </p>
      <p>
        Oat cake cake pudding tootsie roll pastry cotton candy apple pie.
        Brownie oat cake jelly. Danish gummi bears chupa chups croissant halvah
        macaroon. Marzipan marshmallow gingerbread. Halvah chocolate cake lemon
        drops fruitcake candy. Biscuit lollipop donut sweet roll. Biscuit tart
        liquorice sweet roll soufflé chocolate bar. Pudding chocolate bar
        tiramisu gummies. Jelly-o jelly-o toffee chupa chups gingerbread biscuit
        cupcake.
      </p>
      <p>
        Gummi bears cheesecake icing marzipan brownie lemon drops. Cheesecake
        candy powder oat cake donut gummi bears gingerbread pastry. Brownie
        gummies brownie sweet roll gummies. Liquorice danish donut cake. Pie
        gummies caramels chupa chups sesame snaps brownie cake jelly beans.
        Apple pie bonbon icing sesame snaps jelly-o tootsie roll toffee. Chupa
        chups bonbon topping. Toffee marzipan chocolate bar. Chocolate donut
        chocolate cake topping dessert topping sesame snaps danish chocolate
        bar. Apple pie jujubes chocolate cake brownie cake cotton candy
        chocolate.
      </p>
      <p>
        Jelly-o donut gummi bears. Gummi bears ice cream chocolate chocolate bar
        gummies lollipop. Fruitcake chupa chups dessert halvah gummi bears cake
        dessert. Bonbon croissant jelly jelly beans liquorice macaroon lollipop
        gingerbread ice cream. Topping sesame snaps danish. Oat cake bear claw
        biscuit fruitcake icing jelly. Tart jelly beans apple pie gummies
        biscuit gummies bear claw. Halvah candy jelly beans bear claw chocolate
        cake topping dessert pudding chocolate bar. Chocolate cake sweet roll
        tootsie roll candy caramels sweet roll topping caramels dragée.
      </p>
      <p>
        Topping tart sweet roll. Lollipop bonbon chupa chups. Jelly candy canes
        lollipop cake lollipop. Chupa chups sweet macaroon cookie jelly-o
        jujubes. Tart chocolate cake brownie liquorice dragée wafer marzipan
        chocolate cake sweet. Brownie marshmallow cheesecake gummi bears muffin
        croissant cookie. Croissant macaroon cookie gingerbread macaroon muffin
        donut jelly-o carrot cake.
      </p>
    </Wrapper>
  );
};
