import { UpperCase } from '@hp/atomic';
import { Badge } from '@hp/components';
import { Language } from '@hp/core/shared';
import { useI18n } from '@hp/locale';
import { colors } from '@hp/theme';
import { hasFeature, useServerTime } from '@hp/utils';
import { Trans } from '@lingui/react';
import React, { FC } from 'react';

import { ParcelShopItemType } from '../../types';
import { getState } from './openingHoursUtils';
import { ParcelShopMode, ShopState } from './types';

export const ParcelShopOpeningState: FC<{
  item: ParcelShopItemType;
  type: ParcelShopMode;
  language: Language;
}> = ({ language, item: { openingHours, enabled }, type }) => {
  //correctedObj - on the begining is null;
  const { correctedObj } = useServerTime();

  const i18n = useI18n();

  const nA = !enabled ? (
    <Badge bgColor="transparent" textColor={colors.gray_badge} margin="0">
      <Trans id="common.shopState.disabled">Nedostupné</Trans>
    </Badge>
  ) : null;

  if (nA && !hasFeature('allowDisabledShops')) return nA;

  if (!correctedObj || !openingHours) return null;

  // const isOpen = checkIsOpen(correctedObj, openingHours);
  const { state, interval, dayPrefix } = getState(
    correctedObj,
    openingHours,
    language,
    i18n,
  );

  //for delivery, we show only non-stop
  if (type === 'delivery' && state !== ShopState.nonstop) return null;

  switch (state) {
    case ShopState.nonstop:
      return (
        <>
          <Badge bgColor={colors.green} margin="0">
            <UpperCase mode="uppercase">
              <Trans id="common.hours.nonstop">non-stop</Trans>
            </UpperCase>
          </Badge>
        </>
      );
    case ShopState.opened:
      return (
        <>
          <Badge bgColor={colors.green} margin="0">
            <Trans id="common.hours.till">
              {' '}
              {dayPrefix} do {interval.close}
            </Trans>
          </Badge>
        </>
      );
    case ShopState.closed:
      return (
        <>
          <Badge bgColor={colors.gray_badge} margin="0">
            <Trans id="common.hours.from">
              {dayPrefix} od {interval.open}
            </Trans>{' '}
          </Badge>
        </>
      );
    default:
      return null;
  }
};
