import { useConfig } from '@hp/config';
import { PickupType } from '@hp/core/shared';
import { useYup } from '@hp/form';
import { useObjectLocalStorage } from '@hp/utils';
import { useState } from 'react';

import { Dimension, HowToSendFormFields } from '../../types';

export type CalculatorFormProps = Dimension;

const initialValues: CalculatorFormProps = {
  width: null,
  height: null,
  length: null,
};

const computeMaxCircumference = ({
  height: h,
  length: l,
  width: w,
}: Dimension) => {
  return 2 * w + 2 * h + l;
};

export const useCalculator = (
  onSubmit: (values: CalculatorFormProps) => void,
) => {
  const {
    config: {
      constraints: {
        maxLength,
        maxLengthParcelShop,
        maxCircumference,
        maxCircumferenceParcelShop,
      },
    },
  } = useConfig();

  const [hasProperDimension, setHasProperDimension] = useState<boolean>(false);
  const [circumference, setCircumference] = useState<number>(0);
  const [isComputed, setIsComputed] = useState<boolean>(false);
  const Yup = useYup();
  const validationSchema = Yup.object().shape<Dimension>({
    width: Yup.number().positive().min(1).max(maxLength).required(),
    height: Yup.number().positive().min(1).max(maxLength).required(),
    length: Yup.number().positive().min(1).max(maxLength).required(),
  });
  const [howToSend] = useObjectLocalStorage<HowToSendFormFields>('howToSend');

  const isProperDimension = (length: number, circumference: number) => {
    return length <= maxLength && circumference <= maxCircumference;
  };

  const isProperDimensionForParcelShop = (
    length: number,
    circumference: number,
  ) => {
    return (
      length <= maxLengthParcelShop &&
      circumference <= maxCircumferenceParcelShop
    );
  };

  const handleSubmit = (values: CalculatorFormProps) => {
    if (isComputed) {
      onSubmit?.(values);
      return;
    }
    const circumference = computeMaxCircumference(values);
    setCircumference(circumference);
    setHasProperDimension(
      howToSend.pickupType !== PickupType.COURIER
        ? isProperDimensionForParcelShop(values.length, circumference)
        : isProperDimension(values.length, circumference),
    );
    setIsComputed(true);
  };

  const reset = () => setIsComputed(false);

  return {
    initialValues,
    handleSubmit,
    hasProperDimension,
    circumference,
    isComputed,
    reset,
    validationSchema,
  };
};
