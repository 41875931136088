import { CourierIcon, ParcelShopIcon } from '@hp/atomic';
import { spacing } from '@hp/theme';
import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: ${spacing.m};
`;

export const CourierIconStyled = styled(CourierIcon)`
  width: 40px;
  height: 40px;
`;

export const ParcelShopIconStyled = styled(ParcelShopIcon)`
  width: 40px;
  height: 40px;
`;
