import { Country, PickupType } from '@hp/core/shared';
import { useFlow } from '@hp/seo';
import {
  hasFeature,
  pickupDateOptions,
  useObjectLocalStorage,
} from '@hp/utils';
import { useI18n } from '@hp/locale';

import { HowToSendFormFields, ParcelShop, PickupFormType } from '../../types';
import { initialValues as parcelInitialValues } from '../Parcel/useParcel';

export const useHowToSendForm = (selectedParcelShop?: ParcelShop) => {
  const i18n = useI18n();
  const initialValues: HowToSendFormFields = {
    sendFromCountry: Country.CZ,
    sendToCountry: Country.CZ,
    pickupType: hasFeature('v3') ? PickupType.PARCEL_SHOP : PickupType.COURIER,
    pickupFormType: hasFeature('v3')
      ? PickupFormType.PARCELSHOP_OR_BOX
      : PickupFormType.COURIER,
    deliveryType: PickupType.COURIER,
    deliveryFormType: PickupFormType.COURIER,
    sendWhen: pickupDateOptions(Country.CZ, i18n)[0].value,
    selectedParcelShopId: selectedParcelShop?.parcelshop_id || '',
    selectedParcelShopName: selectedParcelShop?.parcelshop_name || '',
    selectedParcelShopAddress: selectedParcelShop?.parcelshop_address || '',
    selectedParcelShopCodAllowed:
      selectedParcelShop?.parcelshop_cod_allowed || '',
  };

  const flow = useFlow();

  const [formData, saveFormData] = useObjectLocalStorage(
    'howToSend',
    initialValues,
  );
  const [parcelData, saveParcelData] = useObjectLocalStorage(
    'parcel',
    parcelInitialValues,
    { skip: !hasFeature('v3') },
  );

  const isDateAvailable = pickupDateOptions(
    formData.sendFromCountry,
    i18n,
  ).some((d) => d.value === formData.sendWhen);
  //when localStorage contains date which is not available now, we should use first available date
  const properFormdata = isDateAvailable
    ? formData
    : {
        ...formData,
        sendWhen: pickupDateOptions(formData.sendFromCountry, i18n)[0].value,
      };

  const handleSubmit = (values: HowToSendFormFields) => {
    if (
      selectedParcelShop &&
      values.selectedParcelShopId !== initialValues.selectedParcelShopId
    ) {
      values.selectedParcelShopId = selectedParcelShop?.parcelshop_id;
      values.selectedParcelShopName = selectedParcelShop?.parcelshop_name;
      values.selectedParcelShopAddress = selectedParcelShop?.parcelshop_address;
      values.selectedParcelShopCodAllowed =
        selectedParcelShop?.parcelshop_cod_allowed;
    }

    const simpleFlow =
      hasFeature('v3') &&
      parcelData.items[0].weight === null &&
      parcelData.items.length <= 1;

    if (!simpleFlow) {
      values.deliveryType = PickupType.COURIER;
      values.pickupType = PickupType.COURIER;
    }
    saveFormData(values);

    flow.nextPage(simpleFlow ? 'orderDelivery' : null);
  };

  return {
    saveFormData,
    handleSubmit,
    initialValues: properFormdata,
    saveParcelData,
  };
};
