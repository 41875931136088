import { useObjectLocalStorage } from '@hp/utils';

import {
  AdditionalServicesFormFields,
  HowToSendFormFields,
  OtherData,
  ParcelFormProps,
  RecipientFormFields,
  SenderFormFields,
} from '../types';

export const useClearLocalStorage = () => {
  const noSsr = { lazy: true };
  const [, saveHowToSendFormData] = useObjectLocalStorage<HowToSendFormFields>(
    'howToSend',
    null,
    noSsr,
  );
  const [, saveParcel] = useObjectLocalStorage<ParcelFormProps>(
    'parcel',
    null,
    noSsr,
  );
  const [, saveOther] = useObjectLocalStorage<OtherData>('other', null, noSsr);
  const [, saveRecipient] = useObjectLocalStorage<RecipientFormFields>(
    'recipient',
    null,
    noSsr,
  );
  const [sender, saveSender] = useObjectLocalStorage<SenderFormFields>(
    'sender',
    null,
    noSsr,
  );
  const [, setAdditionalParams] = useObjectLocalStorage<
    AdditionalServicesFormFields
  >('additionalServices', null, noSsr);

  /** clears next localStorage's when user is logged-in on orderSender page.*/
  const clearLocalStorageAfterSender = () => {
    //we can't clean already used storages
    [setAdditionalParams, saveOther].forEach((f) => f(null));
  };

  /** clear browser' storage. When email is specified and is equal, clearing is skipped */
  const clearLocalStorage = (email?: string, setNewEmail = false) => {
    if (email && sender?.email === email) return;
    [
      saveHowToSendFormData,
      saveParcel,
      saveRecipient,
      saveSender,
      setAdditionalParams,
      saveOther,
    ].forEach((f) => f(null));
    if (setNewEmail) saveSender({ email });
  };

  return { clearLocalStorage, clearLocalStorageAfterSender };
};
