import { Typography, TypographyType } from '@hp/atomic';
import { ParcelSize, ParcelSpecsList } from '@hp/core/shared';
import { colors } from '@hp/theme';
import { Trans } from '@lingui/macro';
import React from 'react';

export const ParcelDimensionsDescription = (props: {
  size: ParcelSize;
  specList: ParcelSpecsList;
}) => {
  const { dimensions } = props.specList[props.size];

  if (!dimensions) return null;

  const [a, b, c] = dimensions;

  if (!c) {
    return (
      <Trans id="order.parcel.xl.description">
        Obvodová délka&nbsp;
        <Typography type={TypographyType.BodyMicro} color={colors.red_main}>
          300
        </Typography>
        &nbsp;cm
      </Trans>
    );
  }

  return (
    <>
      <Typography type={TypographyType.BodyMicro} color={colors.red_main}>
        {a}
      </Typography>
      <Typography type={TypographyType.BodyMicroLight} color={colors.black}>
        &nbsp;x&nbsp;
      </Typography>
      <Typography type={TypographyType.BodyMicro} color={colors.red_main}>
        {b}
      </Typography>
      <Typography type={TypographyType.BodyMicroLight} color={colors.black}>
        &nbsp;cm
      </Typography>
    </>
  );
};
