import { colors } from '@hp/theme';
import styled from 'styled-components';

export const SearchColumn = styled.div`
  display: flex;
  width: 300px;
  flex-direction: column;
  padding-right: 12px;
`;

export const ParcelShopListWrapper = styled.ul`
  height: 290px;
  padding: 0;
  margin: 0 0 16px 0;
  overflow-y: scroll;

  /* width */
  ::-webkit-scrollbar {
    width: 9px;
    height: 250px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${colors.gray_warm};
    border-radius: 10px;
  }
`;

export const ParcelShopListItem = styled.li`
  list-style: none;
  margin: 12px 0 0;
  padding-right: 4px;
`;

export const MapColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
